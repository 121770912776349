import React from 'react';

export default function Testimonial({ title, content, imageUrl, name, designation, company }) {
  return (
    <div>
      <div className="input-processor">
        <div className="output-handler">
          <img loading="lazy" className="testomonial-background-icon" alt="" src="/testomonial-background.svg" />
          <div className="testimonial-central">
            <div className="reach-purchase-conversion-rate-wrapper">
              <h1 className="reach-purchase-conversion">{title}</h1>
            </div>
            <div className="thanks-to-releva-we-manage-to-wrapper">
              <div className="thanks-to-releva">{content}</div>
            </div>
            <div className="frame-parent10">
              <div className="testimonial-image-wrapper">
                <img className="frame-icon" loading="lazy" alt="" src={imageUrl} />
              </div>
              <div className="eleonora-ivanova-marketing-aut-parent">
                <div className="eleonora-ivanova-marketing-container">
                  <p className="eleonora-ivanova">{name}</p>
                  <p className="marketing-automation-expert">{designation}</p>
                  <p className="ebag">{company}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
