import './FrameComponent33.css';

const FrameComponent33 = () => {
  return (
    <section className="about-us-child" id="releva-story">
      <div className="frame-parent126">
        <div className="heading-wrapper">
          <div className="heading">Releva’s Story</div>
        </div>
        <div className="element-3-wrapper">
          <div className="element-3">
            <div className="element-3-inner">
              <div className="the-story-behind-releva-parent">
                <div className="the-story-behind">The story behind Releva</div>
                <div className="inspired-by-the">
                  Inspired by the passion to bridge the gap between the well-known and beloved experiences offered by
                  local stores that have been meeting families' needs across generations, Releva transforms online
                  customers' journeys into equally tailor-made adventures.
                </div>
              </div>
            </div>
            <div className="element-21">
              <img src="AboutUs/OurStory.webp" loading="lazy" alt="" />
            </div>
          </div>
        </div>
        <div className="frame-wrapper53">
          <div className="journey-and-milestones-parent">
            <div className="journey-and-milestones">Journey and Milestones</div>
            <div className="evolving-from-startup-to-indus-wrapper">
              <div className="evolving-from-startup">
                Evolving from Startup to Industry Leader. Take a glimpse into Releva’s journey, where innovation meets
                experience.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent33;
