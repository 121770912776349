import './SubMenu.css';

const SubMenu = ({ active }) => {
  const isActive = (menuName) => {
    return active === menuName;
  };

  return (
    <section className="sub-menu3">
      <div className="menu-items3">
        <div className={`terms-of-use-frame ${isActive('terms-of-use') ? 'active' : ''}`}>
          <div className="terms-of-use14">
            <a rel="noopener noreferrer" href="/terms-of-use">
              Terms of Use
            </a>
          </div>
        </div>
        <div className={`cookie-policy-frame ${isActive('cookie-policy') ? 'active' : ''}`}>
          <div className="cookie-policy5">
            <a rel="noopener noreferrer" href="cookie-policy">
              Cookie Policy
            </a>
          </div>
        </div>
        <div className={`privacy-policy-frame ${isActive('privacy-policy') ? 'active' : ''}`}>
          <div className="privacy-policy16">
            <a rel="noopener noreferrer" href="privacy-policy">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className={`privacy-policy-frame ${isActive('shopify-app-privacy-policy') ? 'active' : ''}`}>
          <div className="privacy-policy17">
            <a rel="noopener noreferrer" href="app-privacy-policy">
              Shopify App Privacy Policy
            </a>
          </div>
        </div>
        <div className="menu-items-child2" />
        <div className="input" />
      </div>
    </section>
  );
};

export default SubMenu;
