import './InputField1.css';

const InputField1 = () => {
  return (
    <section className="input-field4" id="meet-the-team">
      <div className="">
        <div className="button-set">
          <div className="meet-the-team">Meet the Team</div>
          <div className="grid-layout1">
            <div className="meet-the-minds">
              Meet the minds behind Releva - where marketing meets innovation for your success!
            </div>
          </div>
        </div>
      </div>
      <div className="bar-chart">
        <div className="element-with-photos">
          <img className="image-118-icon" loading="lazy" alt="" src="/AboutUs/JoroAndYavor.webp" />
        </div>
        <div className="bar-chart-inner">
          <div className="frame-parent135">
            <div className="frame-parent136">
              <div className="georgi-georgiev-wrapper">
                <div className="georgi-georgiev1">Georgi Georgiev</div>
              </div>
              <div className="ceo-co-founder1">{`CEO & co-founder`}</div>
              <div className="georgi-is-a">{`Georgi is a senior product and technical leader with more than 17 years of experience in product strategy, software engineering, big data, and machine learning. He has built successful AI & Data platforms for some of the most knowledge- intensive businesses across the globe. Georgiev holds a PhD from Sofia University and Global Executive MBA from the Instituto de Empresa (the IE).`}</div>
              <div className="expand-text-button-wrapper">
                <img className="expand-text-button" loading="lazy" alt="" src="/expand-text-button@2x.webp" />
              </div>
            </div>
            <div className="frame-parent137">
              <div className="yavor-stoychev-wrapper">
                <div className="yavor-stoychev1">Yavor Stoychev</div>
              </div>
              <div className="cto-co-founder1">{`CTO & co-founder`}</div>
              <div className="yavor-is-a">{`Yavor is a senior software professional with more than 10 years of experience in machine learning, distributed systems, and data engineering. He has build eCommerce & growth solutions for fortune 500 companies and startups. Yavor holds a bachelor degree in Computer Science and Economics from the American University in Bulgaria, with a minor degree in Mathematics.`}</div>
              {/* <img
                className="group-1-2"
                loading="lazy"
                alt=""
                src="/expand-text-button@2x.webp"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputField1;
