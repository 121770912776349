const settingsFeatures = [
  {
    feature: 'Subscriptions management',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Customizable pricing',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Mobile app automation',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Dynamic pricing',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Options for custom IT development and reporting',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
];

export default settingsFeatures;
