const resourcesFeatures = [
  {
    feature: '5 predefined product templates for web and email',
    // startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Customizable product templates for web and email',
    // startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
];

export default resourcesFeatures;
