import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './FrameComponent14.css';

const FrameComponent14 = () => {
  return (
    <section className="product-page-inner1">
      <div className="frame-parent57">
        <div className="secondary-navigation-wrapper">
          <div className="secondary-navigation">
            <h1 className="persistent-engagement">Persistent Engagement</h1>
            <div className="when-a-visitor">
              When a visitor browses products but departs without a purchase, Releva persists by retargeting them
              through personalized emails, gently reigniting their interest.
            </div>
            <button className="button17">
              <a
                rel="noopener noreferrer"
                className="button-name15"
                target="_blank"
                onClick={(e) =>
                  handleBookaDemoClick(
                    e,
                    'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                  )
                }
              >
                Book a demo
              </a>
            </button>
          </div>
        </div>
        <div className="i-03">
          <img
            loading="lazy"
            src="/ProductPage/PersistentEngagement.webp"
            alt="PersistentEngagement"
            title="PersistentEngagement"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent14;
