import { useMemo } from 'react';
import './Person.css';

const Person = ({
  prop,
  vuesaxlinearlink,
  addRelevaSDKToYourAppEith,
  propPadding,
  propGap,
  propMinWidth,
  propWidth,
}) => {
  const personStyle = useMemo(() => {
    return {
      padding: propPadding,
      gap: propGap,
    };
  }, [propPadding, propGap]);

  const divStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const addRelevaSDKStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className="person" style={personStyle}>
      <div className="parent13">
        <div className="div16" style={divStyle}>
          {prop}
        </div>
        <div className="vuesaxlinearlink-wrapper">
          <img className="vuesaxlinearlink-icon" alt="" src={vuesaxlinearlink} />
        </div>
      </div>
      <div className="add-releva-sdk" style={addRelevaSDKStyle}>
        {addRelevaSDKToYourAppEith}
      </div>
    </div>
  );
};

export default Person;
