import { Helmet } from 'react-helmet';
import CustomersShowcase from '../components/HomePage/CustomersShowcase';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import HowItWorks from '../components/HomePage/HowItWorks';
import OnePlatformForEcommerceSuccess from '../components/HomePage/OnePlatformForEcommerceSuccess';
import TopThreeFunctionsHomePage from '../components/HomePage/TopThreeFunctionsHomePage';
import Testimonials from '../components/Testimonials';
import pagesJsonLD from '../constants/pagesJsonLD';
import './RelevaHomepage.css';

const RelevaHomepage = () => {
  return (
    <div className="releva-homepage">
      <Helmet>
        <title>Releva AI | Home Page</title>
        <meta
          name="description"
          content="Discover Releva AI, the leading platform for AI-driven marketing solutions. Transform customer engagement, optimize campaigns, and drive business growth with our innovative technology. Explore our cutting-edge tools and see how we can elevate your marketing strategy."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.homePage)}</script>
      </Helmet>
      <HomePageHeroTitle
        heroTitle="Transforming Audiences into Fans, One Conversation at a Time"
        heroDescription="The AI agent that understands your users inside out, from day one—no ramp-up, just engagement and conversion results."
      />
      <img src="/HomePage/Hero.webp" alt="" className="HomePageHeroImage" width="100%" height="100%" />

      <CustomersShowcase />
      <Testimonials />
      <TopThreeFunctionsHomePage />
      <section className="one-platform-for-ecommerce-suc-wrapper">
        <h1 className="one-platform-for">One platform for online success</h1>
      </section>
      <OnePlatformForEcommerceSuccess />
      <HowItWorks />
    </div>
  );
};

export default RelevaHomepage;
