import { Helmet } from 'react-helmet';
import SubMenu from '../components/CookiePolicy/SubMenu';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CookiePolicy.css';

const TermsOfUseBg = () => {
  return (
    <div className="cookie-policy3">
      <Helmet>
        <title>Releva AI | Общи условия</title>
        <meta
          name="description"
          content="Review the Terms of Use for Releva AI to understand the guidelines and policies governing your use of our services. Ensure a clear understanding of your rights and responsibilities while using our AI-driven marketing solutions."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.termsOfUsePage)}</script>
      </Helmet>
      <section className="cookie-policy-container">
        <h1 className="cookie-policy4">Общи условия на Releva</h1>
      </section>
      <SubMenu active="terms-of-use" />
      <section className="at-releva-we-respect-individua-wrapper">
        <div className="at-releva-we-container">
          <p className="these-terms-of-use-define-the">
            <span className="these-terms-of-use-define-the1">
              <span className="these-terms-of">
                <p>
                  Тези Общи условия определят правната рамка за използването на Releva – Платформа за маркетингова
                  автоматизация.
                </p>
                <h5>1. Определения</h5>
                <ul>
                  <li>
                    <b>Releva</b> – Софтуер за Потребителско изживяване и Маркетинг автоматизация
                  </li>
                  <li>
                    <b>Потребител</b> – Вие, при условие че имате правоспособността да сключите правен договор (и сте
                    пълнолетен) или сте непълнолетно лице на възраст над 16 години и сте приели тези Общи условия.
                  </li>
                  <li>
                    <b>Потребителски акаунт</b> – сметка, позволяваща използването на Услугата, разбирано като
                    използване на изцяло платен пакет.
                  </li>
                  <li>
                    <b>Потребителски профил</b> – уредба, която може да съхранява информация, предоставена от Оператора
                    в рамките на ИКТ системата, която позволява на Потребителя да въвежда, съхранява и променя данни,
                    необходими за правилното използване на функциите на Releva. Тази информация се предоставя на Releva
                    доброволно и единствено от Потребителя.
                  </li>
                  <li>
                    <b>Месечни активни потребители (MАП)</b> – това са уникални крайни потребители, които са
                    взаимодействали с онлайн магазина на Потребителя през последните 30 дни. За целите на
                    ценообразуването МАП се вземат от ‘‘Аccount usage‘‘ панела на Релева.
                  </li>
                  <li>
                    <b>GDPR или „Законодателство за защита на данните“</b> означава Регламент (ЕС) 2016/679 („GDPR“, на
                    български - Общ регламент относно защитата на данните) и всяко национално приложимо законодателство;
                    както се изменят или заменят периодично или, при липса на такива закони, цялото законодателство,
                    разпоредби и задължителни насоки или задължителни за изпълнение кодекси.
                  </li>
                  <li>
                    <b>Услуги</b> – таргетиране и пренасочване на крайни клиенти на онлайн магазина на Потребителя чрез
                    мултиканал: поставяне на продуктови блокове с автоматизации и персонализации на уебсайта на
                    Потребителя, автоматизиране на персонализирани банери и изскачащи прозорци, персонализирани имейли,
                    изпращане на бюлетини с персонализирано съдържание, автоматизиране на кампании въз основа на
                    тригерирани събития към сегментирана аудитория и мониторинг на ефективността/резултатите на услугите
                    по канал.
                  </li>
                  <li>
                    <b>Уебсайт</b> – (www страница) документ, разработен на един от видовете SGML езици (HTML, XML,
                    PHP), изтеглен от интернет сървър и интерпретиран от Потребителя чрез браузър (един www. адрес).
                  </li>
                  <li>
                    <ul></ul>
                  </li>
                </ul>
                <h5>2. Декларации на Оператора и Потребителя на системата</h5>
                <p>
                  Операторът потвърждава, че притежава всички технически средства и инфраструктура, необходими за
                  сътрудничество съгласно настоящите Общи условия с всички потребители на Releva.
                </p>
                <p>
                  Потребителите на Releva декларират, че няма да използват Releva по начин, който би могъл да
                  представлява нарушение на общоприетите норми и правила за социално съжителство.
                </p>
                <h5>3. Права и задължения на Оператора</h5>
                <p>
                  <strong>Операторът има следните права:</strong>
                </p>
                <ol>
                  <li>
                    Операторът има право да блокира достъпа до акаунта на всеки потребител, който нарушава разпоредбите,
                    съдържащи се тук, както и да прекрати договора, сключен с приемането на тези Общи условия.
                  </li>
                  <li>
                    Операторът има право да блокира достъпа до акаунта на всеки потребител, който нарушава общоприетите
                    норми и правила, регулиращи използването на услуги от този вид.
                  </li>
                  <li>
                    Операторът има право да получи уговореното възнаграждение по реда и при условията на Договора. 
                  </li>
                  <li>Да иска от Потребителя необходимото съдействие за изпълнение на услугата и интеграцията.</li>
                  <li>
                    Операторът има правото да откаже предоставянето на определени услуги, ако тяхното съдържание
                    противоречи на изискванията на българското законодателство или на добрите нрави или сочи към явно
                    нарушение на права на трети лица.
                  </li>
                  <li>
                    Операторът има право да сподели за партньорството с Потребителя, включително, но не само, в
                    пределите на уеб пространство, презентации, конференции.
                  </li>
                </ol>
                <p>
                  <strong>Операторът има следните задължения:</strong>
                </p>
                <ol>
                  <li>
                    Операторът ще положи всички усилия, за да осигури най-високия възможен стандарт на услугите, налични
                    в Системата на Releva.
                  </li>
                  <li>
                    Оператора интегрира услугата за Потребителя безплатно, когато интеграцията е към платформа, която е
                    сред официално поддържаните за интеграция от Оператора. В случаите, когато интеграцията е към
                    платформа, която е извън официално поддържаните от Оператора или се налага модификация на съответния
                    интеграционен плъгин, Потребителя, ако желае, може да делегира частично или напълно интеграцията да
                    се извърши от страна на Оператора, като обхвата, сроковете и цената на тази интеграция се анексират
                    и стават неразделна част от договора.
                  </li>
                  <li>
                    Да извърши услугата качествено и в уговорените срокове, в пълно съответствие с уговореното в
                    Договора и тези Общи условия.
                  </li>
                  <li>
                    Оператора е длъжен да предоставя техническа поддръжка в рамките на работното време на Потребителя
                    (без официални празници и почивни дни), до 2 работни часа на седмична база.
                  </li>
                  <li>
                    При проблем, който има глобално влияние върху работата на продукта и води до намаляване на неговата
                    работоспособност, Операторът се задължава да реагира веднага и да уведоми Потребителя за обхвата на
                    проблема и действията, които ще предприеме за неговото решаване.
                  </li>
                  <li>
                    При проблем, който няма глобално влияние върху работата на продукта и води до намаляване на неговата
                    работоспособност и, но Потребителят може да извърши своята работа чрез други средства, Операторът се
                    задължава да реагира до 1 работен ден и отстрани проблема до 5 работни дни, след възникването му.
                  </li>
                  <li>Оператора се задължава да оказва необходимата помощ при интеграция на Потребителя.</li>
                  <li>
                    В случай, че интергацията от страна на Потребителя ще се извършва към платформа, която не се
                    поддържа официално от Оператора, Оператора се задължава да обучи техническия екип на Потребителя
                    безплатно със оглед постигане на целта от необходимата свързаност между системите на Потребителя и
                    Оператора.
                  </li>
                  <li>
                    Оператора се задължава да предостави на Потребителя безплатно първоначално маркетинг обучение в
                    рамките тестовия период до 4 часа, както и последващи консултации за осигуряване на оптимална
                    експлоатация на платформата. 
                  </li>
                  <li>
                    Операторът се задължава да пази в тайна всички материали, данни и информация, която ще получи по
                    време на изпълнението на дейностите по интеграция, тестови период и обучение, работата си с
                    Потребителя и няма право на нейното официално или неофициално разпространяване, без предварително
                    писмено съгласие на Потребителя.
                  </li>
                  <li>Да изпълни задълженията си по настоящия Договор с грижата на добър търговец.</li>
                </ol>
                <h5>4. Приемане на Общите условия</h5>
                <p>За използването на системата е необходимо Потребителят да приеме Общите условия.</p>
                <p>С приемането на тези Общи условия, Потребителят потвърждава, че: </p>
                <ol>
                  <li>всички данни, въведени в Договора, са пълни, верни и точни,</li>
                  <li>
                    дава съгласие за обработване от Оператора на лични данни относно Потребителя, Клиентите на
                    Потребителя, неговите контрагенти, лицата, посещаващи сайта. Вие ще поверите следните данни за
                    обработка: име и фамилия, имейл адрес, телефонен номер, адрес, финансова информация (данни за
                    фактура, история на покупки);
                  </li>
                  <li>
                    се е съгласил да получава фактури по ДДС, генерирани от доставчика на услугата в електронен формат,
                    по имейл.
                  </li>
                  <li>
                    разрешава на Оператора да използва личните данни само за целите на настоящия Договор, в съответствие
                    с разпоредбите на GDPR и Закона за защита на личните данни. За избягване на съмнение, приемането на
                    настоящите Общи условия и използването на Услугата ще се третира като предоставяне на данни за
                    обработка в съответствие с разпоредбите на GDPR и Закона за защита на личните данни. Освен това
                    Клиентите, които имат платен абонамент за Системата на Releva, заедно със сключването на основния
                    Договор сключват и Споразумението за възлагане на обработката на лични данни.
                  </li>
                </ol>
                <h5>5. Прекратяване на Договора</h5>
                <p>Договорът се прекратява при следните условия:</p>
                <ol>
                  <li>По желание на Възложителя след изтичане на тестовия период.</li>
                  <li>По взаимно съгласие на Страните - изявено писмено с едномесечно предизвестие.</li>
                  <li>
                    При виновно неизпълнение на задълженията повече от 30 /тридесет/ дни на една от страните по Договора
                    със 7 /седем/ дневно писмено предизвестие от изправната до неизправната страна. 
                  </li>
                  <li>
                    Ако интеграцията не бъде стартирана по вина на Потребителя, без да бъде уведомена другата страна, че
                    имат нужда от удължаване на периода, Оператора има право да прекрати договора и обратното, без
                    дължими неустойки.
                  </li>
                  <li>
                    Едностранно, без предизвестие от всяка от страните, в случай, че бъде открито производство по
                    несъстоятелност или ликвидация по отношение на другата страна.
                  </li>
                  <li>Условия изрично договорени в двустранния Договор между Потребителя и Оператора</li>
                </ol>
                <h5>6. Процедура за рекламации</h5>
                <p>
                  Рекламациите на Потребители относно неизпълнение от страна на Оператора на разпоредбите на Договора
                  или неправилно изпълнение на договорни разпоредби се изпращат до Оператора по електронен път на
                  следния адрес: info@releva.ai и трябва да съдържат по-специално:
                </p>
                <ol>
                  <li>данни за контакт на Потребителя,</li>
                  <li>мотиви за подаване на рекламацията,</li>
                  <li>описание на обстоятелствата, които са в основата на рекламацията на Потребителя.</li>
                </ol>
                <h5>7. Задължения на Потребителя</h5>
                <p>
                  <strong>Потребителят се задължава:</strong>
                </p>
                <ol>
                  <li>
                    да уведомява Оператора за всякакви промени в данните, необходими за фактуриране и други разплащания,
                    при условие че всички съответни имейл съобщения се доставят на неактуализиран адрес,
                  </li>
                  <li>
                    Да оказва съдействие на Оператора, за да извърши техническа свързаност на услугата качествено в
                    пълно съответствие с уговореното в тези Общи условия.
                  </li>
                  <li>
                    Да оказва съдействие на Оператора за първоначалното маркетингово обучение в рамките на тестовия
                    период до 4 /четири часа/, както и за последващи консултации за осигуряване на оптимална
                    експлоатация на платформата. Ако Потребителя НЕ употреби обучителните часовете по негова вина, без
                    това да е комуникирано с Оператора в рамките на гратисния тестови период, Оператора има право да
                    издаде фактура на база потреблението.
                  </li>
                  <li>Да заплаща цената на абонамента по Договора по реда и при условията в Договора.</li>
                  <li>
                    Да не възпрепятства Оператора и да не нарушава оперативната му самостоятелност, във връзка с
                    изпълнението на Договора.
                  </li>
                  <li>
                    Да обучи техническия си екип с оглед постигане на целта от необходимата свързаност между системите
                    на Потребителя и Оператора, в случай, че интергацията от страна на Потребителя ще се извършва към
                    платформа, която не се поддържа официално от Оператора.
                  </li>
                  <li>
                    Да пази в тайна всички материали, данни и информация, която ще получи по време на изпълнението на
                    дейностите по интеграция, тестови период и обучение, работата си с Оператора и няма право на нейното
                    официално или неофициално разпространяване, без предварително писмено съгласие на Оператора.
                  </li>
                  <li>да спазва тези Общи условия,</li>
                  <li>да спазва общоприложимите правни разпоредби,</li>
                  <li>да спазва правата на трети страни.</li>
                </ol>
                <p>
                  <strong>Потребителят има право:</strong>
                </p>
                <ol>
                  <li>Да оказва текущ контрол при извършването на всички действия по изпълнението на Договора.</li>
                  <li>
                    Да изисква от Оператора изпълнението на възложената услуга в срок, без отклонение от договореното.
                  </li>
                  <li>
                    Да изисква от Оператора поправката на всички неизправности в изработката и отстраняването на всички
                    несъответствия с параметрите на офертата на Оператора, установени до изтичането на уговорения срок
                    при условията на Договора и тези Общи условия. 
                  </li>
                </ol>
                <h5>8. Технически ограничения и ограничения, свързани със съдържанието</h5>
                <p>
                  <strong>Операторът си запазва правото:</strong>
                </p>
                <ol>
                  <li>периодично да прекъсва наличността на Услугата с цел нейното разширяване или поддръжка,</li>
                  <li>незабавно да прекрати предоставянето на услугите, ако Потребителят наруши тези Общи условия,</li>
                  <li>
                    да променя параметрите на Акаунт или Потребителски профил, включително характеристики и функции на
                    Услугата,
                  </li>
                  <li>
                    да прекрати предоставянето на услугите по всяко време, като затвори цялата Услуга или някоя от
                    нейните части, при предварително поставяне на съответното уведомление на уебсайта на Услугата.
                  </li>
                </ol>
                <p>
                  <strong>Операторът изключва отговорността си за:</strong>
                </p>
                <ol>
                  <li>
                    всякакви щети, свързани с намеса на трета страна, неправилно функциониране на външни фактори или
                    други системи (напр. телекомуникационни мрежи) извън контрола на Оператора,
                  </li>
                  <li>щети в резултат на настъпване на непреодолима сила (форс мажор),</li>
                  <li>
                    всякакви щети в резултат на неоторизиран достъп до Акаунта или Профила на Потребителя в резултат на
                    Ваша небрежност,
                  </li>
                  <li>всякакви щети, произтичащи от неспазване от страна на Потребителя на тези Общи условия,</li>
                  <li>
                    всякакви щети в резултат на неправилен трансфер на данни в системата, неправилен запис или
                    получаване на съобщения, както и всяка загуба на данни, съдържащи се в прехвърлените съобщения.
                  </li>
                  <li>
                    вреди за Потребителя в резултат на рискови фактори, характерни за Интернет, включително системни
                    атаки или заразяване на системата на Потребителя със злонамерен софтуер.
                  </li>
                </ol>
                <p>
                  Операторът полага всички усилия, за да гарантира, че използването на услугите е достъпно за
                  Потребителите на всички най-популярни уеб браузъри, операционни системи, видове компютри и видове
                  интернет връзки. Въпреки това, Операторът не гарантира и не носи отговорност за успешното използване
                  на всяка възможна комбинация от тези опции и/или Системата на Releva. Минималните технически
                  изисквания (независимо от информацията, предоставена в предходното изречение), които позволяват
                  използването на услуги и/или Системата на Releva, са следните: достъп до интернет, модерен уеб
                  браузър, който поддържа HTML5, CSS3 и JavaScript (препоръчителни уеб браузъри: Chrome 74, Firefox 65,
                  Edge 44 и най-новите версии на всички споменати тук).
                </p>
                <h5>9. Права на интелектуална собственост</h5>
                <p>
                  Всички права на интелектуална собственост, притежавани от Оператора, независимо дали се използват при
                  изпълнението на задълженията по Договора или по друг начин, остават за Оператора, като Потребителят
                  няма да придобива никакво право или интерес върху такива права на интелектуална собственост.
                </p>
                <h5>10. Поверителност и лични данни</h5>
                <ol>
                  <li>
                    Този параграф съдържа обща информация относно използването и обработването на личните данни на
                    Потребителите от Оператора и личните данни на Клиентите на Потребителите, които се предоставят на
                    Оператора.
                  </li>
                  <li>Базата данни с Потребителски Акаунти е обект на правна защита.</li>
                  <li>
                    Операторът действа като Администратор на лични данни, предоставени от Потребителя при регистрация на
                    Акаунт в Системата и обработването им е необходимо, за да може Потребителят да използва Системата.
                  </li>
                </ol>
                <p>
                  За личните данни на лица, които се намират в базата данни и които Потребителят е въвел в Системата -
                  Операторът е Обработващият тези лични данни, а Потребителят е Администраторът на тези лични данни или
                  Обработващият, както е определено от GDPR. Тези лични данни се предоставят на Оператора от или от
                  името на Потребителя; и/или са получени от или създадени от Оператора от името на Потребителя в
                  процеса на предоставяне на Услугите. Потребителят носи пълна отговорност в случай на липса на правно
                  основание за обработка на лични данни, въведени в Системата или ако обработката на тези данни е в
                  противоречие с целта, за която са получени, или в нарушение на принципа за минимизиране, произтичащ от
                  Чл. 5.1 (в) на GDPR. За такива лични данни се прилагат разпоредбите на точка 11 от настоящия параграф.
                </p>
                <ol start="5">
                  <li>
                    {' '}
                    Операторът, като Администратор по смисъла на Чл. 4, параграф 7 на GDPR декларира, че обработва
                    личните данни на Потребителите в съответствие с разпоредбите на Закона за защита на данните. Ако
                    имате въпроси относно обработката на Вашите лични данни и Вашите права, моля, свържете се с нас –
                    нашето Длъжностно лице по защита на данните (ДЛЗД) – на имейл на адрес: info@releva.ai.
                  </li>
                  <li> Права на Потребителя: </li>
                </ol>
                <p>
                  6.1. Вие имате право да оттеглите всяко съгласие, което сте дали по време на абонамента за Услугата,
                  както и по време на използването на определени услуги и функционалности, предлагани в Услугата.
                  Оттеглянето на съгласието има действие от момента на оттеглянето. Оттеглянето на Вашето съгласие не
                  засяга обработването, което извършваме в съответствие със Закона преди неговото оттегляне. Оттеглянето
                  на съгласието не води до никакви негативни последици за Вас. Това обаче може да Ви попречи да
                  продължите да използвате Услугите или функционалностите, които можем законно да предоставим само с
                  Вашето съгласие.
                </p>
                <p>
                  6.2. Имате право по всяко време да възразите срещу използването на Вашите лични данни, включително
                  профилиране, ако обработваме Вашите данни въз основа на наш легитимен интерес, напр. във връзка с
                  маркетинга на нашите продукти и услуги и нашите клиенти, водене на статистика за използването на
                  определени функционалности на Услугите и улесняване на използването на Услугите, както и проучвания за
                  удовлетвореност.
                </p>
                <p>
                  Ако не получавате никакви маркетингови съобщения относно нашите продукти или услуги или нашите
                  клиенти, ние ще възразим срещу обработването на вашите лични данни, включително профилиране за тези
                  цели. Ако Вашето възражение се окаже основателно и нямаме друго правно основание за обработка на
                  Вашите лични данни, ние ще изтрием данните, срещу които сте възразили.
                </p>
                <p>6.3. Правно основание: Чл. 17 на GDPR</p>
                <p>Право на изтриване („право да бъдеш забравен“)</p>
                <p>
                  Имате право да поискате изтриване на всички или на някои лични данни. Ние ще третираме искането за
                  изтриване на всички или част от вашите лични данни като искане за изтриване на Вашия акаунт. Имате
                  право да поискате изтриване на Вашите лични данни, ако:
                </p>
                <ul>
                  <li>
                    Вие сте оттеглили Вашето конкретно съгласие, доколкото Вашите лични а били обработени въз основа на
                    Вашето съгласие;
                  </li>
                  <li>Вашите лични данни вече не са необходими за целите, за които са събрани или обработвани;</li>
                  <li>Възразили сте срещу използването на Вашите данни за маркетингови цели;</li>
                  <li>
                    Възразили сте срещу използването на Вашите данни за целите на поддържане на статистика за
                    използването на Услугата и данни с анкети за удовлетвореност и възражението е счетено за
                    основателно;
                  </li>
                </ul>
                <ul>
                  <li>Вашите лични данни се обработват незаконно.</li>
                </ul>
                <p>
                  Въпреки че можем да поискаме изтриване на лични данни във връзка с повдигане на възражение или
                  оттегляне на съгласие, можем да запазим определени лични данни до степента, необходима за
                  установяване, преследване или защита на иск. Това включва, но не се ограничава, до лична информация
                  като име, фамилия, имейл адрес и хронология на приложенията, които запазваме за целите на разследване
                  на жалби и искове, свързани с използването на нашите услуги.
                </p>
                <p>6.4. Правно основание: Чл. 18 на GDPR</p>
                <p>Право на ограничаване на обработването на лични данни</p>
                <p>
                  Имате право да поискате ограничаване на обработката на Вашите лични данни. Ако направите такова
                  искане, ние ще Ви ограничим използването на определени функционалности или услуги, които ще включват
                  обработката на исканите данни, докато такова искане не бъде обработено. Няма да Ви изпращаме никакви
                  съобщения, включително маркетингови съобщения. Имате право да поискате ограничаване на използването на
                  Вашите лични данни в следните случаи:
                </p>
                <ul>
                  <li>
                    ако се съмнявате в точността на Вашите лични данни – тогава ще ограничим използването на Вашите
                    данни за времето, което ни е необходимо, за да проверим точността на Вашите данни, но не повече от 7
                    дни;
                  </li>
                  <li>
                    когато обработването на Вашите данни е в нарушение на закона и вместо изтриване на Вашите данни, Вие
                    ще поискате ограничаване на използването им;
                  </li>
                  <li>
                    когато Вашата лична информация вече не е необходима за целите, за които сме я събрали или
                    използвали, но е необходима на Вас за установяване, преследване или защита на иск;
                  </li>
                </ul>
                <ul>
                  <li>
                    ако сте възразили срещу използването на Вашите данни, тогава ограничението е за времето, необходимо
                    за преценка дали, поради Вашата конкретна ситуация, защитата на Вашите интереси, права и свободи
                    надделява над интересите, които преследваме, обработвайки Вашите лични данни.
                  </li>
                </ul>
                <p>6.5. Правно основание: Чл. 15 на GDPR</p>
                <p>Право на достъп до данни</p>
                <p>
                  Имате право да получите потвърждение от нас дали обработваме вашите лични данни и ако това се случи,
                  имате право:
                </p>
                <ul>
                  <li>на достъп до Вашите лични данни;</li>
                  <li>
                    да получите информация за целите на обработването, категориите лични данни, които се обработват, за
                    получателите или категориите получатели на тези данни, планирания период на съхранение на Вашите
                    данни или критериите за определяне на този период, Вашите права съгласно GDPR и правото да подадете
                    жалба до надзорния орган относно източника на данните, относно автоматизираното вземане на решения,
                    включително профилиране и функции за сигурност, използвани във връзка с прехвърлянето на тези данни
                    извън Европейския съюз;
                  </li>
                  <li>да получите копие от Вашите лични данни.</li>
                </ul>
                <p>6.6. Правно основание: Чл. 16 на GDPR</p>
                <p>Право на корекция</p>
                <p>
                  Имате право да коригирате и допълвате предоставените от Вас лични данни. Можете да направите това сами
                  в раздела Настройки (Поверителност). По отношение на други лични данни имате право да поискате от нас
                  да коригираме тези данни (ако са неверни) и да ги допълним (ако са непълни).
                </p>
                <p>6.7. Правно основание: Чл. 20 на GDPR</p>
                <p>Право на преносимост на данните</p>
                <p>
                  Имате право да получите Вашите лични данни, които сте ни предоставили, и след това да ги изпратите на
                  друг администратор на лични данни по Ваш избор, напр. друг оператор на подобни услуги. Вие също имате
                  право да поискате Вашите лични данни да бъдат изпратени от нас директно на такъв друг администратор,
                  ако това е технически осъществимо.
                </p>
                <p>
                  Вашите лични данни ще бъдат изпратени като файл в csv формат. Форматът csv е често използван
                  машинночетим формат, който Ви позволява да изпратите получените данни на друг администратор на лични
                  данни.
                </p>
                <ol start="7">
                  <li> Правно основание: Чл. 12 на GDPR</li>
                </ol>
                <p>Време за изпълнение на искането</p>
                <p>
                  Ако при упражняване на правомощията, изброени по-горе, отправите искане към нас, ние или изпълняваме
                  това искане, или отказваме да го изпълним незабавно, но не по-късно от един месец след получаването
                  му. Ако обаче, поради сложността на заявката или броя на заявките, не можем да изпълним заявката Ви в
                  рамките на един месец, ние ще я изпълним в рамките на следващите два месеца, като Ви информираме
                  предварително за планираното удължаване.
                </p>
                <p>
                  Поради технически причини винаги се нуждаем от 24 часа, за да актуализираме настройките, които сте
                  избрали в нашите системи. Поради това може да се случи да получите имейл от нас по време на
                  актуализацията на системата, от която сте се отказали.
                </p>
                <ol start="8">
                  <li>
                    {' '}
                    Операторът полага дължимата грижа, когато гарантира поверителността на всички данни, получени от
                    Потребителя, относно всички бизнес партньори и контрагенти на Потребителя, както и всякакви
                    кампании, стартирани от Потребителя посредством Системата на Releva.
                  </li>
                </ol>
                <p>Гореспоменатото изискване за поверителност не обхваща ситуации, в които:</p>
                <ul>
                  <li>данните са общоизвестни,</li>
                  <li>данните са разкрити със съгласието на Потребителя,</li>
                  <li>
                    данните са били разкрити при получаване на съответно искане от официален орган, при спазване на
                    приложимите законови разпоредби.
                  </li>
                </ul>
                <ol start="9">
                  <li>
                    {' '}
                    Операторът се задължава никога да не разкрива информацията, въведена и съхранявана от Потребителя в
                    Акаунта на Потребителя в системата, по-специално данните за контакт. Потребителят притежава
                    изключително всички данни в Акаунта на Потребителя в системата. Операторът гарантира безопасността
                    на данните, съхранявани в системата, в съответствие с приложимите законови разпоредби.
                  </li>
                  <li>
                    {' '}
                    Операторът се задължава да използва въведените в системата данни от Потребителя за никакви други
                    цели, освен за извършване на дейности, приложими за предоставяне на Услугите. Вашата лична
                    информация може да бъде обект на профилиране за целите, за които е събрана. Тези данни обаче няма да
                    бъдат използвани от Администратора за вземане на автоматични решения.
                  </li>
                  <li>
                    {' '}
                    Потребителят, в качеството си на Администратор на лични данни, предава на Оператора, в качеството му
                    на Обработващ лични данни, данните за контакт на клиентите и потенциалните клиенти на Потребителя,
                    особенно по отношение на: име и фамилия, имейл адрес, телефонен номер, IP адрес, поведенчески данни.
                  </li>
                </ol>
                <p>11.1. Обработка на лични данни от Потребителя:</p>
                <p>
                  11.1.1. Потребителят обработва лични данни във връзка със своите услуги в съответствие с изискванията
                  на Закона за защита на данните.
                </p>
                <p>
                  11.1.2. Инструкциите на Потребителя за обработка на лични данни трябва да са в съответствие със
                  Законодателството за защита на данните и не изискват от Оператора да предприеме неправомерни действия
                  по обработка, за да се съобрази с тези инструкции.
                </p>
                <p>
                  11.1.3 Потребителят носи цялата отговорност за точността, качеството и законосъобразността на личните
                  данни и, когато Потребителят е придобил личните данни, средствата, чрез които Потребителят е придобил
                  личните данни.
                </p>
                <p>11.1.4. Потребителят гарантира и поема отговорност, че:</p>
                <ul>
                  <li>
                    разкриването на лични данни от Оператора е ограничено до това, което е необходимо, за да може
                    Операторът да извършва Услугите;
                  </li>
                  <li>
                    такива лични данни са точни и актуални към момента, в който са предоставени на Оператора и
                    Потребителят трябва своевременно да уведоми Оператора за всички необходими корекции, изменения,
                    изтривания или ограничения; и
                  </li>
                  <li>
                    има и трябва да поддържа правните основания за Обработване, включително всички необходими съгласия и
                    известия, необходими, за да позволи на Оператора да обработва законно личните данни за срока и
                    целите на Услугите.
                  </li>
                </ul>
                <p>11.2. Обработка на лични данни от Оператора</p>
                <p>
                  11.2.1 Операторът обработва лични данни във връзка с Услугите в съответствие с изискванията на Закона
                  за защита на данните и само както е посочено в писмените инструкции на Потребителя.
                </p>
                <p>
                  11.2.2. Операторът обработва лични данни от името и в съответствие с писмените инструкции на
                  Потребителя, във всеки отделен случай до степента, разрешена от закона, а при невъзможност да направи
                  това, Операторът уведомява предварително Потребителя.
                </p>
                <p>
                  11.2.3. Потребителят се съгласява, че ще възстанови на Оператора всички направени разходи или
                  плащания, изплатени в резултат на иск, предявен от субект на данни, произтичащ във връзка със
                  спазването от страна на Оператора на инструкциите на Потребителя.
                </p>
                <p>
                  11.2.4. Ако Операторът основателно смята, че инструкциите, предоставени от Потребителя във връзка с
                  обработката, противоречат на Законите за защита на данните, тогава Операторът ще уведоми Потребителя и
                  може да спре обработката до момента, в който Потребителят предостави нови писмени инструкции на
                  Оператора, които не изискват от Оператора да нарушава приложимото законодателство и Операторът има
                  право да:
                </p>
                <ul>
                  <li>
                    модифицира Услугите, така че да могат да се изпълняват, без да се изисква съответната обработка и
                    без да се влошава съществено цялостното представяне на Услугите; и/или
                  </li>
                  <li>
                    преустанови предоставянето на съответната част от Услугите, която зависи от обработката, и
                    Операторът не носи отговорност за каквото и да е забавяне или невъзможност за предоставяне на
                    Услуги, зависещи от такава обработка.
                  </li>
                </ul>
                <p>
                  11.3. Операторът се задължава да обработва поверените данни за контакт само за целите на изпълнение на
                  Услугата – възможността за използване на Releva въз основа на документирани поръчки на Потребителя,
                  които се отнасят и за предаване на данните за контакт на трета държава или международна организация.
                </p>
                <p>
                  11.4. При писмено искане на Потребителя, Операторът е длъжен да предостави информация относно
                  обработката на поверените му лични данни, включително техническите и организационни данни, използвани
                  за обработка на данните в обхвата, посочен от искането на Потребителя.
                </p>
                <p>
                  11.5. Операторът информира Администратора преди започване на обработката на данни за изпълнението на
                  евентуално законово задължение, състоящо се в предаване на лични данни на трета държава или
                  международна организация.
                </p>
                <p>
                  11.6. Операторът гарантира, че лицата, упълномощени да обработват личните данни, са се ангажирали с
                  поверителност или са под съответното законово задължение за поверителност.
                </p>
                <p>
                  11.7. Операторът декларира, че е предприел предпазни мерки, изисквани съгласно Чл. 28, ал. 3 (в) на
                  GDPR. Операторът използва сървърната инфраструктура в Сен Гислен (St. Ghislain), Белгия, Европа.
                </p>
                <p>
                  11.8. Операторът декларира, че спазва условията, посочени в параграфи 2 и 4 от Чл. 28 на GDPR за
                  ангажиране на друг обработващ, в съответствие с Чл. 28, параграф 3 (г) от GDPR.
                </p>
                <p>
                  11.9. Операторът взема предвид естеството на обработката, подпомага Потребителя чрез подходящи
                  технически и организационни мерки за изпълнение на задължението на Потребителя да отговаря на искания
                  за упражняване на правата на субекта на данните, посочени в чл. 28, параграф 3 (д) на GDPR.
                </p>
                <p>
                  11.10. Операторът предоставя на Потребителя цялата информация, необходима за демонстриране на
                  спазването на задълженията, посочени в този член, и позволява и допринася за одити, включително
                  проверки, извършвани от Администратора или друг одитор, упълномощен от Администратора, в съответствие
                  с Чл. 28, ал. 3, (з) на GDPR.
                </p>
                <p>
                  11.11. Нито една от страните няма да носи отговорност съгласно това Споразумение за защита на данните
                  за каквато и да е загуба на действителен или очакван доход или печалби, загуба на договори или за
                  всякаква специална, косвена или последваща загуба или щета от всякакъв вид, както и да е възникнала и
                  независимо дали е причинена от правонарушение (включително небрежност), нарушение на договор или по
                  друг начин, независимо дали такава загуба или щета е предвидима, предвидена или известна.
                  Отговорността на Оператора по отношение на което и да е нарушение на разпоредбите, посочени в клаузи
                  11.1. до 11.10 по-горе възлиза на преките щети, понесени от Потребителя, но във всеки случай не повече
                  от общата сума на действително платените от Потребителя такси.
                </p>
                <p>
                  11.11.1. Съгласно клауза 11.11 Операторът ще обезщети Потребителя срещу всички загуби, щети,
                  отговорности, претенции, искания, действия, неустойки, глоби, присъждания, разходи и разноски
                  (включително разумни правни и други професионални разходи), глоби и санкции, които могат да бъдат
                  понесени от Потребителя в резултат на иск, дело, производство или действие на Регулатора, заведено
                  срещу Потребителя, произтичащо пряко от нарушение от страна на Оператора на разпоредбите, изложени
                  по-горе в клаузи 11.1. до 11.10, с изключение на:
                </p>
                <ul>
                  <li>
                    случаите, когато Операторът е действал в съответствие с инструкциите на Потребителя, разпоредбите,
                    изложени по-горе в клаузи 11.1. до 11.10, Законите за защита на данните или други приложими закони;
                    и
                  </li>
                  <li>
                    до степента, в която Потребителят или трета страна, действаща от името на Потребителя, е нарушила
                    разпоредбите, посочени по-горе в клаузи 11.1. до 11.10 или други приложими закони за защита на
                    данните.
                  </li>
                </ul>
                <p>
                  11.11.2. Потребителят трябва да обезщети Оператора срещу всички загуби, щети, задължения, претенции,
                  искания, действия, неустойки, глоби, присъждания, разходи и разноски (включително разумни правни и
                  други професионални разходи), глоби и санкции, които могат да бъдат понесени от Оператора в резултат
                  на иск, дело, производство или действие на регулатора, заведено или заплашено срещу Оператора,
                  произтичащо пряко от или във връзка със спазването на Оператора с писмените инструкции на Потребителя
                  относно обработката на лични данни.
                </p>
                <p>
                  11.11.3. За да предяви иск по обезщетение, посочено в разпоредбата на клауза 11.11, страната,
                  предявила иска, трябва:
                </p>
                <ul>
                  <li>
                    да изпрати писмено известие за съответния иск, дело, производство или действие на Регулатора на
                    другия възможно най-скоро;
                  </li>
                  <li>
                    да не признава отговорност във връзка с основния иск, дело, производство или действие на Регулатора
                    без предварителното писмено съгласие на другата страна;
                  </li>
                  <li>
                    да позволи на другата страна да проведе защитата на основния иск, дело, производство или действие на
                    Регулатора; и
                  </li>
                  <li>
                    за сметка на другата страна да сътрудничи и съдейства в разумна степен при защитата на основния иск,
                    дело, производство или действие на Регулатора.
                  </li>
                </ul>
                <p>
                  11.12. Разпоредбите на клауза 11 остават в сила за срока на Споразумението безсрочно или докато
                  Операторът не върне или анонимизира всички лични данни, предоставени му от Потребителя.
                </p>
                <ol start="12">
                  <li>
                    {' '}
                    Операторът полага всички усилия, за да гарантира, че всички лични данни се съхраняват за времето,
                    необходимо за постигане на целите, за които са събрани. Доколкото е възможно, ние се опитваме да
                    осигурим определен период на съхранение на данните.
                  </li>
                </ol>
                <p>
                  12.1. 1 месец след отказа  на Потребителя от използването на Системата на Releva, Операторът ще
                  премахне окончателно всички записи, съдържащи лични данни, предадени на Оператора във връзка или по
                  време на използване на Системата на Releva, в съответствие с Чл. 28, ал. 3 (ж) на GDPR.
                </p>
                <p>
                  12.2. Нашите Услуги са динамични и ние често въвеждаме нови функции, които може да изискват събиране
                  на нова информация. Ако започнем да събираме друга лична информация или значително променим начина, по
                  който я събираме, използваме или споделяме тази информация, ще Ви уведомим и ще направим промени в
                  тези Общи условия.
                </p>
                <p>
                  12.3. Операторът няма да обработва, съхранява или прехвърля Лични данни извън Европейското
                  икономическо пространство („ЕИП“) без предварително писмено разрешение от Потребителя. Счита се, че
                  Операторът има разрешение да прехвърля данни към Подобработващ, ако има решение за съответствие или
                  друг валиден законен механизъм за прехвърляне (като, но не само, Стандартните договорни клаузи на
                  Европейската комисия), както е необходимо за предоставяне на Услугите, предмет на тези Общи условия.
                </p>
                <p>
                  12.3.1. Ако някакви Лични данни, прехвърлени между Потребителя и Оператора, изискват изпълнение на
                  Стандартните договорни клаузи на Европейската комисия, за да се съобразят със Законодателството за
                  защита на данните, страните ще попълнят всички съответни подробности и ще изпълнят Стандартните
                  договорни клаузи на Европейската комисия и ще предприемат всички други действия, необходими за
                  легитимиране на прехвърлянето. Потребителят упълномощава Оператора да сключи Стандартните договорни
                  клаузи на Европейската комисия с Подобработващите от името на Потребителя и от негово име, когато е
                  необходимо за отчитане на оторизирани трансфери или достъп до лични данни извън ЕИП.
                </p>
                <p>
                  12.4. Вие можете да подавате жалби, запитвания и молби до нас относно обработката на Вашите лични
                  данни и изпълнението на правата Ви.
                </p>
                <h5>11. Условия за плащане за ползване на системата</h5>
                <p>
                  Потребителят ще заплаща Таксите, приложими за абонамента на Потребителя за Услугите („Абонаментни
                  такси“) и всички други приложими такси, включително, но не само, приложими такси, свързани с растежа
                  на бизнеса на Потребителя („Цена на базата на месечни активни потребители (MАП)“) , както и всички
                  такси, свързани със закупуването от Потребителя на допълнителни услуги като Viber, sms или други
                  („Допълнителни такси“), в случай че не е уговорено друго в Споразумението. В случаите, в които
                  Потребителя надвишава нормалното използване на услугата (над 15 бр. маркетинг имейли и уеб пушове на
                  регистриран клиент), цената се договаря писмено по имейл. Заедно Таксите за абонамент, цената на база
                  MАП и допълнителните такси се наричат „Такси“.
                </p>
                <p>
                  Абонаментните такси и цената на база MАП се заплащат предварително и ще бъдат таксувани на интервали
                  от 30/31 дни (всяка такава дата е „Дата на фактуриране“). Допълнителни такси ще бъдат налагани
                  периодично спрямо потреблението. Потребителите ще бъдат таксувани на всяка Дата на фактуриране за
                  всички дължими Такси, които не са били таксувани преди това. Таксите ще се показват във фактура, която
                  ще бъде изпратена на Потребителя чрез предоставения имейл адрес за връзка. Потребителите разполагат с
                  приблизително две седмици, за да повдигнат и уредят всякакви проблеми с фактурирането на Таксите.
                </p>
                <p>
                  Първата фактура, издадена от Оператора ще обхваща периода от края на тестовия период до първо /1-во/
                  число на следващия месец и ще бъде изчислена на база МАП по време на тестовия период. Тя ще бъде
                  издадена след писмено потвърждение от страна на Потребителя, че иска да продължи използването на
                  предоставяните от Оператора услуги. Фактурата трябва да бъде издадена до 5 календарни от Оператора и
                  заплатена до 10 календарни дни след издаването ѝ.
                </p>
                <p>
                  Всяка следваща фактура се изчислява от първо /1-во/ до последно число на предстоящия месец, въз основа
                  на МАП от изминалият месец. Фактурите трябва да бъдат издадени до 5 календарни от началото на месеца
                  от Оператора и заплатени до 10 календарни дни след издаването им от Потребителя.
                </p>
                <p>Фактурираните суми се превеждат по следните банкови сметки:</p>
                <p>За суми в лева: IBAN: BG69STSA93000027131494, открита в Банка ДСК, с титуляр Релева АД.</p>
                <p>За суми в евро: IBAN: BG13STSA93000028804879, открита в Банка ДСК, с титуляр Релева АД.</p>
                <p>
                  Ако Releva не получи плащане на такси в рамките на 28 дни от издаването на фактурата, Releva може да
                  спре и отмени достъпа до Акаунта на Потребителя и Услугите. Акаунтът на Потребителя ще бъде активиран
                  отново след плащане от страна на Потребителя на всички дължими Такси, плюс таксите, приложими за
                  следващия цикъл на фактуриране. Потребителят може да няма достъп до Акаунта на Потребителя по време на
                  периода на спиране. Ако дължимите Такси останат неплатени в продължение на 60 дни след датата на
                  спиране, Releva си запазва правото да прекрати Акаунта на Потребителя.
                </p>
                <p>
                  При забава на плащане на възнаграждението с повече от 5 календарни дни след срока за плащане, без да е
                  комуникирана причина, считана от Оператора за ‘‘уважителна‘‘,&nbsp; Потребителят дължи на Оператора
                  неустойка в размер на 0,5% от неплатената сума за всеки просрочен ден.
                </p>
                <p>
                  Releva си запазва правото да променя приложимите Такси с писмено уведомление и ще уведоми Потребителя
                  по имейл най-малко 30 дни преди промяната на Таксите. Ако Потребителят не е съгласен с промяната, той
                  трябва да се откаже и да спре да използва Услугите, преди промяната на цената да влезе в сила. Ако има
                  фиксиран срок и Такса за потребителска услуга, тази Такса ще остане в сила за първите дванадесет
                  месеца от датата на влизане в сила на Споразумението.
                </p>
                <h5>12. Заключителни разпоредби</h5>
                <p>
                  Всички въпроси извън обхвата на настоящите Общи условия са предмет на съответните разпоредби на
                  Гражданския кодекс и други български закони.
                </p>
                <p>
                  По отношение на всякакви спорове, възникнали във връзка с Услугата, страните се задължават да
                  разрешават всички такива спорове по взаимно съгласие и при надлежно зачитане на интересите на другата
                  страна.
                </p>
                <p>
                  От Потребителите на Releva се изисква да следят всички промени в тези Общи условия, достъпни на
                  <a href="http://releva.ai"> releva.ai.</a>
                </p>
              </span>
            </span>
          </p>
        </div>
      </section>{' '}
    </div>
  );
};

export default TermsOfUseBg;
