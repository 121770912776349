import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PartnersList from '../components/PartnersListing/PartnersList';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudiesListing.css';

const useQueryContent = () => {
  return new URLSearchParams(useLocation().search);
};

const Partners = () => {
  const query = useQueryContent();
  const itemUrl = query.get('type');
  const [setCategory] = useState('All');

  useEffect(() => {
    if (itemUrl) {
      const capitalizedItemUrl = itemUrl.charAt(0).toUpperCase() + itemUrl.slice(1);
      setCategory(capitalizedItemUrl);
    }
  }, [itemUrl, setCategory]);

  // const handleCategoryChange = (newCategory) => {
  //   setCategory(newCategory);
  // };

  return (
    <div className="case-studies-content-for-each">
      <Helmet>
        <title>Releva AI | Partners</title>
        <meta
          name="description"
          content="Join Releva.ai Partners to enhance your business with AI-driven solutions. Collaborate for growth, innovation, and tailored offerings that deliver exceptional value to clients."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.partnersPage)}</script>
      </Helmet>
      <section className="case-studies-title-parent">
        <h1 className="case-studies2">Partners</h1>
      </section>
      <section className="case-studies-description-paren">
        <div className="discover-how-releva">Collaborate with Releva.ai for Cutting-Edge AI Solutions</div>
      </section>
      {/* <section className="sub-menu3">
        <div className="menu-items3">
          <div
            className={`all-case-studies-wrapper ${category === 'All' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('All')}
          >
            <Link to="/content-library" className="all-case-studies">
              All
            </Link>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'News' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('News')}
          >
            <Link to="/content-library?type=news" className="all-case-studies">
              News
            </Link>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Blog' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Blog')}
          >
            <Link to="/content-library?type=blog" className="all-case-studies">
              Blog
            </Link>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Press Release' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Press Release')}
          >
            <Link to="/content-library?type=press%20Release" className="all-case-studies">
              Press Release
            </Link>
          </div>
        </div>
      </section> */}
      <div className="case-studies-content-for-each-inner">
        <div className="frame-child14" />
      </div>
      {/* <div className="slow-moving-title-parent">
        <h2 className="slow-moving-consumer-goods1">{category}</h2>
      </div> */}
      <PartnersList category="" />
    </div>
  );
};

export default Partners;
