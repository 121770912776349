import './HowItWorks1.css';

const HowItWorks2 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/MessageHubPage/omni4.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">04</div>
          <div className="master-your-generated">Customer Engagement with</div>
          <div className="edit-your-documents">WhatsApp Messaging</div>
          <div className="customize-your-generated">
            Your customers are not responding well to your SMS and email marketing? Don't worry, Releva got you covered.
            Communicate with customers using WhatsApp and manage your retargeting campaigns with ease. Provide
            personalized special offers aligned with their needs and desires, and transform them from one-time buyers
            into loyal customers.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks2;
