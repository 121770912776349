const messageHubFeatures = [
  {
    feature: 'Campaigns',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Workflows',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'SMS',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Whatsapp',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Viber',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Dynamic coupons',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  // {
  //   feature: 'Customer segmentation ',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Pop-up and banners',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Post-Purchase',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Analytics',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
];

export default messageHubFeatures;
