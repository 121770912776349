import { Helmet } from 'react-helmet';
import FeaturesSection from '../components/AIHubPage/FeaturesSection';
import HowItWorks1 from '../components/SocialMediaPage/HowItWorks1';
import Testmonials from '../components/Testimonials';
import TrustedCustomers from '../components/TrustedCustomers';
import handleBookaDemoClick from '../constants/bookAdemoClick';
import pagesJsonLD from '../constants/pagesJsonLD';
import './RelevaAIHub.css';

const SocialMedia = () => {
  // Function to handle clicks on the card wrappers
  const handleCardWrapperClick = (e, slider) => {
    e.preventDefault(); // Prevent default behavior of the click event if needed
    showElements(`.slider${slider}`, 'image');
    showElements(`.text${slider}`, 'text');
    toggleActive(`.interactiveHeading${slider}`);
  };
  // Function to toggle "active" class on the clicked element and remove it from others
  const toggleActive = (className) => {
    // Get all elements with the specified class name
    const elements = document.querySelectorAll(className);

    // Remove "active" class from all elements
    document.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });

    // Add "active" class to the clicked element
    elements.forEach((element) => {
      element.classList.add('active');
    });
  };
  // Function to show all elements with the specified class name
  const showElements = (className, type) => {
    // Get all elements with the specified class name
    const elementsToShow = document.querySelectorAll(className);

    // Convert NodeList to an array using spread operator
    const elementsArray = [...elementsToShow];

    // Show all elements
    elementsArray.forEach((element) => {
      element.style.display = 'block';
    });

    // Hide all other images
    if (type === 'image') {
      const allImages = document.querySelectorAll('.interactiveSlider');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
    if (type === 'text') {
      const allImages = document.querySelectorAll('.interactiveText');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
  };

  return (
    <div className="releva-ai-hub omni-personalization socialMediaPage">
      <Helmet>
        <title>Releva AI | Social Media</title>
        <meta
          name="description"
          content="Elevate your social media strategy with Releva AI. Leverage advanced AI tools to optimize your campaigns, increase engagement, and drive growth across all platforms. Discover how our solutions can transform your social media presence."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.socialMediaPage)}</script>
      </Helmet>
      <section className="else-statement">
        <div className="while-loop">
          <div className="relevas-ai-hub">
            Smart Ads, Smarter Engagement:
            <br />
            Craft Ads That Speak Directly to Your Audience
          </div>
          <div className="switch-case-ai">
            <div className="create-document-collaborate">
              Plan, Execute, and Optimize your social media campaigns across
              <br />
              Facebook, Instagram, Google*, and TikTok*
            </div>
          </div>
          <div className="break-point">
            <div className="button1">
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                onClick={(e) =>
                  handleBookaDemoClick(
                    e,
                    'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                  )
                }
              >
                Try Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="call-stack">
        <img className="image-118-icon" loading="lazy" alt="" src="/SocialMediaPage/HeroImage.webp" />
      </section>
      <section className="global-scope">
        <div className="block-statement">
          <div className="achievements-recognition">{`Achievements & Recognition `}</div>
          <div className="achievements-recognition-parent">
            <div className="arithmetic-operator">
              <div className="relevas-global-impact">Releva's global impact on eCommerce from around the world.</div>
            </div>
          </div>
          <div className="frame-container">
            <div className="frame-wrapper"></div>
            <div className="awardsHolder">
              <img className="image-118-icon" loading="lazy" alt="" src="/SocialMediaPage/Achievements1.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/SocialMediaPage/Achievements2.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/SocialMediaPage/Achievements3.webp" />
            </div>
          </div>
          <div className="array-operator">
            <div className="object-operator">
              <div className="function-define">
                <div className="why-your-ecommerce">Why Your eCommerce Business Needs Releva Social Media?</div>
              </div>
              {/* make it interactive */}
              <div className="variable-declare">
                <div className="variable-update">
                  <div className="statement-list">
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading1 active"
                      onClick={(e) => handleCardWrapperClick(e, 1)}
                    >
                      <b className="struggling-to-persuade">Is inconsistent branding reducing your marketing impact?</b>
                      <div className="convey-in-the interactiveText text1">
                        Achieve a unified brand voice across all social media channels with Releva's Social Media,
                        enhancing brand recognition and building customer trust by delivering cohesive and compelling
                        content tailored to each platform.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading2"
                      onClick={(e) => handleCardWrapperClick(e, 2)}
                    >
                      <b className="struggling-to-persuade">
                        Are high retargeting costs draining your marketing budget?
                      </b>
                      <div className="convey-in-the interactiveText text2">
                        Cut down on retargeting expenses by over 50% and boost conversion rates by 30% with Releva’s
                        efficient retargeting tools that optimize your spending and maximize ROI through precise and
                        strategic ad placements.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading3"
                      onClick={(e) => handleCardWrapperClick(e, 3)}
                    >
                      <b className="struggling-to-persuade">Need to convert more with your social media ads?</b>
                      <div className="convey-in-the interactiveText text3">
                        Ensure each ad you run has maximum impact. Utilize Releva's advanced analytics to gain deep
                        insights into the performance of your social media campaigns, enabling data-driven decisions
                        that attract the right audience with the most accurate messages and improve overall campaign
                        results.
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="image-118-icon interactiveSlider  slider1"
                  loading="lazy"
                  alt=""
                  src="/AIHub/WhySlider1.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider2"
                  loading="lazy"
                  alt=""
                  src="/AIHub/WhySlider2.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider3"
                  loading="lazy"
                  alt=""
                  src="/AIHub/WhySlider3.webp"
                />
              </div>
              {/* make it interactive */}
            </div>
          </div>
        </div>
      </section>
      <Testmonials />

      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    onClick={(e) =>
                      handleBookaDemoClick(
                        e,
                        'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                      )
                    }
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks">
            <div className="title">
              <div className="how-releva-ai">How Releva Social Media Works</div>
              <div className="releva-ai-hub1">Releva Social Media consists of</div>
            </div>
            <div className="sub-menu">
              <div className="menu-items">
                <div className="brand-identity-wrapper">
                  <div className="brand-identity1">
                    <a className="dummyLink" href="#campaigns">
                      Campaigns
                    </a>
                  </div>
                </div>
                <div className="create-content-wrapper">
                  <div className="create-content">
                    <a className="dummyLink" href="#workflows">
                      Workflows
                    </a>
                  </div>
                </div>
                <div className="generated-content-wrapper">
                  <div className="generated-content">
                    <a className="dummyLink" href="#facebookAndInstagramAds">
                      Facebook & Instagram Ads
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks1-wrapper">
            <div className="howitworks1" id="campaigns">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">01</div>
                  </div>
                  <div className="extract-your-brand">Tailoring Dynamic Messages</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">for Every Customer</div>
                  </div>
                  <div className="simply-provide-your">
                    Releva's Campaigns tool revolutionizes how eCommerce stores engage with their audiences, enabling
                    marketers to craft tailored campaigns that speak directly to varied customer segments. This
                    personalized approach ensures that marketing messages are not only seen but felt, deeply resonating
                    with the audience to drive significant engagement and conversion. The seamless integration across
                    social media platforms amplifies the impact, creating a cohesive campaign that elevates the shopping
                    experience and builds lasting customer loyalty.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/SocialMediaPage/omni1.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="workflows">
            <HowItWorks1 />
          </div>

          <div className="howitworks1-wrapper" id="facebookAndInstagramAds">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">03</div>
                  </div>
                  <div className="extract-your-brand">Smart Personalized Facebook Ads:</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">Amplify Your Results, Spend less on Ads</div>
                  </div>
                  <div className="simply-provide-your">
                    Releva's integration with Facebook transforms social media advertising by leveraging deep user
                    insights to deliver highly personalized ad content. This strategy not only captivates the intended
                    audience but also significantly boosts the efficiency of ad spend, ensuring that every dollar
                    contributes directly to increased conversions. As a result, eCommerce brands can see a significant
                    improvement in ROI, with ads that not only reach but resonate with potential buyers.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/SocialMediaPage/omni3.webp" />
            </div>
          </div>
        </div>
      </section>
      <TrustedCustomers />
      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    onClick={(e) =>
                      handleBookaDemoClick(
                        e,
                        'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                      )
                    }
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FeaturesSection title="Releva goes beyond just social media marketing." />
      <Testmonials />
      <div className="separator"></div>
    </div>
  );
};
export default SocialMedia;
