import './FrameComponent21.css';

const FrameComponent21 = () => {
  return (
    <div className="i-09-parent">
      <div className="i-09">
        <img
          loading="lazy"
          src="/DataDrivenMarketer/SpendLess.webp"
          alt="HeroImage"
          title="HeroImage"
          className="HomePageHeroImage"
        />
      </div>
      <div className="data-aggregator-container">
        <div className="data-aggregator4">
          <h1 className="spend-50-less">Spend 50% Less, Achieve More</h1>
          <div className="releva-saves-you">
            Releva saves you +50% on Facebook and Google ads retargeting, optimizing your ad spend effectively.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent21;
