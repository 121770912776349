import { Helmet } from 'react-helmet';
import FeaturesSection from '../components/AIHubPage/FeaturesSection';
import HowItWorks1 from '../components/MailAndPushPage/HowItWorks1';
import HowItWorks2 from '../components/MailAndPushPage/HowItWorks2';
import HowItWorks3 from '../components/MailAndPushPage/HowItWorks3';
import HowItWorks4 from '../components/MailAndPushPage/HowItWorks4';
import Testmonials from '../components/Testimonials';
import TrustedCustomers from '../components/TrustedCustomers';
import handleBookaDemoClick from '../constants/bookAdemoClick';
import pagesJsonLD from '../constants/pagesJsonLD';
import './RelevaAIHub.css';

const MailAndPush = () => {
  // Function to handle clicks on the card wrappers
  const handleCardWrapperClick = (e, slider) => {
    e.preventDefault(); // Prevent default behavior of the click event if needed
    showElements(`.slider${slider}`, 'image');
    showElements(`.text${slider}`, 'text');
    toggleActive(`.interactiveHeading${slider}`);
  };
  // Function to toggle "active" class on the clicked element and remove it from others
  const toggleActive = (className) => {
    // Get all elements with the specified class name
    const elements = document.querySelectorAll(className);

    // Remove "active" class from all elements
    document.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });

    // Add "active" class to the clicked element
    elements.forEach((element) => {
      element.classList.add('active');
    });
  };
  // Function to show all elements with the specified class name
  const showElements = (className, type) => {
    // Get all elements with the specified class name
    const elementsToShow = document.querySelectorAll(className);

    // Convert NodeList to an array using spread operator
    const elementsArray = [...elementsToShow];

    // Show all elements
    elementsArray.forEach((element) => {
      element.style.display = 'block';
    });

    // Hide all other images
    if (type === 'image') {
      const allImages = document.querySelectorAll('.interactiveSlider');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
    if (type === 'text') {
      const allImages = document.querySelectorAll('.interactiveText');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
  };

  return (
    <div className="releva-ai-hub omni-personalization mail-and-push">
      <Helmet>
        <title>Releva AI | Mail & Push</title>
        <meta
          name="description"
          content="Enhance your marketing with Releva AI's Mail and Push solutions. Automate personalized email and push notifications to boost engagement and conversions. Discover how our AI technology can transform your outreach strategies."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.mailAndPushPage)}</script>
      </Helmet>
      <section className="else-statement">
        <div className="while-loop">
          <div className="relevas-ai-hub">
            Target, Engage, Convert:
            <br />
            Releva’s Mail & Push Delivers
          </div>
          <div className="switch-case-ai">
            <div className="create-document-collaborate">
              Design, execute, and optimize personalized, consistent, and compelling emails, banners, pop-ups and push
              notifications that resonate directly with your target audience.
            </div>
          </div>
          <div className="break-point">
            <div className="button1">
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                onClick={(e) =>
                  handleBookaDemoClick(
                    e,
                    'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                  )
                }
              >
                Try Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="call-stack">
        <img className="image-118-icon" loading="lazy" alt="" src="/MailAndPushPage/HeroImage.webp" />
      </section>
      <section className="global-scope">
        <div className="block-statement">
          <div className="achievements-recognition">{`Achievements & Recognition `}</div>
          <div className="achievements-recognition-parent">
            <div className="arithmetic-operator">
              <div className="relevas-global-impact">Releva's global impact on eCommerce from around the world.</div>
            </div>
          </div>
          <div className="frame-container">
            <div className="frame-wrapper"></div>
            <div className="awardsHolder">
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements1.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements2.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements3.webp" />
            </div>
          </div>
          <div className="array-operator">
            <div className="object-operator">
              <div className="function-define">
                <div className="why-your-ecommerce">Why Your eCommerce Business Needs Releva Mail & Push?</div>
              </div>
              {/* make it interactive */}
              <div className="variable-declare">
                <div className="variable-update">
                  <div className="statement-list">
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading1 active"
                      onClick={(e) => handleCardWrapperClick(e, 1)}
                    >
                      <b className="struggling-to-persuade">Are lack of personalization limiting your sales growth?</b>
                      <div className="convey-in-the interactiveText text1">
                        Leverage the integrated Customer Data Platform (CDP) within the Mail & Push to gain deep
                        insights into customer behaviors, enhancing targeting precision. Use these comprehensive
                        profiles to make data-driven marketing decisions and craft personalized campaigns that boost
                        engagement and increase your sales.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading2"
                      onClick={(e) => handleCardWrapperClick(e, 2)}
                    >
                      <b className="struggling-to-persuade">Need to optimize your marketing campaigns?</b>
                      <div className="convey-in-the interactiveText text2">
                        Design, execute, and optimize compelling banners, pop-ups, and notification workflows that
                        directly appeal to your target audience. Boost your performance and elevate conversion rates
                        with AI-driven recommenders that streamlines your efforts.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading3"
                      onClick={(e) => handleCardWrapperClick(e, 3)}
                    >
                      <b className="struggling-to-persuade">Facing challenges with customer retention?</b>
                      <div className="convey-in-the interactiveText text3">
                        Utilize dynamic coupons, surveys, and targeted campaigns from the Mail & Push to keep your
                        customers returning. Personalized interactions increase loyalty and repeat purchases by making
                        every communication feel tailor-made.
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="image-118-icon interactiveSlider  slider1"
                  loading="lazy"
                  alt=""
                  src="/MailAndPushPage/WhySlider1.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider2"
                  loading="lazy"
                  alt=""
                  src="/MailAndPushPage/WhySlider2.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider3"
                  loading="lazy"
                  alt=""
                  src="/MailAndPushPage/WhySlider3.webp"
                />
              </div>
              {/* make it interactive */}
            </div>
          </div>
        </div>
      </section>
      <Testmonials />

      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    onClick={(e) =>
                      handleBookaDemoClick(
                        e,
                        'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                      )
                    }
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks">
            <div className="title">
              <div className="how-releva-ai">How Releva Mail & Push Works</div>
              <div className="releva-ai-hub1">Releva Mail & Push consists of</div>
            </div>
            <div className="sub-menu">
              <div className="menu-items">
                <div className="brand-identity-wrapper">
                  <div className="brand-identity1">
                    <a className="dummyLink" href="#bannersAndPopUps">
                      Banners & pop-ups
                    </a>
                  </div>
                </div>
                <div className="create-content-wrapper">
                  <div className="create-content">
                    <a className="dummyLink" href="#campaignsAndWorkflows">
                      Campaigns & workflow
                    </a>
                  </div>
                </div>
                <div className="generated-content-wrapper">
                  <div className="generated-content">
                    <a className="dummyLink" href="#customerDataPlatform">
                      Customer Data Platform
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#productPlatform">
                      Product Platform
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#productTrends">
                      Product Trends
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#dynamicCoupons">
                      Dynamic coupons
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#surveys">
                      Surveys
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#analytics">
                      Analytics
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks1-wrapper" id="bannersAndPopUps">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">01</div>
                  </div>
                  <div className="extract-your-brand">Enhance Store Engagement</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">with Smart Banners and Pop-ups</div>
                  </div>
                  <div className="simply-provide-your">
                    Engage visitors more effectively with Releva’s banners and pop-ups, which are crafted to capture
                    attention at the right moment based on user behaviour analytics. Reduce bounce rates by providing
                    visually appealing, personalized offers that encourage deeper interaction with your store.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MailAndPushPage/omni1.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="campaignsAndWorkflows">
            <HowItWorks1 />
          </div>

          <div className="howitworks1-wrapper" id="customerDataPlatform">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">03</div>
                  </div>
                  <div className="extract-your-brand">Turn Customer Insights</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">into Competitive Advantage</div>
                  </div>
                  <div className="simply-provide-your">
                    Releva’s Customer Data Platform centralizes customer interactions from multiple channels, providing
                    a unified view that helps you understand and anticipate customer needs more effectively. Based on
                    this valuable data you can provide tailored-made marketing strategies, which enhance customer
                    satisfaction, boost number of completed purchases and establish long-term relationships with your
                    customers.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MailAndPushPage/omni3.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="productPlatform">
            <HowItWorks2 />
          </div>

          <div className="howitworks1-wrapper" id="productTrends">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">05</div>
                  </div>
                  <div className="extract-your-brand">Future Forecasts: Use Trends</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">to Craft Marketing Campaigns</div>
                  </div>
                  <div className="simply-provide-your">
                    Stay ahead of market shifts with Releva’s Product Trends analysis, which leverages data about the
                    performance of your products to forecast emerging trends. Adapt your product strategy quickly by
                    aligning with consumer preferences. This alignment helps capture market interest early and gain a
                    competitive advantage.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MailAndPushPage/omni5.webp" />
            </div>
          </div>
          <div className="howitworks3-ai-wrapper" id="dynamicCoupons">
            <HowItWorks3 />
          </div>

          <div className="howitworks1-wrapper" id="surveys">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">07</div>
                  </div>
                  <div className="extract-your-brand">Build Better Marketing Strategies</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">Based on User Feedback</div>
                  </div>
                  <div className="simply-provide-your">
                    Unlock invaluable insights with Releva's targeted surveys, seamlessly embedded into your customer
                    interactions. Discover exactly what draws your visitors in and what might be turning them away. This
                    real-time feedback empowers you to refine your product offerings and elevate customer service.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/MailAndPushPage/omni7.webp" />
            </div>
          </div>
          <div className="howitworks3-ai-wrapper" id="analytics">
            <HowItWorks4 />
          </div>
        </div>
      </section>
      <TrustedCustomers />
      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    onClick={(e) =>
                      handleBookaDemoClick(
                        e,
                        'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                      )
                    }
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FeaturesSection title="Releva goes beyond just email marketing." />
      <Testmonials />
      <div className="separator"></div>
    </div>
  );
};

export default MailAndPush;
