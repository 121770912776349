import './FrameComponent29.css';
import Vuesaxlinearsend2Icon from './Vuesaxlinearsend2Icon';

const FrameComponent29 = () => {
  return (
    <div className="block-2-parent">
      <div className="block-2">
        <div className="data-collector">
          <div className="customers-parent">
            <h1 className="customers1">Customers</h1>
            <div className="as-an-online">
              As an online market place tailored to a diverse user base, TrendO.bg offers a wide selection of products
              and brands, ensuring that every shopper finds their perfect fit.
            </div>
          </div>
        </div>
        <div className="list-item1">
          <div className="ebag-trendo-adres">
            <div className="segments-parent1">
              <div className="segments3">Segments</div>
              <div className="footer9">
                <div className="c-t-a-background5">
                  <div className="ready-to-act-parent">
                    <div className="ready-to-act" />
                    <div className="company-resources2" />
                  </div>
                  <div className="rectangle-parent9">
                    <div className="frame-child58" />
                    <div className="frame-child59" />
                  </div>
                  <div className="rectangle-parent10">
                    <div className="frame-child60" />
                    <div className="frame-child61" />
                  </div>
                </div>
              </div>
            </div>
            <div className="why-releva8" />
            <div className="product-datacenter-parent">
              <div className="product-datacenter">
                <div className="profile4">Profile</div>
              </div>
              <div className="frame-parent108">
                <div className="image-84-parent">
                  <div className="image-84" />
                  <img className="mask-group-3-1" alt="" src="/mask-group-3-1@2x.webp" />
                </div>
                <div className="quote">
                  <div className="anthony-smith3">Anthony Smith</div>
                </div>
                <div className="paragraph">
                  <div className="ecommerce-brands-container" />
                </div>
                <div className="paragraph1">
                  <div className="paragraph-child" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="column6">
                <div className="customer-data-segmentation1">
                  <div className="communication-center5" />
                  <img className="mask-group-4-1" loading="lazy" alt="" src="/mask-group-4-1@2x.webp" />
                </div>
                <div className="jessica-ford-container">
                  <div className="jessica-ford2">Jessica Ford</div>
                </div>
              </div>
              <div className="triangle1">
                <div className="trusted-by-ecommerce-industrie">
                  <div className="from-diverse-industries-and-gl" />
                  <div className="from-diverse-industries-and-gl1" />
                </div>
              </div>
            </div>
          </div>
          <div className="oval">
            <img className="artwork-icon1" alt="" src="/vector-21.svg" />
            <img className="vector-icon28" loading="lazy" alt="" src="/vector-3.svg" />
            <img className="oval-child" loading="lazy" alt="" src="/frame-146.svg" />
            <div className="ebag-adres-block" />
            <img
              className="tobias-tullius-fg15ldqpwrs-uns-icon"
              loading="lazy"
              alt=""
              src="/tobiastulliusfg15ldqpwrsunsplashremovebgpreview2-1@2x.webp"
            />
          </div>
        </div>
      </div>
      <div className="block-3-parent">
        <div className="block-3">
          <div className="grid1">
            <div className="key-objectives-parent">
              <h1 className="key-objectives">Key Objectives </h1>
              <div className="trendo-aims-to">
                TrendO aims to provide personalized communication with its customers. They want to engage with their
                clients, providing personalized offers that fit exactly their needs.
              </div>
            </div>
          </div>
          <div className="elements">
            <div className="opacity-parent">
              <div className="opacity">
                <img className="vuesaxlinearuser-octagon-icon" alt="" src="/vuesaxlinearuseroctagon.svg" />
              </div>
              <div className="website-parent">
                <h3 className="website1">Website</h3>
                <h3 className="product-personalization">
                  <p className="product5">{`Product `}</p>
                  <p className="personalization">Personalization</p>
                </h3>
              </div>
            </div>
            <div className="image-stack">
              <div className="dropdown-set-parent">
                <div className="dropdown-set">
                  <img className="vector-icon29" alt="" src="/vector-9.svg" />
                  <div className="link-list-parent">
                    <div className="link-list">
                      <Vuesaxlinearsend2Icon />
                    </div>
                    <h3 className="engagement">Engagement</h3>
                    <h3 className="omnichannel-marketing1">
                      <p className="omnichannel">{`Omnichannel `}</p>
                      <p className="marketing1">{`Marketing `}</p>
                    </h3>
                  </div>
                </div>
                <div className="frame-parent109">
                  <div className="vuesaxlinearstory-wrapper">
                    <img className="vuesaxlinearstory-icon" loading="lazy" alt="" src="/vuesaxlinearstory.svg" />
                  </div>
                  <h3 className="marketing2">Marketing</h3>
                  <div className="square-shape-set">
                    <h3 className="automation5">Automation</h3>
                  </div>
                </div>
              </div>
              <div className="global-locations-list">
                <div className="global-locations-list-inner">
                  <img className="frame-child62" loading="lazy" alt="" src="/group-25.svg" />
                </div>
                <div className="predict-group">
                  <div className="predict1">Predict</div>
                  <div className="users-next-purchase-wrapper">
                    <div className="users-next-purchase-container">
                      <p className="users-next">Users’ Next</p>
                      <p className="purchase1">Purchase</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="triangle-shape-set">
          <div className="block-4">
            <div className="block-4-inner">
              <div className="trendo-implemented-parent">
                <h1 className="trendo-implemented">TrendO implemented</h1>
                <div className="automated-email-newsletters-container">
                  <ul className="automated-email-newsletters-ai">
                    <li className="automated-email-newsletters">Automated email newsletters</li>
                    <li className="ai-marketing-automation">AI marketing automation</li>
                    <li className="omnichannel-communication">Omnichannel communication </li>
                    <li>Personalization and omnichannel communication via Facebook ads</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="heart-shape-set">
              <div className="heart-shape-set-inner">
                <div className="ebag-trendo-adres-parent">
                  <div className="ebag-trendo-adres1">
                    <div className="ebag-trendo-adres-inner">
                      <div className="hi-jane-parent">
                        <h2 className="hi-jane">Hi Jane,</h2>
                        <div className="youve-left-two3">
                          You’ve left two Item on your basket are you ready to purchase?
                        </div>
                      </div>
                    </div>
                    <div className="frame-parent110">
                      <div className="frame-parent111">
                        <div className="frame-wrapper44">
                          <div className="image-83-group">
                            <img className="image-83-icon3" loading="lazy" alt="" src="/image-832@2x.webp" />
                            <div className="frame-wrapper45">
                              <div className="rectangle-parent11">
                                <div className="frame-child63" />
                                <div className="ecommerce-owners-market-specia" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-child64" />
                      </div>
                      <div className="card-stack">
                        <div className="grid-layout">
                          <div className="ebag-trendo-adres2">
                            <img className="image-13-icon3" loading="lazy" alt="" src="/image-131@2x.webp" />
                          </div>
                          <div className="stack-container">
                            <div className="tabbed-container">
                              <div className="try-releva" />
                              <div className="button-name37" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="logic-gate15">
                      <div className="get-demo11">
                        <div className="checkout3">Checkout</div>
                      </div>
                    </div>
                    <img className="icon7" loading="lazy" alt="" src="/group-1.svg" />
                  </div>
                  <div className="ecommerceowners">
                    <div className="marketingspecialist">
                      <div className="image-841" />
                    </div>
                    <img
                      className="tamara-bellis-joks3xwev50-unsp-icon"
                      loading="lazy"
                      alt=""
                      src="/tamarabellisjoks3xwev50unsplashremovebgpreview-1@2x.webp"
                    />
                  </div>
                </div>
              </div>
              <img className="referralprograms-icon" loading="lazy" alt="" src="/vector-3.svg" />
            </div>
          </div>
          <div className="block-5">
            <div className="background">
              <div className="achieved-results-wrapper">
                <h1 className="achieved-results">Achieved results</h1>
              </div>
              <div className="error-handler9">
                <div className="frame-parent112">
                  <div className="marketeers-parent">
                    <div className="marketeers">60.3</div>
                    <div className="open-rate-from-automations-wrapper">
                      <div className="open-rate-from">Open rate from automations</div>
                    </div>
                  </div>
                  <div className="ready-to-see-action1">
                    <h1 className="ebag-trendo-address">%</h1>
                  </div>
                </div>
                <div className="frame-parent113">
                  <div className="parent16">
                    <div className="div19">68.2</div>
                    <div className="click-rate-from1">Click rate from automations</div>
                  </div>
                  <div className="ready-to-see-action2">
                    <h1 className="h14">%</h1>
                  </div>
                </div>
                <div className="frame-parent114">
                  <div className="frame-wrapper46">
                    <div className="parent17">
                      <div className="div20">3.4</div>
                      <div className="header-logo-container1">
                        <h1 className="h15">%</h1>
                      </div>
                    </div>
                  </div>
                  <div className="conversion-rate-from-container">
                    <p className="conversion-rate">Conversion rate </p>
                    <p className="from-automations">from automations</p>
                  </div>
                </div>
                <div className="frame-parent115">
                  <div className="parent18">
                    <div className="div21">43.8</div>
                    <div className="open-rate-from-campaigns-wrapper">
                      <div className="open-rate-from1">Open rate from campaigns</div>
                    </div>
                  </div>
                  <div className="ready-to-see-action3">
                    <h1 className="h16">%</h1>
                  </div>
                </div>
                <div className="frame-parent116">
                  <div className="parent19">
                    <div className="div22">49.3</div>
                    <div className="click-rate-from-automations-wrapper">
                      <div className="click-rate-from2">Click rate from automations</div>
                    </div>
                  </div>
                  <div className="ready-to-see-action4">
                    <h1 className="h17">%</h1>
                  </div>
                </div>
                <div className="frame-parent117">
                  <div className="frame-wrapper47">
                    <div className="parent20">
                      <div className="div23">2</div>
                      <div className="ready-to-see-action5">
                        <h1 className="h18">%</h1>
                      </div>
                    </div>
                  </div>
                  <div className="conversion-rate-from">Conversion rate from campaigns</div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-6">
            <div className="block-6-inner">
              <div className="key-metrics-parent">
                <h1 className="key-metrics">Key metrics</h1>
                <div className="average-page-views-container">
                  <p className="average-page-views">Average page views :  1 203 367</p>
                  <p className="average-active-users">Average active users : 129 669</p>
                  <p className="emails-sent-1">Emails sent :  1 522 499</p>
                </div>
              </div>
            </div>
            <div className="wrapper-photo">
              <img className="photo-icon" loading="lazy" alt="" src="/photo.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent29;
