import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './FrameComponent16.css';

const FrameComponent16 = () => {
  return (
    <section className="product-page-child">
      <div className="i-02-parent">
        <div className="i-02">
          <img
            loading="lazy"
            src="/ProductPage/ProductDiscovery.webp"
            alt="WebPersonalization"
            title="WebPersonalization"
            className="HomePageHeroImage"
          />
        </div>
        <div className="frame-wrapper25">
          <div className="product-discovery-parent">
            <h1 className="product-discovery">Product Discovery</h1>
            <div className="with-every-click">
              With every click, Releva learns more about what the visitor truly desires. It's like having a personal
              shopping assistant, guiding them towards the perfect purchase.
            </div>
            <button className="button16">
              <a
                rel="noopener noreferrer"
                className="button-name14"
                target="_blank"
                onClick={(e) =>
                  handleBookaDemoClick(
                    e,
                    'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                  )
                }
              >
                Book a demo
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent16;
