import './HowItWorks1.css';

const HowItWorks1 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img
          className="screenshot-2024-04-09-at-2028-icon"
          loading="lazy"
          alt=""
          src="/Omnipersonalization/omni2.webp"
        />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">02</div>
          <div className="master-your-generated">Automate Your Campaigns </div>
          <div className="edit-your-documents">for Maximum Impact and Efficiency</div>
          <div className="customize-your-generated">
            Releva’s campaign and workflow automation tools optimise your marketing process, ensuring that your
            campaigns are consistently delivered on time and targeted to the right audience segments. Save time and
            resources while maintaining high engagement levels, making each campaign more effective and easier to
            manage.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks1;
