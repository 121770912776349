import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Testimonial from './Testimonial';
import './Testimonials.css';

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const testimonials = [
    {
      title: '',
      content:
        'Еxtremely adequate partner with an exceptional understanding of our business needs, providing timely, and spot-on solutions.',
      imageUrl: '/Testimonials/Address.jpeg',
      name: 'Roza Divleva',
      designation: 'Marketing manager',
      company: 'Address Real Estate',
    },
    {
      title: '',
      content:
        'Releva offers a unique experience for each customer. Thanks to Releva our business has become a personalized mall for everyone.',
      imageUrl: '/unico.jpg',
      name: 'Ilian Radev',
      designation: 'eCommerce owner',
      company: 'Unico.bg',
    },
    {
      title: '',
      content:
        'Releva helped our site a lot. It gave us a lot of flexibility and provided our customers with a different and much appreciated experience on our website. Most of the products each customer sees are based on their own interest and individual needs. And of course, Releva helped us increase our profits. It reflects our marketing strategy by allowing us to use automations and personalizations.',
      imageUrl: '/Testimonials/TrendO.jpeg',
      name: 'Datsko Datsev',
      designation: 'CTO',
      company: 'trendo',
    },
    {
      title: '',
      content:
        'I hope Releva becomes a big company. We believe it can bring a lot of value to other companies like Mercari.',
      imageUrl: '/Testimonials/Mercari.jpeg',
      name: 'Andreas Schneider',
      designation: 'eCommerce owner',
      company: 'Mercari',
    },
    {
      title: '',
      content:
        'Thanks to Releva we manage to offer our customers so much more personalized and relevant content. We manage to engage them, encourage them to make a purchase and increase the average shopping cart total.',
      imageUrl: '/Testimonials/eBag.jpeg',
      name: 'Eleonora Ivanova',
      designation: 'Marketing automation expert',
      company: 'eBag',
    },
    {
      title: '',
      content:
        'Thanks to Releva we manage to offer our customers so much more personalized and relevant content. We manage to engage them, encourage them to make a purchase and increase the average shopping cart total.',
      imageUrl: '/Testimonials/Moemax.jpeg',
      name: 'Elitza Dushkova',
      designation: 'Online marketing manager',
      company: 'Aiko and Moemax',
    },
    {
      title: '',
      content:
        'Releva is exactly what Kinderland needed in order to strengthen its greatest competitive advantage - the personalized approach towards our customers. The personalizations that Releva provides are at a level that no other company in Bulgaria has been able to achieve. This gives us the opportunity to be closer to the end user by offering an individual approach.',
      imageUrl: 'kinderland.jpg',
      name: 'Elena Atanasova',
      designation: 'Brand Manager',
      company: 'Kinderland',
    },
    {
      title: '',
      content:
        'The service quality is excellent; the system operates smoothly without any disruptions or errors. The continual development of existing applications, along with prompt and high-quality support, stands out as one of the key competitive advantages. The ability to consult quickly and effectively is very important in the online business realm.',
      imageUrl: '/Testimonials/Calinachi.jpeg',
      name: 'Alexandra Tsvetanova',
      designation: 'Sales Manager',
      company: 'Calinachi',
    },
    {
      title: '',
      content:
        'Releva contains a great set of tools that a company can utilize in order to maximize customer retention and increase engagement as well as average cart values and ultimately profits. From popups, email and on site personalized recommendations, to automation flows and SMS marketing, it sets itself apart from the competition. The team is great, willing and able to guide you through the whole process of setup seamlessly. I highly recommend it as an all in one automation & recommendation platform.',
      imageUrl: '/Testimonials/Sexopolis.jpeg',
      name: 'Bill Kougioumtzidis',
      designation: 'Managing Director',
      company: 'Sexopolis',
    },
    {
      title: '',
      content: `With the implementation of Releva as a method for product recommendations on our online store, www.carducci.bg, we have experienced an increase in purchases.In addition to our traditional marketing channels, we have started using emails to boost sales. With minimal resources and effort, the platform can target a warm audience that is constantly reminded of the brand.The integration of product blocks has allowed us to offer different products that were not selling at the moment. Releva successfully displays a large portion of the website's portfolio.By tailoring recommendations to the interests of users, we have increased the chances that they will place an order.Releva serves as an ideal complement to other advertising channels that drive traffic to the website. This software functions as our virtual sales consultant, operating with a significantly lower salary than physical salespersons.`,
      imageUrl: '/Testimonials/Carducci.png',
      name: 'Petya Dimitrova',
      designation: 'Representative',
      company: 'Carducci',
    },
    {
      title: '',
      content: `With Releva, you can offer personalized recommendations to your customer audience using artificial intelligence. Its ability to deliver accurate and timely messages truly enhances your connection with the audience and significantly improves the results of marketing efforts. I highly recommend Releva to anyone looking to establish a more personal and impressive market presence.All of this makes Releva not just a tool but an indispensable partner in enhancing your marketing success and building stronger connections with your customer base.`,
      imageUrl: '/Testimonials/VisVitalis.jpeg',
      name: 'Petur Sarakov',
      designation: 'Brand Manager',
      company: 'Vis Vitalis',
    },
    // Add more testimonials as needed
  ];
  return (
    <div className="releva-homepage-child">
      <div className="slider-container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index} {...testimonial} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
