/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import FeatureTable from '../components/PricingPage/FeatureTable';
import PlanDetails from '../components/PricingPage/PlanDetails';
import PlansTable from '../components/PricingPage/PlansTable';
import ShopifyMerchantProButtons1 from '../components/PricingPage/ShopifyMerchantProButtons1';
import aiHubFeatures from '../constants/aiHubFeatures';
import analyticsFeatures from '../constants/analyticsFeatures';
import automationFeatures from '../constants/automationFeatures';
import dataPlatformFeatures from '../constants/dataPlatformFeatures';
import marketingChannelsFeatures from '../constants/marketingChannelsFeatures';
import plansData from '../constants/plansData';
import resourcesFeatures from '../constants/resourcesFeatures';
import settingsFeatures from '../constants/settingsFeatures';
import webPersonalizationFeatures from '../constants/webPersonalizationFeatures';
import './PricingPage.css';

const PricingShopifyPage = () => {
  return (
    <div className="pricing-page">
      <Helmet>
        <title>Releva AI | Pricing Page</title>
        <meta
          name="description"
          content="Seamlessly integrate with Releva AI's to optimize your e-commerce operations. Discover how our advanced integration solutions enhance your online store's performance, streamline processes, and drive growth."
        />
      </Helmet>
      <main className="background">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Shopify_Logo.png/640px-Shopify_Logo.png"
          alt=""
        />
        <HomePageHeroTitle
          heroTitle="Trust Your Decisions, Drive Success"
          heroDescription="Maximize the Value of Your Business and Grow Your eCommerce."
        />
        {/* <ChooseYourPlan1 /> */}
        <div className="releva-pricing-plans2">
          <h1 className="releva-pricing-plans3">Releva Pricing Plans</h1>
          <div className="startup-plan-parent">
            {plansData.slice(1, 3).map((plan, index) => (
              <PlanDetails key={index} {...plan} />
            ))}
          </div>
        </div>
        <ShopifyMerchantProButtons1 />
        <form className="compare-plans2">
          <PlansTable plans={[{ name: 'Standard' }, { name: 'Growth' }]} />

          <FeatureTable growth={true} data={dataPlatformFeatures} title="Data Platform" />
          <FeatureTable
            growth={true}
            data={marketingChannelsFeatures}
            title="Marketing channels"
            disclaimer="*Available for Standard additional costs apply."
          />
          <FeatureTable growth={true} data={webPersonalizationFeatures} title="Web personalization" />
          <FeatureTable growth={true} data={automationFeatures} title="Automation" />
          <FeatureTable growth={true} data={analyticsFeatures} title="Analytics" />
          <FeatureTable growth={true} data={resourcesFeatures} title="Resources" />
          <FeatureTable growth={true} data={settingsFeatures} title="Settings" />
          <FeatureTable
            growth={true}
            data={aiHubFeatures}
            title="AI Hub"
            disclaimer="*Available for Standard additional costs apply."
          />
        </form>
      </main>
      {/* <div className="cta2">
        <h1 className="try-releva-free3">Try Releva free for 30 days. </h1>
        <div className="book-a-session2">
          Book a session with one of our eCommerce experts and learn how Releva can help your business grow.
        </div>
        <div className="button10">
          <div className="button-name10">Book a demo</div>
        </div>
      </div>
      <FAQ1 />
      <Testimonials4 />
      <Customers />
       */}
    </div>
  );
};

export default PricingShopifyPage;
