const handleBookaDemoClick = (event, url) => {
  event.preventDefault(); // Prevents immediate navigation
  const pageToken = '7dc33b4d-020b-40cf-98bc-7ecbd8368695';

  // Function to safely use Releva SDK after it has loaded
  const handleRelevaSdkLoaded = () => {
    if (window.Releva) {
      window.Releva.push(
        'a380e041-f8e4-49e8-a647-85c365f11a2a',
        {
          page: {
            token: pageToken,
          },
        },
        function (results) {
          console.log(results);
        },
        function (error) {
          console.error(error);
        }
      );
    } else {
      console.error('Releva SDK not available.');
    }
  };

  // Check if Releva SDK is already loaded, if yes, update the token
  if (window.Releva) {
    handleRelevaSdkLoaded();
  }

  window.open(url, '_blank'); // Opens the URL in a new tab
};

export default handleBookaDemoClick;
