import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import FrameComponent12 from '../components/ProductPage/FrameComponent12';
import FrameComponent13 from '../components/ProductPage/FrameComponent13';
import FrameComponent14 from '../components/ProductPage/FrameComponent14';
import FrameComponent16 from '../components/ProductPage/FrameComponent16';
import FrameComponent17 from '../components/ProductPage/FrameComponent17';
import Steps from '../components/ProductPage/Steps';
import pagesJsonLD from '../constants/pagesJsonLD';
import './ProductPage.css';

const ProductPage = () => {
  return (
    <div className="product-page">
      <Helmet>
        <title>Releva AI | Flagship Product</title>
        <meta
          name="description"
          content="Discover Releva AI's advanced marketing automation tools. Personalize customer experiences, boost engagement, and drive sales with our innovative AI solutions. Learn more about our cutting-edge product now!"
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.productPage)}</script>
      </Helmet>
      <HomePageHeroTitle
        heroTitle="Engage, Convert, and Delight: Your eCommerce Success Story"
        heroDescription="With Releva every interaction optimizes product recommendations, fosters customer loyalty, and fuels eCommerce
        success."
      />
      <img
        loading="lazy"
        src="/ProductPage/HeroImage.webp"
        alt="HeroImage"
        title="HeroImage"
        className="HomePageHeroImage"
      />
      <div className="trusted-by-100-ecommerce-bran-wrapper">
        <div className="trusted-by-100">Trusted by 100+ ecommerce brands</div>
      </div>
      <section className="logic-comparators">
        <div className="search-field-with-dropdown">
          <img className="imoteka-icon" loading="lazy" alt="" src="/imoteka.svg" />
          <img className="ebag-icon" loading="lazy" alt="" src="/ebag.svg" />
          <img className="trendo-icon" loading="lazy" alt="" src="/trendo.svg" />
          <img className="adres-icon" loading="lazy" alt="" src="/adres.svg" />
          <img className="mercari-icon" loading="lazy" alt="" src="/mercari.svg" />
        </div>
      </section>
      <FrameComponent17 />
      <FrameComponent16 />
      <FrameComponent14 />
      <FrameComponent13 />
      <Steps />
      <FrameComponent12 />
    </div>
  );
};

export default ProductPage;
