import React from 'react';
import './PlanDetails.css';

const PlanDetails = ({
  mauIncluded,
  mauIncludedLabel,
  emailsIncluded,
  daysFreeTrial,
  baseTariff,
  mauUsageTraffic,
  monthlyActiveUsers,
  title,
  recommended,
  annualMauUsageTraffic,
  showAnnualPrices,
}) => {
  return (
    <div className="startup-plan1">
      <div className="emails-included8">
        <b>{emailsIncluded}</b>
        <span className="emails-included9">​ Emails included</span>
      </div>
      <div className="days-free-trial-container5">
        <b>{daysFreeTrial}</b>
        <span className="days-free-trial5"> days free trial</span>
      </div>
      {/* <b className="base-tariff-799-container1">
        <p className="base-tariff-7991">Base tariff {baseTariff}</p>
        <p className="mau-usage-traffic3">{showAnnualPrices ? annualMauUsageTraffic : mauUsageTraffic}</p>
      </b> */}
      {/* <div className="price-subscription5">Subscription price:</div> */}
      <div className="monthly-active-users-wrapper">
        <div className="monthly-active-users8">{monthlyActiveUsers}</div>
      </div>
      <div className="div56">{mauIncluded}</div>
      <div className="up-to3">{mauIncludedLabel}</div>
      <div className="title13">
        <div className="startup3">
          {title}
          {recommended && <em>*Recommended</em>}
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
