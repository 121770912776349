const partnersListItems = [
  {
    image: 'Partners/GenerationY.webp',
    title: `Generation Y`,
    shortDescription: `Generation Y provides a full range of digital marketing and e-commerce solutions, including web development, SEO, social media management, in Greece and internationally.`,
    url: 'https://www.generation-y.gr',
  },
  {
    image: 'Partners/konvergence.webp',
    title: `Konvergence`,
    shortDescription: `Konvergence offers integrated digital solutions (SaaS) specializing in retail technology, designed to optimize business operations  for retailers in Indonesia`,
    url: 'https://konvergence.co.id',
  },
  {
    image: 'Partners/AfterSalesPro.webp',
    title: `AfterSalesPro`,
    shortDescription: `AfterSalePro offers a complete solution (SaaS) for the creation and management of shipments sent through all known courier companies operating in Greece.`,
    url: 'https://aftersalespro.gr/en',
  },
  {
    image: 'Partners/CreativeDigitalTower.webp',
    title: `Creative Digital Tower`,
    shortDescription: `Creative Digital Tower is a digital agency providing all digital services and comprehensive business solutions in one place.`,
    url: 'https://www.creativedigitaltower.com',
  },
  {
    image: 'Partners/Optimystica.webp',
    title: ` Optimystica`,
    shortDescription: `Optimystica specializes in SEO optimization, and since 2006, the company has ranked over 600 websites for over 10,000 keywords to the #1 position on Google`,
    url: 'http://www.optimystica.com',
  },
  {
    image: 'Partners/Stenik.webp',
    title: `STENIK`,
    shortDescription: `Stenik is a Bulgarian technology company with 19 years of experience and a focus on eCommerce.`,
    url: 'https://www.stenikgroup.com',
  },
  {
    image: 'Partners/Kooperativa20.webp',
    title: `Kooperativa 2.0`,
    shortDescription: `Kooperativa 2.0 is established in 2012 by entrepreneurs with 20+ years of experience in the online industry`,
    url: 'https://kooperativa.ro/en/',
  },
  {
    image: 'Partners/CopyVibes.webp',
    title: `CopyVibes`,
    shortDescription: `📌 Releva has a new partner –  CopyVibes! CopyVibes is a professional content writing/copywriting agency specializing in creating content tha...`,
    url: 'releva-has-new-partner-copyvibes',
    content: `<img width="1024" height="1024" src="https://releva.ai/wp-content/uploads/2023/06/Partners-Copy-Vibes-1-1024x1024.webp" alt="" loading="lazy" srcset="https://releva.ai/wp-content/uploads/2023/06/Partners-Copy-Vibes-1-1024x1024.webp 1024w, https://releva.ai/wp-content/uploads/2023/06/Partners-Copy-Vibes-1-300x300.webp 300w, https://releva.ai/wp-content/uploads/2023/06/Partners-Copy-Vibes-1-150x150.webp 150w, https://releva.ai/wp-content/uploads/2023/06/Partners-Copy-Vibes-1-768x768.webp 768w, https://releva.ai/wp-content/uploads/2023/06/Partners-Copy-Vibes-1.webp 1200w" sizes="(max-width: 1024px) 100vw, 1024px"><p>CopyVibes is a professional content writing/copywriting agency specializing in creating content that satisfies the demands of users and search engines.</p><p>The company offers a huge variety of different text content types - for a blog, for media, for advertising purposes, for SEO, email marketing, social networks, etc.</p><p>CopyVibes offers personalized copywriting solutions designed to meet clients' unique preferences and requirements, ensuring maximum results through collaborative efforts.</p><p>👉 &nbsp;Check them out at&nbsp;<a href="https://copyvibes.eu/en" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://copyvibes.eu/en&amp;source=gmail&amp;ust=1686738590147000&amp;usg=AOvVaw37h75dLgVdqxS9bwuTUz3c" rel="noopener">https://copyvibes.eu/en</a>&nbsp;and learn more about their services.</p>`,
  },
  {
    image: 'Partners/Serpact.webp',
    title: `Serpact`,
    shortDescription: `Serpact is a small Bulgarian SEO agency established in 2016. Serpact is the only Bulgarian SEO agency which has participated in 3 Google events (as a visitor at the first and as a guest speaker at the other two).`,
    url: 'https://serpact.bg/',
  },
  {
    image: 'Partners/JUSTONLINE.webp',
    title: `JUST ONLINE`,
    shortDescription: `JUST ONLINE have been in the online and e-commerce market since 1996.&nbsp; The company provides online marketing, google ad campaigns, banner and rich media campaigns, social media voice building, advertising and monitoring, e-mail campaigns, site evaluation, design and building, mobile commerce, mobile campaigns, e-commerce training.`,
    url: 'https://www.justonline.gr/en/',
  },
  {
    image: 'Partners/eCommerceAssociationinBosniaandHerzegovina.webp',
    title: `eCommerce Association in Bosnia and Herzegovina`,
    shortDescription: `The aim of the eCommerce Association in Bosnia and Herzegovina is to represent the eCommerce community, protect the rights and interests of merchants and consumers, and create and develop a positive business environment in the field of electronic commerce, as well as develop an eCommerce platform for Association members.`,
    url: 'https://e-comm.ba/en/',
  },
  {
    image: 'Partners/7LInternational.webp',
    title: `7L International`,
    shortDescription: `7L International provides fully integrated, end-to-end business solutions specifically tailored to each customer. The company helps businesses around the world to evolve and thrive using technology.`,
    url: 'https://7linternational.com ',
  },
  {
    image: 'Partners/WebExpert.webp',
    title: `WebExpet`,
    shortDescription: `WebExpert specializes in web design and development, offering cost-effective solutions that cater to businesses of all sizes.`,
    url: 'https://www.webexpert.gr/',
  },
  {
    image: 'Partners/GlobalConcept.webp',
    title: `Global Concept`,
    shortDescription: `The company provides a full range of eCommerce services including web development, SEO optimization, advertising, email marketing and graphic design.`,
    url: 'https://globalconcept.gr/en/',
  },
  {
    image: 'Partners/WebBuild.webp',
    title: `WebBuild`,
    shortDescription: `Webbuild Bulgaria Ltd. is focused on website and eCommerce development and CRM &amp; ERP systems.`,
    url: 'https://webbuild.bg/',
  },
  {
    image: 'Partners/Eshoped.webp',
    title: `Eshoped - Web Services`,
    shortDescription: `Eshoped is a Digital Services Agency with strong expertise in designing, developing and marketing eShops.`,
    url: 'https://eshoped.gr/',
  },
  {
    image: 'Partners/iZiMedia.webp',
    title: `iZi Media Group`,
    shortDescription: `iZi Media is a company that helps eCommerce shops develop a successful website and move their business from a local market to a global sphere.`,
    url: 'https://izimedia.gr/',
  },
  {
    image: 'Partners/Medusamarketing.webp',
    title: `Medusa Marketing`,
    shortDescription: `Medusa Marketing is a company that helps eCommerce shops grow their audience, sell more products and develop a successful online presence.`,
    url: 'https://medusamarketing.gr',
  },
  {
    image: 'Partners/SpiralMango.webp',
    title: `Spiral mango`,
    shortDescription: `Spiral mango is a digital marketing agency that is focused on&nbsp; web development, social media management, Google Ads and inbound marketing.`,
    url: 'https://spiralmango.com/',
  },
  {
    image: 'Partners/Bytebee.webp',
    title: `Bytebee`,
    shortDescription: `Bytebee is an eCommerce accelerator that provides a tailor-made digital transformation guide and services for online businesses.`,
    url: 'https://bytebee.gr/',
  },
  {
    image: 'Partners/WeNet.webp',
    title: `Wenet`,
    shortDescription: `Wenet is mainly focused on design, development and maintenance of high-quality websites, apps and eCommerce shops.`,
    url: 'https://wenet.gr/',
  },
  {
    image: 'Partners/Robobizz.webp',
    title: `RoboBIZZ`,
    shortDescription: `Robobizz is an artificial intelligence, working as a fully automated eCommerce SAAS solution.`,
    url: 'https://robobizz.com/',
  },
  {
    image: 'Partners/Prodesign.webp',
    title: `Prodesign`,
    shortDescription: `They specialize in providing high-quality branding solutions for business clients in the eCommerce sector.`,
    url: 'https://prodesign.bg/?fbclid=IwAR2WS7c_C2asdwNUgZCuuyFeiP1lNECVlcLc8zwht8BRPDlAn6mwSG0gkhg',
  },
  {
    image: 'Partners/LevelTechnicalGroup.webp',
    title: `Level Technical Group`,
    shortDescription: `Level Techical Group are engaged in implementing successful e-shops adapted to the requirements of clients' businesses and products.`,
    url: 'https://leveltgp.gr/',
  },
  {
    image: 'Partners/BigArena.webp',
    title: `Big Arena`,
    shortDescription: `BigArena offers a range of logistics and e-commerce solutions for online retailers, including fulfillment services like warehousing, order processing, and delivery across Europe.`,
    url: 'https://bigarena.net',
  },
  {
    image: 'Partners/Gomag.webp',
    title: `Gomag`,
    shortDescription: `Gomag is an e-commerce platform that enables users to create and manage online stores with customizable themes and a wide range of integrated applications.`,
    url: 'https://Gomag.ro',
  },
  {
    image: 'Partners/HappyOnline.webp',
    title: `HappyOnline`,
    shortDescription: `HappyOnline is a web design and development company that specializes in creating customized websites, e-commerce solutions, and hosting services.`,
    url: 'https://HappyOnline.gr',
  },
  {
    image: 'Partners/Improve.webp',
    title: `Improve`,
    shortDescription: `Improve.bg is a digital marketing agency that specializes in providing a wide range of services such as SEO optimization, PPC advertising, social media marketing, and web design.`,
    url: 'https://Improve.bg',
  },
  {
    image: 'Partners/RiscaseDigital.webp',
    title: `Riscase Digital`,
    shortDescription: `Riscase is a digital marketing agency focused on delivering ROI-oriented solutions through services such as Facebook Ads, Google Ads, email marketing, social media growth, and conversion rate optimization (CRO).`,
    url: 'https://Riscase.com',
  },
];

export default partnersListItems;
