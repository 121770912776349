import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './ValueTransformer.css';

const ValueTransformer = () => {
  return (
    <section className="value-transformer">
      <div className="section-011">
        <div className="section-01-child">
          <div className="ebaglogo-parent">
            <div className="ebaglogo1">01</div>
            <div className="data-integration-and-unificati-parent">
              <h1 className="data-integration-and">Data Integration and Unification</h1>
              <div className="releva-simplifies-your">
                Releva simplifies your journey to success by seamlessly integrating with your eCommerce platform and
                digital channels. Our platform effortlessly pulls data from a multitude of sources, including customer
                profiles, website interactions, and purchase history. This unified data source becomes your foundation
                for making informed decisions and crafting personalized strategies.
              </div>
              <div className="button28">
                <a
                  className="button-name25"
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={(e) =>
                    handleBookaDemoClick(
                      e,
                      'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                    )
                  }
                >
                  Book a demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="matrix-multiplier-parent">
          <img
            loading="lazy"
            src="/OverviewPage/DataIntegrationUnificaiton.webp"
            alt="DataIntegrationUnificaiton"
            title="DataIntegrationUnificaiton"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default ValueTransformer;
