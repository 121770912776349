import React, { useState } from 'react';
import './OnePlatformForEcommerceSuccess.css';

const OnePlatformForEcommerceSuccess = () => {
  const [activeImage, setActiveImage] = useState('Slider1.webp'); // Default active image is image1
  const [activeHeading, setActiveHeading] = useState('heading1'); // Default active heading is heading1

  // Function to handle click on div elements with class 'onePlatformDivClick'
  const handleDivClick = (imageName, headingName) => {
    setActiveImage(imageName); // Set the clicked image as active
    setActiveHeading(headingName); // Set the clicked heading as active
  };

  return (
    <section className="frame-section">
      <div className="frame-parent29">
        <div className="icon-parent">
          <div
            className={`icon1 onePlatformDivClick ${activeHeading === 'heading1' ? 'active' : ''}`}
            onClick={() => handleDivClick('Slider1.webp', 'heading1')}
          >
            <div className="personalized-product-recommend-wrapper">
              <div className="personalized-product-recommend">Personalized product recommendations</div>
            </div>
          </div>
          <div
            className={`content-container onePlatformDivClick ${activeHeading === 'heading2' ? 'active' : ''}`}
            onClick={() => handleDivClick('Slider2.webp', 'heading2')}
          >
            <h2 className="basket-browse">Basket & browse abandonment retargeting</h2>
          </div>
          <div
            className={`content-container1 onePlatformDivClick ${activeHeading === 'heading3' ? 'active' : ''}`}
            onClick={() => handleDivClick('Slider3.webp', 'heading3')}
          >
            <h2 className="omnichannel-customer-engagemen">Omnichannel customer engagement</h2>
          </div>
          <div
            className={`content-container2 onePlatformDivClick ${activeHeading === 'heading4' ? 'active' : ''}`}
            onClick={() => handleDivClick('Slider4.webp', 'heading4')}
          >
            <h2 className="post-purchase-strategies">Post-purchase strategies</h2>
          </div>
          <div
            className={`content-container3 onePlatformDivClick ${activeHeading === 'heading5' ? 'active' : ''}`}
            onClick={() => handleDivClick('Slider5.webp', 'heading5')}
          >
            <h2 className="cross-sell-upsell">Cross-sell & upsell strategies</h2>
          </div>
        </div>
        <div className="wrapper-p5">
          <img
            loading="lazy"
            className={`p5-icon onePlatformImg ${activeImage}`}
            alt=""
            src={`/HomePage/${activeImage}`}
          />
          {/* Render the image based on the activeImage state */}
        </div>
      </div>
    </section>
  );
};

export default OnePlatformForEcommerceSuccess;
