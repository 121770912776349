import { useMemo } from 'react';
import './LabeledIcon.css';

const LabeledIcon = ({ businessEmail, icon, propMarginTop }) => {
  const labeledIconStyle = useMemo(() => {
    return {
      marginTop: propMarginTop,
    };
  }, [propMarginTop]);

  return (
    <div className="labeled-icon" style={labeledIconStyle}>
      <div className="business-email">{businessEmail}</div>
      <div className="input-field1">
        <div className="text1">
          <div className="typing">Typing |</div>
          <img className="icon4" alt="" src={icon} />
        </div>
      </div>
    </div>
  );
};

export default LabeledIcon;
