import './HowItWorks1.css';

const HowItWorks2 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img
          className="screenshot-2024-04-09-at-2028-icon"
          loading="lazy"
          alt=""
          src="/Omnipersonalization/omni4.webp"
        />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">04</div>
          <div className="master-your-generated">Tailored Search Results</div>
          <div className="edit-your-documents">for Better User Experience</div>
          <div className="customize-your-generated">
            Personalize search results for quicker conversions. Releva customizes search experiences based on individual
            user data, making it easier for customers to find what they need quickly, while reducing frustration and
            accelerating their path to completing the purchase.  
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks2;
