import './HowItWorks.css';
import TransformOneTimeBuyers from './TransformOneTimeBuyers';

const HowItWorks = () => {
  return (
    <section className="how-it-works1">
      <div className="c-t-a-background2">
        <h1 className="experience-the-releva">Experience the Releva difference</h1>
        <div className="frame-parent30">
          <TransformOneTimeBuyers
            icon01="/icon-01.svg"
            transformOneTimeBuyersInt="Transform one-time buyers into loyal customers"
            unleashThePowerOfPostPurc="Unleash the power of post-purchase strategies by using retargeting techniques to upsell and cross-sell strategies to give customers a reason to keep coming back to your online shop."
          />
          <div className="reporting2">
            <img className="icon-02" loading="lazy" alt="" src="/icon-02.svg" />
            <div className="why-work-with-us-wrapper">
              <div className="why-work-with-us">
                <h2 className="reporting-and-analytics">Reporting and analytics</h2>
                <div className="know-exactly-how">
                  Know exactly how Releva is growing your store. Track the performance of your social media and commerce
                  search ads in real-time. Easily monitor the top-performing product sets and make adjustments when
                  needed
                </div>
              </div>
            </div>
          </div>
          <TransformOneTimeBuyers
            icon01="/icon-03.svg"
            transformOneTimeBuyersInt="Save site speed"
            unleashThePowerOfPostPurc="Simplify Your Tech Stack – a cumbersome app stack can slow your site to a crawl, lower your site traffic, and drop your search rankings. Releva’s all-in-one platform replaces a number of single-function apps."
            propWidth="427px"
            propAlignSelf="unset"
          />
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
