import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './FrameComponent12.css';

const FrameComponent12 = () => {
  return (
    <section className="section-06-wrapper">
      <div className="section-061">
        <div className="section-06-inner">
          <div className="parent9">
            <div className="div12">02</div>
            <div className="your-message-everywhere-parent">
              <h1 className="your-message-everywhere">Your Message Everywhere</h1>
              <div className="seamlessly-engaging-users">
                Seamlessly engaging users beyond your website, ensuring your message reaches them no matter where they
                are.
              </div>
              <button className="button20">
                <a
                  rel="noopener noreferrer"
                  className="button-name18"
                  target="_blank"
                  onClick={(e) =>
                    handleBookaDemoClick(
                      e,
                      'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                    )
                  }
                >
                  Book a demo
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="frame-parent67">
          <img
            loading="lazy"
            src="/ProductPage/YourMessageEverywhere.webp"
            alt="OmnichannelMastery"
            title="OmnichannelMastery"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent12;
