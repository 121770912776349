import './DataWarden.css';

const DataWarden = () => {
  return (
    <section className="data-warden">
      <div className="data-container">
        <img
          loading="lazy"
          src="/OverviewPage/Results.webp"
          alt="Results"
          title="Results"
          className="HomePageHeroImage"
        />
      </div>
    </section>
  );
};

export default DataWarden;
