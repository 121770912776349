import LabeledIcon from './LabeledIcon';
import './OvalShape.css';
import ShapeConnector from './ShapeConnector';
import ShapeConnector1 from './ShapeConnector1';

const OvalShape = () => {
  return (
    <section className="oval-shape">
      <div className="block-1">
        <form className="get-my-free-ebook-parent">
          <h1 className="get-my-free">Get my FREE eBook</h1>
          <div className="frame-wrapper43">
            <div className="componentvariant6-parent">
              <LabeledIcon businessEmail="Name" icon="pending_I1:1505;1:1460" propMarginTop="unset" />
              <div className="labeled-icon-parent">
                <LabeledIcon businessEmail="Business email*" icon="pending_1:1460" />
                <div className="componentvariant6-group">
                  <LabeledIcon businessEmail="Business email*" icon="pending_I1:1489;1:1460" propMarginTop="unset" />
                  <LabeledIcon businessEmail="Business email*" icon="pending_I1:1497;1:1460" propMarginTop="-82px" />
                </div>
              </div>
              <ShapeConnector1 />
              <ShapeConnector />
            </div>
          </div>
          <div className="button-frame">
            <div className="button39">
              <div className="button-name34">Get my eBook</div>
            </div>
          </div>
        </form>
        <div className="data-aggregator7">
          <div className="function-chain">
            <div className="image-collection-parent">
              <div className="image-collection1">
                <img className="vuesaxlineardirect-up-icon18" loading="lazy" alt="" src="/vuesaxlineardirectup.svg" />
              </div>
              <div className="loop-structure-wrapper">
                <div className="loop-structure">52%</div>
              </div>
              <div className="releva-contrubited-to">{`Releva contrubited to Kidso’s success `}</div>
            </div>
            <div className="data-split-parent">
              <div className="data-split">
                <img className="vuesaxlineardirect-up-icon19" alt="" />
                <div className="error-handler7">
                  <img
                    className="vuesaxlinearshopping-cart-icon2"
                    loading="lazy"
                    alt=""
                    src="/vuesaxlinearshoppingcart.svg"
                  />
                </div>
                <div className="parallel-process">
                  <div className="function-call-stack">
                    <h1 className="boost2">{`Boost `}</h1>
                  </div>
                  <div className="e-commerce-growth1">e-commerce growth</div>
                </div>
              </div>
              <div className="output-display">
                <div className="flow-control">
                  <img className="event-trigger-icon" alt="" src="/vector.svg" />
                  <img
                    className="a-boy-and-a-girl-lie-on-the-fl-icon"
                    loading="lazy"
                    alt=""
                    src="/aboyandagirllieonthefloorplaymerrilyw20231127051108utcremovebgpreview-1@2x.webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OvalShape;
