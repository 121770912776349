const aiHubFeatures = [
  {
    feature: 'Brand Context, Voice, Description Generation',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Audience generation*',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature:
      'Text Generation: native support of English, Spanish, French, German, Italian, Greek, Bulgarian, Indonesian',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Text Generation: Email, SMS, Viber, WhatsApp, Facebook Ad, Blog post',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Image Generation: text to image, image to image',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'AI Chat',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Organization: Folders, Chats, Documents, Images',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature:
      'Collaboration: Collaborate in a single document, write and generate images and text; Share documents, images and chats with your colleagues',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
];

export default aiHubFeatures;
