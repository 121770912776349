import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './LogicGate2.css';

const LogicGate2 = () => {
  return (
    <section className="logic-gate12">
      <div className="frame-parent96">
        <div className="frame-wrapper37">
          <div className="engagement-conversion-parent">
            <h1 className="engagement-conversion">{`Engagement & Conversion`}</h1>
            <div className="unlocking-ecommerce-success">
              Unlocking eCommerce success through Engagement and Conversion.
            </div>
            <div className="button31">
              <a
                rel="noopener noreferrer"
                onClick={(e) =>
                  handleBookaDemoClick(
                    e,
                    'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                  )
                }
                target="_blank"
              >
                <div className="button-name28">Book a demo</div>
              </a>
            </div>
          </div>
        </div>
        <div className="i-11">
          <img
            loading="lazy"
            src="/OverviewPage/EngagementConversion.webp"
            alt="EngagementConversion"
            title="EngagementConversion"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default LogicGate2;
