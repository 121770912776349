//import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import App from './App';
import Footer from './components/Footer';
import Header from './components/Header';
import './global.css';

function redirectToHashUrl() {
  const currentUrl = window.location.href;
  const hashIndex = currentUrl.indexOf('#');

  // If the URL does not contain a hash, redirect to the URL with a hash
  if (hashIndex === -1) {
    const path = window.location.pathname + window.location.search;
    window.location.replace(`${window.location.origin}/#${path}`);
    return false; // Return false to indicate redirection
  }
  return true; // Return true to indicate no redirection needed
}

// Check if we need to redirect before rendering the app
if (redirectToHashUrl()) {
  // No redirection needed, render the app immediately
  renderApp();
} else {
  console.log('here');
  // Redirection needed, wait for it to complete before rendering
  window.addEventListener('hashchange', renderApp, { once: true });
}

function renderApp() {
  ReactDOM.render(
    <Router>
      <Header />
      <App />
      <Footer />
    </Router>,
    document.getElementById('root')
  );
}
