const marketingChannelsFeatures = [
  {
    feature: 'Web-push',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Email',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Coupon discounts',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Google, Instagram & Facebook',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Automations using text messaging*',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Automations using Viber, WhatsApp messaging*',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
];

export default marketingChannelsFeatures;
