import "./Vuesaxlinearsend2Icon.css";

const Vuesaxlinearsend2Icon = () => {
  return (
    <img
      className="vuesaxlinearsend-2-icon1"
      loading="lazy"
      alt=""
      src="/vuesaxlinearsend21.svg"
    />
  );
};

export default Vuesaxlinearsend2Icon;
