import './HowItWorks1.css';

const HowItWorks1 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img
          className="screenshot-2024-04-09-at-2028-icon"
          loading="lazy"
          alt=""
          src="/Omnipersonalization/omni2.webp"
        />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">02</div>
          <div className="master-your-generated">Turn Browse & Basket</div>
          <div className="edit-your-documents">Abandonment into Purchases</div>
          <div className="customize-your-generated">
            Recover lost opportunities: reduce basket abandonment with timely interventions. Releva identifies when
            users are likely to abandon their unfinished orders and engages them with customized messages or offers,
            turning potential losses into completed sales and increasing overall revenue.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks1;
