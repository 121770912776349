import './HowItWorks1.css';

const HowItWorks1 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/SocialMediaPage/omni2.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">02</div>
          <div className="master-your-generated">Optimize Your Marketing</div>
          <div className="edit-your-documents">to Drive Success</div>
          <div className="customize-your-generated">
            With Releva’s Workflows, marketing managers can automate the entire lifecycle of their ad campaigns, from
            planning and execution to analysis. This not only ensures consistency and efficiency in message delivery but
            also empowers teams to deploy more complex, creative marketing strategies. Enhanced automation capabilities
            allow for real-time adjustments based on analytics, ensuring that campaigns are always optimized for maximum
            performance.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks1;
