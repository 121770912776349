import './Container.css';
import Ionut from './Ionut';

const Container = () => {
  return (
    <section className="container3">
      <div className="panel">
        <div className="page">
          <div className="releva-team-wrapper">
            <div className="releva-team">Releva team</div>
          </div>
          {/* <div className="ceo-wrapper">
            <div className="ceo">CEO</div>
          </div>
          <div className="cto-wrapper">
            <div className="cto">CTO</div>
          </div>
          <div className="customer-success-team-wrapper">
            <div className="customer-success-team">Customer Success Team</div>
          </div>
          <div className="sales-team-wrapper">
            <div className="sales-team">Sales Team</div>
          </div>
          <div className="marketing-team-wrapper">
            <div className="marketing-team">Marketing team</div>
          </div>
          <div className="technical-team-wrapper">
            <div className="technical-team">Technical team</div>
          </div>
          <div className="technical-team-container">
            <div className="technical-team1">Machine learning team</div>
          </div> */}
        </div>
        <div className="slide">
          <div className="layer">
            <div className="mask">
              <div className="data-gatherer1">
                <div className="condition-splitter3">
                  <div className="georgi">
                    <div className="output-filter1">
                      <b className="georgi-georgiev2">Georgi Georgiev</b>
                      <div className="decision-maker2">
                        <img loading="lazy" className="vector-icon33" alt="" src="/vector-15.svg" />
                        <img loading="lazy" className="photo-icon1" alt="" src="/georgi-photo.webp" />
                      </div>
                    </div>
                    <div className="function-applier2">
                      <div className="ceo-and-co-founder">CEO and co-founder</div>
                    </div>
                  </div>
                </div>
                <Ionut
                  ionutLeoveanu="Ionut Leoveanu"
                  whatsAppImage20230812At17="/whatsapp-image-20230812-at-1708-1@2x.webp"
                />
                <div className="hristina">
                  <div className="signal-emitter1">
                    <b className="hristina-petkova"> Hristina Petkova</b>
                    <div className="data-reader">
                      <img loading="lazy" className="vector-icon34" alt="" src="/vector-23.svg" />
                      <img
                        className="removebg-preview-1-icon"
                        loading="lazy"
                        alt=""
                        src="/1602609346683removebgpreview-1@2x.webp"
                      />
                    </div>
                  </div>
                  <div className="value-enricher">
                    <div className="product-marketing-specialist">Head of Marketing</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mask">
              <div className="error-handler10">
                <div className="sequence-processor">
                  <div className="yavor">
                    <div className="data-combiner">
                      <b className="yavor-stoychev2">Yavor Stoychev</b>
                      <div className="result-splitter">
                        <img className="error-handler-icon" alt="" src="/vector-23.svg" />
                        <img
                          className="dsc-8093-eddit-min-removebg-pr-icon"
                          loading="lazy"
                          alt=""
                          src="/dsc-8093edditminremovebgpreview-1@2x.webp"
                        />
                      </div>
                    </div>
                    <div className="value-sorter">
                      <div className="cto-and-co-founder">CTO and co-founder</div>
                    </div>
                  </div>
                </div>
                <div className="radosalv">
                  <div className="result-formatter">
                    <b className="radoslav-danev">Radoslav Danev</b>
                    <div className="sequence-monitor">
                      <img className="value-sequencer-icon" alt="" src="/vector-23.svg" />
                      <img
                        className="f409cde-7e16-46f2-9d53-bae5633-icon"
                        loading="lazy"
                        alt=""
                        src="/0f409cde7e1646f29d53bae5633cd2d0-1-105-cremovebgpreview-2@2x.webp"
                      />
                    </div>
                  </div>
                  <div className="tree-splitting">
                    <div className="customer-success-specialist1">Customer Success Specialist</div>
                  </div>
                </div>
                <div className="error-handler11">
                  <div className="georgi-keranov">
                    <div className="value-transformer1">
                      <b className="georgi-keranov1">Georgi Keranov</b>
                      <div className="tree-reducer">
                        <div className="result-extractor">
                          <img loading="lazy" className="vector-icon35" alt="" src="/vector-15.svg" />
                          <img
                            loading="lazy"
                            className="removebg-preview-1-icon1"
                            alt=""
                            src="/1633517903670removebgpreview-1@2x.webp"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="software-engineer-wrapper">
                      <div className="software-engineer">Team Lead Software Engineer</div>
                    </div>
                  </div>
                  <div className="dobri">
                    <div className="vector-parent13">
                      <img loading="lazy" className="vector-icon36" alt="" src="/vector-23.svg" />
                      <img
                        className="dobri-removebg-preview-1-icon"
                        loading="lazy"
                        alt=""
                        src="/dobriremovebgpreview-1@2x.webp"
                      />
                    </div>
                    <div className="frame-parent138">
                      <div className="dobri-nedev-wrapper">
                        <b className="dobri-nedev">Dobri Nedev</b>
                      </div>
                      <div className="junior-full-stack">Software Engineer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mask">
              <div className="error-handler10">
                <div className="boryana-wrapper">
                  <div className="boryana">
                    <div className="boryana-antonova-parent">
                      <b className="boryana-antonova">Boryana Antonova</b>
                      <div className="vector-parent14">
                        <img loading="lazy" className="vector-icon37" alt="" src="/vector-15.svg" />
                        <img loading="lazy" className="photo-icon2" alt="" src="/photo-11@2x.webp" />
                      </div>
                    </div>
                    <div className="data-merger">
                      <div className="customer-success-manager">Director Customer Success</div>
                    </div>
                  </div>
                </div>
                <div className="data-combiner1">
                  <div className="maria">
                    <div className="signal-splitter">
                      <b className="maria-ivanova">Maria Ivanova</b>
                      <div className="data-joiner">
                        <img loading="lazy" className="valueaddedproduct-icon" alt="" src="/vector-15.svg" />
                        <img
                          className="removebg-preview-1-icon2"
                          loading="lazy"
                          alt=""
                          src="/1654029429171removebgpreview-1@2x.webp"
                        />
                      </div>
                    </div>
                    <div className="data-selector">
                      <div className="sales-director">Sales Director Balkans</div>
                    </div>
                  </div>
                </div>
                <div className="error-handler12">
                  <div className="dean">
                    <div className="value-comparator3">
                      <b className="dean-dupalov">Dean Dupalov</b>
                      <div className="output-transformer">
                        <img className="merchant-platform-icon" alt="" src="/vector-23.svg" />
                        <img
                          className="removebg-preview-1-icon3"
                          loading="lazy"
                          alt=""
                          src="/1653493496417removebgpreview-1@2x.webp"
                        />
                      </div>
                    </div>
                    <div className="data-processor1">
                      <div className="junior-full-stack1">Team Lead Software Engineer</div>
                    </div>
                  </div>
                  <div className="asen">
                    <div className="tree-analyzer">
                      <b className="asen-antonov">Asen Antonov</b>
                      <div className="result-parser">
                        <div className="error-handler13">
                          <img loading="lazy" className="vector-icon38" alt="" src="/vector-23.svg" />
                          <img
                            loading="lazy"
                            className="mask-group-6-removebg-preview-icon"
                            alt=""
                            src="/mask-group-6removebgpreview-1@2x.webp"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="data-mapper1">
                      <div className="senior-data-scientist">Team Lead Machine Learning Engineer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mask">
              <div className="error-handler10">
                <Ionut
                  ionutLeoveanu="Nikoleta  Bogdanova"
                  whatsAppImage20230812At17="/1622468126513removebgpreview-1@2x.webp"
                  propPadding="var(--padding-170xl) var(--padding-xl) var(--padding-xs)"
                  propLeft="21.3px"
                  propMinWidth="unset"
                  propWidth="147px"
                  propHeight="147px"
                />
                <div className="dimitri-parent">
                  <div className="dimitri">
                    <div className="dimitrios-ntousikos-parent">
                      <b className="dimitrios-ntousikos">Dimitrios Ntousikos</b>
                      <div className="vector-parent15">
                        <img loading="lazy" className="vector-icon39" alt="" src="/vector-23.svg" />
                        <img
                          className="removebg-preview-1-icon4"
                          loading="lazy"
                          alt=""
                          src="/1660054042525removebgpreview-1@2x.webp"
                        />
                      </div>
                    </div>
                    <div className="business-development-manager-wrapper">
                      <div className="business-development-manager">Sales Director Greece</div>
                    </div>
                  </div>
                  <div className="sequence-sorter">
                    <div className="radostin">
                      <div className="customer-data-segmentation3">
                        <img loading="lazy" className="automation-icon" alt="" src="/vector-23.svg" />
                        <img
                          className="removebg-preview-1-icon5"
                          loading="lazy"
                          alt=""
                          src="/1652192135110removebgpreview-1@2x.webp"
                        />
                      </div>
                      <div className="radostin-minchev-wrapper">
                        <b className="radostin-minchev">Radostin Minchev</b>
                      </div>
                      <div className="junior-full-stack2">Software Engineer</div>
                    </div>
                  </div>
                  <div className="daren">
                    <div className="data-aggregator9">
                      <div className="input-filter4">
                        <b className="daren-stoev">Daren Stoev</b>
                        <div className="pattern-matcher">
                          <img loading="lazy" className="value-added-product2" alt="" src="/vector-23.svg" />
                          <img
                            className="removebg-preview-1-icon6"
                            loading="lazy"
                            alt=""
                            src="/1657800769504removebgpreview-1@2x.webp"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="junior-machine-learning">Software Engineer</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mask">
              <div className="error-handler10">
                <div className="irena">
                  <div className="frame-parent139">
                    <div className="vector-parent16">
                      <img loading="lazy" className="vector-icon40" alt="" src="/vector-23.svg" />
                      <img
                        loading="lazy"
                        className="removebg-preview-1-icon7"
                        alt=""
                        src="/1633149565290removebgpreview-1@2x.webp"
                      />
                    </div>
                    <b className="irena-yordanova">Irena Yordanova</b>
                  </div>
                  <div className="customer-success-specialist-wrapper">
                    <div className="customer-success-specialist2">Customer Success Specialist</div>
                  </div>
                </div>
                <div className="asen-parent">
                  <div className="asen1">
                    <div className="asen-ivanov-parent">
                      <b className="asen-ivanov">Asen Ivanov</b>
                      <div className="vector-parent17">
                        <img loading="lazy" className="vector-icon41" alt="" src="/vector-23.svg" />
                        <img
                          loading="lazy"
                          className="removebg-preview-1-icon8"
                          alt=""
                          src="/1640686118356removebgpreview-1@2x.webp"
                        />
                      </div>
                    </div>
                    <div className="business-development-manager1">Director partnerships</div>
                  </div>
                  <div className="krasimira">
                    <div className="krasimira-georgieva-parent">
                      <b className="krasimira-georgieva">Krasimira Georgieva</b>
                      <div className="vector-parent18">
                        <img loading="lazy" className="vector-icon42" alt="" src="/vector-15.svg" />
                        <img
                          className="removebg-preview-1-icon9"
                          alt=""
                          loading="lazy"
                          src="/1677091545064removebgpreview-1@2x.webp"
                        />
                      </div>
                    </div>
                    <div className="removebg-preview-1" />
                    <div className="value-mapper-tree">
                      <div className="junior-full-stack3">Software Engineer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Container;
