import { useMemo } from 'react';
import './LogicGate.css';

const LogicGate = ({ howDoesRelevaCollectAndAn, propPadding, propBorderTop }) => {
  const logicGateStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const frameDiv4Style = useMemo(() => {
    return {
      borderTop: propBorderTop,
    };
  }, [propBorderTop]);

  return (
    <section className="logic-gate14" style={logicGateStyle}>
      <div className="frame-parent104" style={frameDiv4Style}>
        <div className="how-does-releva-collect-and-an-wrapper">
          <div className="how-does-releva">{howDoesRelevaCollectAndAn}</div>
        </div>
        <img className="communication-center-icon1" loading="lazy" alt="" src="/frame-229.svg" />
      </div>
    </section>
  );
};

export default LogicGate;
