import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import CompareValues from '../components/OverviewPage/CompareValues';
import DataFlowController from '../components/OverviewPage/DataFlowController';
import DataWarden from '../components/OverviewPage/DataWarden';
import DijkstraAlgorithm from '../components/OverviewPage/DijkstraAlgorithm';
import FrameComponent23 from '../components/OverviewPage/FrameComponent23';
import FrameComponent24 from '../components/OverviewPage/FrameComponent24';
import FrameComponent25 from '../components/OverviewPage/FrameComponent25';
import IterativeStructure from '../components/OverviewPage/IterativeStructure';
import LogicGate from '../components/OverviewPage/LogicGate';
import LogicGate1 from '../components/OverviewPage/LogicGate1';
import LogicGate2 from '../components/OverviewPage/LogicGate2';
import PowFunction from '../components/OverviewPage/PowFunction';
import PressureControl from '../components/OverviewPage/PressureControl';
import SymbolLibrary from '../components/OverviewPage/SymbolLibrary';
import ValueTransformer from '../components/OverviewPage/ValueTransformer';
import pagesJsonLD from '../constants/pagesJsonLD';
import './Overview.css';

const Overview = () => {
  return (
    <div className="overview">
      <Helmet>
        <title>Releva AI | Overview</title>
        <meta
          name="description"
          content="Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.overviewPage)}</script>
      </Helmet>
      <HomePageHeroTitle
        heroTitle="Your Data-Driven Companion for E-commerce Excellence"
        heroDescription="eCommerce success hinges on more than just great products. It's about personalization, precision, and
        propelling your brand to the forefront. Releva is not just another tool, we're your path to data-driven
        marketing triumph."
        heroYoutube={true}
      />
      {/* <OpacitySlider /> */}
      <SymbolLibrary />
      <IterativeStructure />
      <section className="logic-gate">
        <h1 className="how-it-works">How It Works</h1>
      </section>
      <section className="logic-gate1">
        <div className="boosting-your-ecommerce">Boosting Your eCommerce Success. It's as Easy as 1, 2, 3</div>
      </section>
      <ValueTransformer />
      <PressureControl />
      <FrameComponent25 />
      <section className="logic-gate2">
        <h1 className="quick-integrations">Quick Integrations</h1>
      </section>
      <section className="logic-gate3">
        <div className="we-provide-seamless">We provide seamless integration with major eCommerce platforms.</div>
      </section>
      <PowFunction />
      <FrameComponent24 />
      <FrameComponent23 />
      <section className="logic-gate4" id="solutions">
        <h1 className="solutions">Solutions</h1>
      </section>
      <section className="logic-gate5">
        <div className="your-toolbox-to">
          Your toolbox to ignite eCommerce growth, AI-powered personalization, and a remarkable 50x ROI boost.
        </div>
      </section>
      <LogicGate2 />
      <DijkstraAlgorithm />
      <LogicGate1 />
      <DataFlowController />
      <section className="logic-gate6" id="results">
        <h1 className="results">Results</h1>
      </section>
      <section className="logic-gate7">
        <div className="delivering-exceptional-results">Delivering Exceptional Results: Our Customers Speak</div>
      </section>
      <DataWarden />
      <CompareValues />
      <section className="logic-gate8" id="faq">
        <h1 className="faq">FAQ</h1>
      </section>
      <section className="logic-gate9">
        <div className="your-questions-answered">Your Questions, Answered</div>
      </section>
      <LogicGate howDoesRelevaCollectAndAn="How does Releva collect and analyze customer data?" />
      <LogicGate
        howDoesRelevaCollectAndAn="Can Releva integrate with my existing e-commerce platform?"
        propPadding="0px var(--padding-5xl) 0px var(--padding-xl)"
        propBorderTop="unset"
      />
      <section className="layout-manager">
        <div className="c-t-a-background">
          <div className="canvas-designer">
            <div className="is-my-customer">Is my customer data safe with Releva?</div>
            <div className="absolutely-we-prioritize">
              Absolutely. We prioritize data security and comply with industry-standard privacy regulations to safeguard
              your customer data.
            </div>
          </div>
          <div className="value-modifier">
            <img className="team-blog-icon" alt="" src="/frame-229.svg" />
          </div>
        </div>
      </section>
      <LogicGate
        howDoesRelevaCollectAndAn="What kind of support and training does Releva offer?"
        propPadding="0px var(--padding-5xl) 0px var(--padding-xl)"
        propBorderTop="unset"
      />
      <LogicGate
        howDoesRelevaCollectAndAn="What results can I expect from using Releva?"
        propPadding="0px var(--padding-5xl) var(--padding-141xl) var(--padding-xl)"
        propBorderTop="unset"
      />
    </div>
  );
};

export default Overview;
