import './FrameComponent22.css';

const FrameComponent22 = () => {
  return (
    <section className="data-driven-marketers-inner">
      <div className="frame-parent73">
        <div className="trusted-by-100-ecommerce-bran-container">
          <h2 className="trusted-by-1002">Trusted by 100+ ecommerce brands</h2>
        </div>
        <div className="why-work-with-us1">
          <img className="imoteka-icon2" loading="lazy" alt="" src="/imoteka.svg" />
          <img className="ebag-icon2" loading="lazy" alt="" src="/ebag.svg" />
          <img className="trendo-icon2" loading="lazy" alt="" src="/trendo.svg" />
          <img className="adres-icon2" loading="lazy" alt="" src="/adres.svg" />
          <img className="mercari-icon2" loading="lazy" alt="" src="/mercari.svg" />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent22;
