import './SymbolLibrary.css';

const SymbolLibrary = () => {
  return (
    <section className="symbol-library">
      <img
        loading="lazy"
        src="/OverviewPage/HeroImage.webp"
        alt="HeroImage"
        title="HeroImage"
        className="HomePageHeroImage"
      />
    </section>
  );
};

export default SymbolLibrary;
