import './ShapeConnector.css';

const ShapeConnector = () => {
  return (
    <div className="shape-connector1">
      <div className="ecommerce-url">eCommerce URL*</div>
      <div className="input-field3">
        <div className="text3">
          <div className="typing2">Typing |</div>
          <img className="icon6" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ShapeConnector;
