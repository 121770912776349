import { Helmet } from 'react-helmet';
import SubMenu from '../components/CookiePolicy/SubMenu';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy3">
      <Helmet>
        <title>Releva AI | Cookie Policy</title>
        <meta
          name="description"
          content="Learn about Releva AI's Cookie Policy to understand how we use cookies to enhance your experience on our site. Find details on how we collect, use, and manage cookies and your options for controlling them."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.cookiesPolicyPage)}</script>
      </Helmet>
      <section className="cookie-policy-container">
        <h1 className="cookie-policy4">Cookie Policy</h1>
      </section>
      <SubMenu active="cookie-policy" />
      <section className="at-releva-we-respect-individua-wrapper">
        <div className="at-releva-we-container">
          <p className="at-releva-we-respect-individua">
            <span className="at-releva-we-respect-individua1">
              <span className="at-releva-we-respect-individua2">
                <span className="at-releva-we">
                  At Releva we respect individuals’ rights to privacy and believe in the relationship transparency that
                  we have with you. Thats why we would like to inform you about our cookie policy.
                </span>
              </span>
            </span>
          </p>
          <p className="we-use-cookies-is-to-ensure-th">
            <span className="we-use-cookies-is-to-ensure-th1">
              <span className="we-use-cookies-is-to-ensure-th2">
                <span className="we-use-cookies">
                  We use cookies is to ensure that your experience on our website is smooth and productive. The cookies
                  we use help us to understand your behavior, in order to deliver the best user experience.
                </span>
              </span>
            </span>
          </p>
          <p className="this-policy-covers-information">
            <span className="this-policy-covers-information1">
              <span className="this-policy-covers-information2">
                <span className="this-policy-covers">
                  This Policy covers information we collect from you through all of our channels, including website,
                  email and others. Some of our website’s functionality can be used without revealing any of Your Data.
                  In order to access certain products, demonstrators or services, you may need to submit, or we may
                  collect information that can be used to identify you.
                </span>
              </span>
            </span>
          </p>
          <p className="what-is-a-cookie-and-what-is-i">
            <span className="what-is-a-cookie-and-what-is-i1">
              <b className="what-is-a">1. What is a cookie and what is it good for?</b>
            </span>
          </p>
          <p className="cookies-are-small-text-files-t">
            <span className="cookies-are-small">
              Cookies are small text files that contain some information. They are downloaded by your web browser when
              you visit our website. Cookies do a number of very useful jobs, such as remembering your preferences,
              telling us how you interact with our website or how you found our website. Our use of cookies is limited
              to internal use, mainly to better organize our web presence, to find out if some sections of our website
              work the way we want them to work.
            </span>
          </p>
          <p className="we-use-few-essential-cookies">
            <span className="we-use-few">
              We use few essential cookies: Google Analytics cookie from Alphabet Inc., cookies from Facebook, LinkedIn
              and HotJar.
            </span>
          </p>
          <p className="all-cookies-are-considered-to">
            <span className="all-cookies-are">
              All cookies are considered to be a third-party cookie, which means they are managed by a third party;
              however, the data it collects are strictly limited to the cookie tracking mentioned above. We generally
              use Google Analytics, Facebook and LinkedIn to find out more about you as a customer or potential
              customer. The data collected varies, depending on whether you are logged in with your Google/ Facebook or
              LinkedIn accounts or not. This cookie tracks, for example, location data, browser type, origination
              website, time of your session as well as some demographic data, such as your age bracket and gender.
            </span>
          </p>
          <p className="the-hotjar-cookie-analyses-you">
            <span className="the-hotjar-cookie">The Hotjar cookie analyses your behaviour within Releva’s website</span>
          </p>
          <p className="each-cookie-is-kept-for-a-diff">
            <span className="each-cookie-is">
              Each cookie is kept for a different period. We do not keep cookie data for more than 13 months.
            </span>
          </p>
          <p className="how-do-i-manage-my-cookie">
            <b>
              <span className="how-do-i">4. How do I manage my cookie?</span>
            </b>
          </p>
          <p className="most-internet-browsers-automat">
            <span>
              <span>
                Most internet browsers automatically allow cookies to be stored on your device. Depending on your
                browser, you should be able to manage acceptance and management of current cookies yourself. You can
                decide to disable cookies for our website; you can still use our services, but enabled cookies help us
                greatly, to provide you with the best experience while using our services.
              </span>
            </span>
          </p>
          <p className="if-you-want-to-find-out-more-a">
            <span>
              <span>If you want to find out more about cookies, please visit </span>
            </span>
            <a
              rel="noopener noreferrer"
              className="wwwallaboutcookiesorg"
              href="http://www.allaboutcookies.org/"
              target="_blank"
            >
              <span>
                <span>
                  <span className="wwwallaboutcookiesorg1">www.allaboutcookies.org</span>
                </span>
              </span>
            </a>
            <span>
              <span>.</span>
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default CookiePolicy;
