import './Block2.css';

const Block2 = () => {
  return (
    <div className="block-11">
      <div className="block-1-inner">
        <div className="products-parent">
          <h1 className="products">{`Products `}</h1>
          <div className="trendobg-offers-a">
            TrendO.bg offers a vast selection of over 90,000 items across fashion for men, women, and children, as well
            as home goods, and accessories.
          </div>
        </div>
      </div>
      <div className="e-commerce-brands-list-parent">
        <div className="e-commerce-brands-list">
          <div className="vuesaxlineardirect-up-wrapper3">
            <img className="vuesaxlineardirect-up-icon20" loading="lazy" alt="" src="/vuesaxlineardirectup.svg" />
          </div>
          <div className="try-relevafree">68.2%</div>
          <div className="click-rate-from-automation-wrapper">
            <div className="click-rate-from">Click rate from automation</div>
          </div>
        </div>
        <div className="frame-parent106">
          <div className="vuesaxlineardirect-up-parent2">
            <img className="vuesaxlineardirect-up-icon21" loading="lazy" alt="" />
            <div className="vuesaxlinearshopping-cart-container">
              <img
                className="vuesaxlinearshopping-cart-icon3"
                loading="lazy"
                alt=""
                src="/vuesaxlinearshoppingcart.svg"
              />
            </div>
            <div className="frame-parent107">
              <div className="boost-frame">
                <h1 className="boost3">{`Boost `}</h1>
              </div>
              <div className="e-commerce-growth2">e-commerce growth</div>
            </div>
          </div>
          <div className="communicationcenter1">
            <img className="promotionalbanners-icon" alt="" src="/vector.svg" />
            <img className="communicationcenter-child" loading="lazy" alt="" src="/frame-1324@2x.webp" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block2;
