import React from 'react';
import { Link } from 'react-router-dom';
import partnersListItems from '../../constants/partnersListItems';
import './PartnersList.css';

const PartnersList = () => {
  const shuffledConentLibraryItems = partnersListItems;

  return (
    <div className="caseStudiesContainer contentLibrary partnersList">
      {shuffledConentLibraryItems.map((item, index) => (
        <Link target="_blank" to={`${item.url}`} key={index}>
          <div className="frame-parent174">
            <img className="frame-child108" loading="lazy" src={item.image} alt={item.title} />
            <div className="momax-case-study1">{item.title}</div>
            <div className="conversion-rate-from5">{item.shortDescription}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PartnersList;
