import './FrameComponent24.css';

const FrameComponent24 = () => {
  return (
    <section className="overview-inner">
      <div className="frame-parent93">
        <div className="vector-parent9">
          <img className="vector-icon21" loading="lazy" alt="" src="/vector-61.svg" />
          <div className="cs-card-wrapper">
            <div className="cs-card1">CS Cart</div>
          </div>
        </div>
        <div className="frame-parent94">
          <div className="frame-wrapper36">
            <div className="vector-parent10">
              <img className="vector-icon22" alt="" src="/vector-71.svg" />
              <img className="vector-icon23" alt="" src="/vector-8.svg" />
              <img className="vector-icon24" alt="" src="/vector-91.svg" />
            </div>
          </div>
          <div className="shopify-container">
            <div className="shopify3">Shopify</div>
          </div>
        </div>
        <div className="input-provider">
          <div className="get-started5">Get started</div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent24;
