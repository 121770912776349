import React from 'react';
import './FeaturesSection.css';

const FeaturesSection = ({ title }) => {
  return (
    <section className="releva-ai-hub-inner">
      <div className="simple-powerful-tool-impres-parent">
        <img className="image-119-icon empowering" loading="lazy" alt="" src="/AIHub/Empowering.webp" />
        <div className="position-arranger">
          <div className="frame-parent15">
            <div className="value-combiner-wrapper">
              <div className="value-combiner">
                <div className="releva-goes-beyond">{title}</div>
                <div className="it-empowers-you-to-unlock-the-wrapper">
                  <div className="it-empowers-you">It empowers you to unlock the full potential of your eCommerce.</div>
                </div>
              </div>
            </div>
            <div className="features">
              <div className="web-search-multi-channel-pe">
                <div className="title4">
                  <div className="social-media">{`Omni Personalization`}</div>
                </div>
                <div className="symbol-library">
                  <div className="integrates-personalized-custom">
                    integrates personalized customer experiences across various platforms and search results, ensuring a
                    cohesive and tailored user journey that enhances engagement and conversion.
                  </div>
                </div>
              </div>
              <div className="mail-push-center">
                <div className="title2">
                  <div className="mail-push">{`Mail & Push Center`}</div>
                </div>
                <div className="is-designed-to-enable-seamless-wrapper">
                  <div className="is-designed-to">
                    is designed to enable seamless and personalized email and push notification campaigns, enhancing
                    customer engagement and conversion through targeted, AI-driven content.
                  </div>
                </div>
              </div>
              <div className="message-hub">
                <div className="title3">
                  <div className="message-hub1">Message Hub</div>
                </div>
                <div className="is-a-centralized-platform-desi-wrapper">
                  <div className="is-a-centralized">
                    is a centralized platform designed to streamline and enhance communication with customers through
                    personalized and targeted messaging, optimizing engagement and conversion rates.
                  </div>
                </div>
              </div>
              <div className="social-media-hub">
                <div className="title4">
                  <div className="social-media">{`Social Media`}</div>
                </div>
                <div className="enables-brands-to-extend-their-wrapper">
                  <div className="enables-brands-to">
                    enables brands to extend their personalized engagement strategies into social media and digital
                    advertising realms, ensuring a cohesive and customized marketing experience across all online
                    platforms.
                  </div>
                </div>
              </div>
              <div className="cdp-for-offline-shops-email">
                <div className="title4">
                  <div className="social-media">{`Customer & Product Platform`}</div>
                </div>
                <div className="integrates-seamlessly-with-ema-wrapper">
                  <div className="integrates-seamlessly-with">
                    integrates seamlessly with email and web push communications, enabling brick-and-mortar retailers to
                    leverage sophisticated data analysis for personalized customer engagement and marketing strategies
                    across online channels.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
