const socialMediaFeatures = [
  {
    feature: 'Campaigns',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Workflows',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Facebook',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Instagram',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Google*',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'TikTok*',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  // {
  //   feature: 'Customer segmentation ',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Pop-up and banners',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Post-Purchase',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Analytics',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
];

export default socialMediaFeatures;
