import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import './ChooseYourPlan.css';

const marks = {
  0: '0',
  30000: '30,000',
  200000: '200,000',
  300000: 'Unlimited',
};

const marksEmail = {
  0: '0',
  50000: '50,000',
  200000: '200,000',
  300000: 'Unlimited',
};

const ChooseYourPlan = () => {
  const [slider1Value, setSlider1Value] = useState(190000);
  const [slider2Value, setSlider2Value] = useState(190000);
  const [mauValue, setMauValue] = useState('30,000');
  const [emailsValue, setEmailValue] = useState('50,000');
  const [planAndTariff, setPlanAndTariff] = useState({
    planValue: 'Standard',
    tariffValue: '$249.99',
  });
  const handleRender = (props) => {
    const { value, style } = props;
    const minValue = marks[Object.keys(marks)[0]];
    let maxValue = marks[Object.keys(marks)[Object.keys(marks).length - 1]];

    // Check if value is NaN
    if (isNaN(value)) {
      return null;
    }

    if (!isFinite(maxValue)) {
      maxValue = 300000;
    }

    const range = maxValue - minValue;
    let leftPosition;

    if (value === maxValue) {
      leftPosition = '100%';
    } else {
      if (value == 'Infinity') {
        leftPosition = '100%';
      } else {
        leftPosition = ((value - minValue) / range) * 100 + '%';
      }
    }
    return (
      <div className="custom-handle" style={{ ...style, left: leftPosition }}>
        <div className="tooltip">{value.toLocaleString()}</div>
      </div>
    );
  };

  const handleSliderChange = (value, setValue, otherValue, setOtherValue) => {
    setValue(value);
    if (value <= 30000) {
      setOtherValue(value);
      setPlanAndTariff({ planValue: 'Growth', tariffValue: '$49.99' });
      setMauValue('30,000');
      setEmailValue('50,000');
    } else if (value <= 200000) {
      setOtherValue(200000);
      setPlanAndTariff({ planValue: 'Standard', tariffValue: '$249.99' });
      setMauValue('200,000');
      setEmailValue('200,000');
    } else {
      setOtherValue(Infinity);
      setPlanAndTariff({ planValue: 'Enterprise', tariffValue: '$649.99' });
      setMauValue('Unlimited');
      setEmailValue('Unlimited');
    }
  };

  const tipFormatter = (value) => {
    return value.toLocaleString();
  };

  return (
    <div className="choose-your-plan1">
      <div className="mau-slider1">
        <div className="title16">
          <h1 className="monthly-active-users11">Monthly Active Users</h1>
        </div>
        <div className="slider4">
          <Slider
            min={0}
            max={300000}
            step={10000}
            value={slider1Value}
            onChange={(value) => handleSliderChange(value, setSlider1Value, slider2Value, setSlider2Value)}
            tipFormatter={tipFormatter}
            marks={marks}
            handle={(props) => handleRender({ ...props, value: slider1Value })} // Pass slider1Value as the value prop
            handleRender={(props) => handleRender({ ...props, value: slider1Value })} // Pass slider1Value as the value prop
            ariaLabel={['Monthly Active Users']}
            ariaValuetext={(value) => `${value.toLocaleString()} Monthly Active Users`}
          />
        </div>
        <div className="selected-mau-emails1">
          <div className="monthly-active-users12">
            {mauValue} Monthly Active Users + {emailsValue} Emails
          </div>
        </div>
      </div>
      <div className="emails-slider1">
        <div className="title17">
          <h1 className="monthly-sent-emails1">Monthly Sent Emails</h1>
        </div>
        <div className="slider5">
          <Slider
            min={0}
            max={300000}
            step={10000}
            value={slider2Value}
            onChange={(value) => handleSliderChange(value, setSlider2Value, slider1Value, setSlider1Value)}
            tipFormatter={tipFormatter}
            marks={marksEmail}
            ariaLabel={['Monthly Sent Emails']}
            ariaValuetext={(value) => `${value.toLocaleString()} Monthly Sent Emails`}
            handle={(props) => handleRender({ ...props, value: slider2Value })} // Pass slider2Value as the value prop
            handleRender={(props) => handleRender({ ...props, value: slider2Value })} // Pass slider2Value as the value prop
          />
        </div>
        <div className="plan-price-according-to-sele1">
          <div className="growth-plan3">{planAndTariff.planValue} plan</div>
          <div className="base-tariff-4999-mau1">Base tariff {planAndTariff.tariffValue} + MAU usage (traffic)</div>
        </div>
      </div>
    </div>
  );
};

export default ChooseYourPlan;
