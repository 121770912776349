import './HowItWorks1.css';

const HowItWorks1 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img
          className="screenshot-2024-04-09-at-2028-icon"
          loading="lazy"
          alt=""
          src="/CustomerAndProductPage/omni2.webp"
        />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">02</div>
          <div className="master-your-generated">Merging all data in one profile:</div>
          <div className="edit-your-documents"></div>
          <div className="customize-your-generated">
            <ul>
              <li>Online shopping behaviour</li>
              <li>Offline shopping behaviour</li>
              <li>Optimal time</li>
              <li>AI profile enrichment</li>
              <li>Churn Probability</li>
              <li>Retargeting Prediction</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks1;
