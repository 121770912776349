import { useState } from 'react';
import { Helmet } from 'react-helmet';
import CaseStudiesList from '../components/CaseStudiesListing/CaseStudiesList';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudiesListing.css';

const CaseStudiesListing = () => {
  const [category, setCategory] = useState('All case studies');

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <div className="case-studies-content-for-each">
      <Helmet>
        <title>Releva AI | Case Study</title>
        <meta
          name="description"
          content="Explore Releva AI's case studies to see how our AI-driven solutions have transformed businesses. Discover real-world examples of enhanced customer engagement, increased conversions, and optimized marketing strategies. Learn from our success stories."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.caseStudiesPage)}</script>
      </Helmet>
      <section className="case-studies-title-parent">
        <h1 className="case-studies2">Case studies</h1>
      </section>
      <section className="case-studies-description-paren">
        <div className="discover-how-releva">
          Discover how Releva can empower you to unlock the full potential of your eCommerce
        </div>
      </section>
      <section className="sub-menu3">
        <div className="menu-items3">
          <div
            className={`all-case-studies-wrapper ${category === 'All case studies' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('All case studies')}
          >
            <div className="all-case-studies">All case studies</div>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Slow-Moving Consumer Goods' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Slow-Moving Consumer Goods')}
          >
            <div className="all-case-studies">Slow-Moving Consumer Goods</div>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Medium Turnover Goods' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Medium Turnover Goods')}
          >
            <div className="all-case-studies">Medium Turnover Goods</div>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Fast-Moving Consumer Goods' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Fast-Moving Consumer Goods')}
          >
            <div className="all-case-studies">Fast-Moving Consumer Goods</div>
          </div>
        </div>
      </section>
      <div className="case-studies-content-for-each-inner">
        <div className="frame-child14" />
      </div>
      <div className="slow-moving-title-parent">
        <h2 className="slow-moving-consumer-goods1">{category}</h2>
      </div>
      <CaseStudiesList category={category === 'All case studies' ? '' : category} />
    </div>
  );
};

export default CaseStudiesListing;
