import './ShopifyMerchantProButtons1.css';

const ShopifyMerchantProButtons = () => {
  return (
    <div className="shopify-merchantpro-buttons1">
      <h1 className="are-you-a1">Are you a Shopify or Merchant Pro user?</h1>
      <div className="learn-more-about1">Learn more about Releva’s app and pricing.</div>
      <div className="buttonsHolder">
        <div className="shopify-button1">
          <div className="button-name70">Shopify App</div>
        </div>
        <div className="merchant-pro-button1">
          <b className="button-name71">MerchantPro App</b>
        </div>
      </div>
      <img className="vector-icon41" loading="lazy" alt="" src="/vector5.svg" />
    </div>
  );
};

export default ShopifyMerchantProButtons;
