import { useMemo } from 'react';
import './TransformOneTimeBuyers.css';

const TransformOneTimeBuyers = ({
  icon01,
  transformOneTimeBuyersInt,
  unleashThePowerOfPostPurc,
  propWidth,
  propAlignSelf,
}) => {
  const frameDiv1Style = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const transformOneTimeBuyersStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div className="icon-01-parent" style={frameDiv1Style}>
      <img className="icon-01" loading="lazy" alt="" src={icon01} />
      <div className="transform">
        <h2 className="transform-one-time-buyers" style={transformOneTimeBuyersStyle}>
          {transformOneTimeBuyersInt}
        </h2>
        <div className="unleash-the-power">{unleashThePowerOfPostPurc}</div>
      </div>
    </div>
  );
};

export default TransformOneTimeBuyers;
