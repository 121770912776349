import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import ContentLibraryList from '../components/ContentLibraryListing/ContentLibraryList';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudiesListing.css';

const useQueryContent = () => {
  return new URLSearchParams(useLocation().search);
};

const ContentLibrary = () => {
  const query = useQueryContent();
  const itemUrl = query.get('type');
  const [category, setCategory] = useState('All');

  useEffect(() => {
    if (itemUrl) {
      const capitalizedItemUrl = itemUrl.charAt(0).toUpperCase() + itemUrl.slice(1);
      setCategory(capitalizedItemUrl);
    }
  }, [itemUrl]);

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <div className="case-studies-content-for-each">
      <Helmet>
        <title>Releva AI | Content Library</title>
        <meta
          name="description"
          content="Discover how Releva.ai leverages advanced AI to automate and optimize your e-commerce operations, driving growth, efficiency, and personalized customer experiences."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.contentLibraryPage)}</script>
      </Helmet>
      <section className="case-studies-title-parent">
        <h1 className="case-studies2">Content Library</h1>
      </section>
      <section className="case-studies-description-paren">
        <div className="discover-how-releva">Empowering Intelligent Automation for E-Commerce Success</div>
      </section>
      <section className="sub-menu3">
        <div className="menu-items3">
          <div
            className={`all-case-studies-wrapper ${category === 'All' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('All')}
          >
            <Link to="/content-library" className="all-case-studies">
              All
            </Link>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'News' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('News')}
          >
            <Link to="/content-library?type=news" className="all-case-studies">
              News
            </Link>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Blog' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Blog')}
          >
            <Link to="/content-library?type=blog" className="all-case-studies">
              Blog
            </Link>
          </div>
          <div
            className={`all-case-studies-wrapper ${category === 'Press Release' ? 'active' : ''}`}
            onClick={() => handleCategoryChange('Press Release')}
          >
            <Link to="/content-library?type=press%20Release" className="all-case-studies">
              Press Release
            </Link>
          </div>
        </div>
      </section>
      <div className="case-studies-content-for-each-inner">
        <div className="frame-child14" />
      </div>
      <div className="slow-moving-title-parent">
        <h2 className="slow-moving-consumer-goods1">{category}</h2>
      </div>
      <ContentLibraryList category={category === 'All' ? '' : category} />
    </div>
  );
};

export default ContentLibrary;
