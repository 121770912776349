import './FrameComponent31.css';

const FrameComponent31 = () => {
  return (
    <section className="frame-parent141" id="releva-vision">
      <div className="relevas-vision-for-the-future-wrapper">
        <div className="relevas-vision-for">Releva’s Vision for the Future</div>
      </div>
      <div className="at-releva-were-not-just-keep-wrapper">
        <div className="at-releva-were">
          At Releva, we're not just keeping pace with the future — we're creating it.
        </div>
      </div>
      <div className="elements1">
        <div className="text-1">
          <img className="icon-011" loading="lazy" alt="" src="/icon-01.svg" />
          <div className="text4">
            <div className="new-market-niche">New market niche</div>
            <div className="through-generative-personaliza-container">
              <p className="through-generative-personaliza">
                Through generative personalization, we boost revenue of eCommerce by 35% to 50%. We aim for closing the
                current session with purchase, rather than provide single step optimizations.
              </p>
              <p className="blank-line2">&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="text-2">
          <img className="icon-021" loading="lazy" alt="" src="/icon-02.svg" />
          <div className="text5">
            <div className="banner1">
              <div className="actions-based-on">Actions based on real data</div>
              <div className="we-use-real-time">
                We use real-time insights into users' buying potential, churn, optimal time for messages, next basket,
                worldview, social status, psychology, mood, and demographics. Releva looks at the entire funnel data
                rather than just email or website, leading to providing an exceptional, personalized user experience.
              </div>
            </div>
          </div>
        </div>
        <div className="text-3">
          <img className="icon-03" loading="lazy" alt="" src="/icon-03.svg" />
          <div className="text6">
            <div className="exceptional-experience-more">Exceptional experience, more sales</div>
            <div className="releva-leverages-10x">
              Releva leverages 10x more deep data and suggest the right products to the right people, resulting in
              higher conversion rates and more successful purchases. We generate persuasive, immersive, one-on-one
              experiences on web and multichannel by adapting on direct feedback from user interactions.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent31;
