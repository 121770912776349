import './HowItWorks1.css';

const HowItWorks3 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/MailAndPushPage/omni6.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">06</div>
          <div className="master-your-generated">Increase Engagement </div>
          <div className="edit-your-documents">with Tailored Promotions</div>
          <div className="customize-your-generated">
            Releva's Dynamic Coupons feature generates unique, single-use coupon codes for each customer, ensuring that
            no two recipients receive the same code. This personalized approach allows you to deliver specific discounts
            that enhance the relevance and attractiveness of your offers, increasing the likelihood of use and boosting
            the overall effectiveness of your marketing campaigns.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks3;
