import { Helmet } from 'react-helmet';
import Clock from '../components/DataDrivenMarketersPage/Clock';
import FrameComponent21 from '../components/DataDrivenMarketersPage/FrameComponent21';
import FrameComponent22 from '../components/DataDrivenMarketersPage/FrameComponent22';
import ShapeArray from '../components/DataDrivenMarketersPage/ShapeArray';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import Shapes from '../components/Shapes';
import Testimonials from '../components/Testimonials';
import pagesJsonLD from '../constants/pagesJsonLD';
import './DataDrivenMarketers.css';

const DataDrivenMarketers = () => {
  return (
    <div className="data-driven-marketers">
      <Helmet>
        <title>Releva AI | Data Driven Marketers</title>
        <meta
          name="description"
          content="Empower your marketing strategy with Releva AI. Harness the power of data-driven insights to optimize campaigns, personalize customer interactions, and maximize ROI. Explore our solutions for data-driven marketers today!"
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.dataDrivenMarketersPage)}</script>
      </Helmet>
      <HomePageHeroTitle
        heroTitle="Data-Driven Marketing, Your Path to Success"
        heroDescription="Releva - Your Ultimate Ecommerce Growth Partner. Unlock Hours, Revenue, and ROI."
      />
      <img
        loading="lazy"
        src="/DataDrivenMarketer/HeroImage.webp"
        alt="HeroImage"
        title="HeroImage"
        className="HomePageHeroImage"
      />
      <FrameComponent22 />
      <Testimonials />
      <section className="adjustments">
        <div className="properties">
          <div className="styling">
            <h1 className="your-data-driven-marketing">Your Data-Driven Marketing Revolution</h1>
          </div>
          <Shapes />
          <FrameComponent21 />
        </div>
      </section>
      <ShapeArray />
      <Clock />
    </div>
  );
};

export default DataDrivenMarketers;
