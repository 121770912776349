const dataPlatformFeatures = [
  {
    feature: '360 Customer Profiles (CDP)',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: '360 Product Profile (PDP)',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Import customers',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Customer segmentation *',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Events',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
];

export default dataPlatformFeatures;
