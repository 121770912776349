import './HowItWorks1.css';

const HowItWorks1 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/MessageHubPage/omni2.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">02</div>
          <div className="master-your-generated">Optimize Marketing Operations</div>
          <div className="edit-your-documents">with Automated Workflows</div>
          <div className="customize-your-generated">
            Simplify your marketing processes with Releva's Workflows, designed to automate and streamline tasks from
            customer acquisition to loyalty programs. This automation ensures consistent customer interactions and frees
            up your team to focus on strategic planning, enhancing productivity and reducing costs. The result is a more
            efficient operation that not only saves time but also drives customer satisfaction and business growth.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks1;
