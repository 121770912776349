import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../../components/HomePage/HomePageHeroTitle';
import FeatureTable from '../../components/PricingPage/FeatureTable';
import ShopifyMerchantProButtons1 from '../../components/PricingPage/ShopifyMerchantProButtons1';
import customerAndProductPlatformFeatures from '../../constants/customerAndProductPlatformFeatures';
import '../PricingPage.css';

const CustomerAndProductPlatformProduct = () => {
  return (
    <div className="pricing-page aihub-product">
      <Helmet>
        <title>Releva AI | Customer And Product Platform Product Plan</title>
        <meta
          name="description"
          content="Seamlessly integrate with Releva AI's to optimize your e-commerce operations. Discover how our advanced integration solutions enhance your online store's performance, streamline processes, and drive growth."
        />
      </Helmet>
      <main className="background">
        <HomePageHeroTitle
          heroTitle="Trust Your Decisions, Drive Success"
          heroDescription="Maximize the Value of Your Business and Grow Your eCommerce."
        />
        <div className="releva-pricing-plans2">
          <h1 className="releva-pricing-plans3">Use Releva Customer And Product Platform</h1>
        </div>
        <ShopifyMerchantProButtons1 />
        <form className="compare-plans2">
          <FeatureTable
            growth={false}
            enterprise={false}
            data={customerAndProductPlatformFeatures}
            title="Customer And Product Platform <sup>*new</sup>"
          />
        </form>
      </main>
    </div>
  );
};

export default CustomerAndProductPlatformProduct;
