const mailAndPushFeatures = [
  {
    feature: 'Emails',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Web Push',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Banners',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Dynamic Coupons',
    //startup: true,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Campaigns & Workflows',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Webhooks',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  // {
  //   feature: 'Customer segmentation ',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Pop-up and banners',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Post-Purchase',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  // {
  //   feature: 'Analytics',
  //   //startup: false,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
];

export default mailAndPushFeatures;
