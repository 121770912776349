const plansData = [
  // {
  //   title: 'StartUp',
  //   emailsIncluded: '10 000',
  //   mauIncludedLabel: 'up to',
  //   mauIncluded: '10 000',
  //   daysFreeTrial: '30',
  //   baseTariff: '$7.99',
  //   mauUsageTraffic: 'MAU usage (traffic)',
  //   monthlyActiveUsers: 'Monthly Active Users',
  // },
  {
    title: 'Enterprise',
    emailsIncluded: 'unlimited',
    mauIncludedLabel: 'more than',
    mauIncluded: '201 000',
    daysFreeTrial: '30',
    baseTariff: '$649.99 +',
    mauUsageTraffic: '$24 per 1000 UMAU*',
    annualMauUsageTraffic: '$18 per 1000 UMAU*',
    monthlyActiveUsers: 'Monthly Active Users',
  },
  {
    title: 'Standard',
    recommended: true,
    emailsIncluded: '200 000',
    mauIncludedLabel: 'up to',
    mauIncluded: '200 000',
    daysFreeTrial: '30',
    baseTariff: '$249.99 +',
    mauUsageTraffic: '$24 per 1000 UMAU*',
    annualMauUsageTraffic: '$18 per 1000 UMAU*',
    monthlyActiveUsers: 'Monthly Active Users',
  },
  {
    title: 'Growth',
    emailsIncluded: '50 000',
    mauIncludedLabel: 'up to',
    mauIncluded: '30 000',
    daysFreeTrial: '30',
    baseTariff: '$49.99 +',
    mauUsageTraffic: '$24 per 1000 UMAU*',
    annualMauUsageTraffic: '$18 per 1000 UMAU*',
    monthlyActiveUsers: 'Monthly Active Users',
  },
];

export default plansData;
