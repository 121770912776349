import TrustedCustomers from "./TrustedCustomers";
import "./ValueComparator.css";

const ValueComparator = () => {
  return (
    <section className="value-comparator1">
      <div className="customers">
        <TrustedCustomers
          block8AlignSelf="unset"
          block8Padding="var(--padding-18xl) var(--padding-56xl) var(--padding-32xl-7)"
          block8Flex="1"
          trustedBy400Margin="0"
        />
      </div>
    </section>
  );
};

export default ValueComparator;
