import { Helmet } from 'react-helmet';
import SubMenu from '../components/CookiePolicy/SubMenu';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CookiePolicy.css';

const TermsOfUse = () => {
  return (
    <div className="cookie-policy3">
      <Helmet>
        <title>Releva AI | Terms of Use</title>
        <meta
          name="description"
          content="Review the Terms of Use for Releva AI to understand the guidelines and policies governing your use of our services. Ensure a clear understanding of your rights and responsibilities while using our AI-driven marketing solutions."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.termsOfUsePage)}</script>
      </Helmet>
      <section className="cookie-policy-container">
        <h1 className="cookie-policy4">Terms Of Use</h1>
      </section>
      <SubMenu active="terms-of-use" />
      <section className="at-releva-we-respect-individua-wrapper">
        <div className="at-releva-we-container">
          <p className="these-terms-of-use-define-the">
            <span className="these-terms-of-use-define-the1">
              <span className="these-terms-of">
                These Terms of Use define the legal framework for the use of the Releva – Marketing Automation Platform.
              </span>
            </span>
          </p>
          <p className="definition">
            <span className="definition1">
              <b className="definition2">1. Definition</b>
            </span>
          </p>
          <ul className="releva-user-experience-and-m">
            <li className="releva-user-experience-and-m1">
              <span className="releva-user-experience-and-m2">
                <span className="releva">Releva</span>
                <span className="user-experience-and"> – User Experience and Marketing Automation Software</span>
              </span>
            </li>
            <li className="user-you-provided-you-have">
              <span className="user-you-provided-you-have1">
                <span className="user">User</span>
                <span className="you-provided-you">
                   – you, provided you have the legal capacity to form a legal contract (and are an adult), or are a
                  minor over the age of 16 and have accepted these Terms of Use.
                </span>
              </span>
            </li>
            <li className="users-account-an-account-en">
              <span className="users-account-an-account-en1">
                <span className="users-account">User’s Account </span>
                <span className="an-account-enabling">
                  – an account enabling the use of the Service, understood as the use of a fully paid package.
                </span>
              </span>
            </li>
            <li className="user-profile-an-arrangement">
              <span className="user-profile-an-arrangement1">
                <span className="user-profile">User profile </span>
                <span className="an-arrangement-that">
                  – an arrangement that can store information, made available by the Operator within the ICT system,
                  that enables the User to enter, store and modify data necessary for proper usage of the features of
                  Releva. This information is provided to Releva voluntarily and solely by the User.
                </span>
              </span>
            </li>
            <li className="monthly-active-users-mau-a">
              <span className="monthly-active-users-mau-a1">
                <span className="monthly-active-users">Monthly active users (MAU)</span>
                <span className="are-unique-end">
                   – are unique end users who have interacted with the online store of the User in the last 30 days. 
                  For the pricing purposes they are taken from the “Account usage” panel of Releva.
                </span>
              </span>
            </li>
            <li className="gdpr-or-data-protection-legis">
              <span className="gdpr-or-data-protection-legis1">
                <span className="gdpr-or-data">GDPR or “Data Protection Legislation”</span>
                <span className="means-regulation-eu">
                   means Regulation (EU) 2016/679 (the “GDPR”) and any national implementing legislation; as amended or
                  replaced from time to time or, in the absence of such laws, all legislation, regulation, and mandatory
                  guidance or mandatory codes of practice. 
                </span>
              </span>
            </li>
            <li className="services-targeting-and-retar">
              <span className="services-targeting-and-retar1">
                <span className="services">{`Services – `}</span>
                <span className="targeting-and-retargeting">
                  targeting and retargeting of end clients of the User’s online store through multichannel: placing
                  product blocks with automatizations and personalizations on the User’s website, automation of
                  personalised banners and pop-ups, personalised emails, sending newsletters with personalised content,
                  automation of campaigns based on triggered events to segmented audience and performance/results
                  monitoring of the services by channel.
                </span>
              </span>
            </li>
            <li className="website-www-page-a-documen">
              <span className="website-www-page-a-documen1">
                <span className="website">Website</span>
                <span className="www-page-a">
                   – (www page) a document developed in one of SGML language types (HTML, XML, PHP) downloaded from an
                  Internet server and interpreted by the User by means of a browser (one www. address).
                </span>
              </span>
            </li>
            <li className="the-operator-releva-plc-ba">
              <span className="the-operator-releva-plc-ba1">
                <span className="the-operator">The Operator </span>
                <span className="releva-plc-based">
                  – Releva Plc., based in EU, Sofia, Lozenets, Harambi Tachev str 18, registered under the Commercial
                  Register of Bulgaria under the identification number 207561080.
                </span>
              </span>
            </li>
          </ul>
          <p className="declarations-of-the-operator-a">
            <b className="declarations-of-the">2. Declarations of the Operator and the System User</b>
          </p>
          <p className="the-operator-confirms-that-he">
            <span className="the-operator-confirms">
              The Operator confirms that he is in possession of all and any technical means and infrastructure required
              for cooperation under these Terms of Use for all users of Releva.
            </span>
          </p>
          <p className="users-of-the-releva-declare-th">
            <span className="users-of-the">
              Users of the Releva declare that they will not use Releva in a way that could constitute violation of
              generally accepted norms and rules of social coexistence.
            </span>
          </p>
          <p className="operators-rights-and-obligati">
            <b>
              <span className="operators-rights-and">3. Operator’s rights and obligations</span>
            </b>
          </p>

          <p className="the-operator-shall-have-the-fo">
            <span>
              <b>
                <span className="the-operator-shall">The Operator shall have the following rights:</span>
              </b>
            </span>
          </p>
          <ol className="the-operator-shall-have-the-ri">
            <li className="the-operator-shall-have-the-ri1">
              <span>
                <span>
                  The Operator shall have the right to block access to the account of any user infringing any provisions
                  contained herein as well as to terminate the agreement concluded by accepting these Terms of Use.
                </span>
              </span>
            </li>
            <li className="the-operator-shall-have-the-ri2">
              <span>
                <span>
                  The Operator shall have the right to block access to the account of any user violating generally
                  accepted norms and rules governing the use of services of this kind.
                </span>
              </span>
            </li>
            <li className="the-operator-has-the-right-to">
              <span>
                <span>
                  The Operator has the right to receive the agreed remuneration under the terms and conditions of this
                  Agreement.
                </span>
              </span>
            </li>
            <li className="the-operator-has-the-right-on">
              <span>
                <span>
                  The Operator has the right on his own or through a third contracting party certified by the Operator
                  to request from the User the necessary assistance for the performance of the service and the
                  integration.
                </span>
              </span>
            </li>
            <li className="the-operator-has-the-right-to1">
              <span>
                <span>
                  The Operator has the right to refuse the provision of certain services if their content contradicts
                  the requirements of Bulgarian law or good manners or indicates a clear violation of the rights of
                  third parties.
                </span>
              </span>
            </li>
            <li className="the-operator-has-the-right-to2">
              <span>
                <span>
                  The Operator has the right to share about the partnership with the User, including, but not limited,
                  within the web space, presentations, conferences.
                </span>
              </span>
            </li>
          </ol>
          <p className="the-operator-shall-have-the-fo1">
            <span>
              <b className="the-operator-shall1">The Operator shall have the following obligations:</b>
            </span>
          </p>
          <ol className="the-operator-will-make-every-e">
            <li className="the-operator-will-make-every-e1">
              <span>
                <span className="the-operator-will">
                  The Operator will make every effort to ensure the highest possible standard of services available
                  within the Releva System.
                </span>
              </span>
            </li>
            <li className="the-operator-integrates-the-se">
              <span>
                <span className="the-operator-integrates">
                  The Operator integrates the service free of charge in case the integration is to the platform that is
                  among officially supported for integration by the Operator. In cases where the integration is to a
                  platform that is outside of officially supported by the Operator or a modification of the relevant
                  integration plugin is required, the User may partially or fully delegate the integration to be carried
                  out by the Operator. The scope, the terms and the price of this integration are annexed and made an
                  integral part of the Agreement.
                </span>
              </span>
            </li>
            <li className="to-perform-the-service-qualita">
              <span>
                <span className="to-perform-the">
                  To perform the service qualitatively and within the agreed terms, in full compliance with what is
                  agreed upon in this contract.
                </span>
              </span>
            </li>
            <li className="the-operator-is-obliged-to-pro">
              <span>
                <span className="the-operator-is">
                  The Operator is obliged to provide technical support within the working hours of the User (excluding
                  public holidays and weekends), up to 2 working hours per week.
                </span>
              </span>
            </li>
            <li className="in-the-event-of-a-technical-pr">
              <span>
                <span className="in-the-event">
                  In the event of a technical problem that has a global impact on the operation of the product and leads
                  to a reduction in its operability, the Operator will act immediately and notify the User of the scope
                  of the problem and the actions taken to solve it.
                </span>
              </span>
            </li>
            <li className="in-case-of-a-technical-problem">
              <span>
                <span className="in-case-of">
                  In case of a technical problem that has no global impact on the operation of the product and leads to
                  a decrease in its operability, but the User can perform its work by other means, the Operator
                  undertakes to respond within 1 working day and eliminate the problem within 5 working days.
                </span>
              </span>
            </li>
            <li className="the-operator-undertakes-to-pro">
              <span>
                <span className="the-operator-undertakes">
                  The Operator undertakes to provide the necessary assistance in the integration of the User.
                </span>
              </span>
            </li>
            <li className="in-the-event-that-the-integrat">
              <span>
                <span className="in-the-event1">
                  In the event that the integration of the User will be carried out to a platform that is not officially
                  supported by the Operator, the Operator undertakes to train the technical team of the User free of
                  charge in order to achieve the goal of the necessary connectivity between the systems of the User and
                  the Operator.
                </span>
              </span>
            </li>
            <li className="the-operator-undertakes-to-pro1">
              <span>
                <span className="the-operator-undertakes1">
                  The Operator undertakes to provide the User, on his own or through a third contracting party certified
                  by Operator, with free initial marketing training within the test period, as well as subsequent
                  consultations to ensure optimal operation of the platform.
                </span>
              </span>
            </li>
            <li className="the-operator-undertakes-to-kee">
              <span>
                <span className="the-operator-undertakes2">
                  The Operator undertakes to keep confidential all materials, data and information that he will receive
                  during the integration activities, test period and training, his work with the User and has no right
                  to their official or unofficial distribution, without the prior written consent of the User.
                </span>
              </span>
            </li>
            <li className="to-perform-its-obligations-und">
              <span>
                <span className="to-perform-its">
                  To perform its obligations under this Agreement with the care of a good merchant.
                </span>
              </span>
            </li>
          </ol>
          <p className="acceptance-of-the-terms-of-use">
            <b>
              <span className="acceptance-of-the">4. Acceptance of the Terms of Use</span>
            </b>
          </p>
          <p className="user-acceptance-of-terms-and-c">
            <span>
              <b>
                <span className="user-acceptance-of">
                  User acceptance of terms and conditions are required for the use of the system. 
                </span>
              </b>
            </span>
          </p>
          <p className="by-accepting-these-terms-of-us">
            <span>
              <span className="by-accepting-these">By accepting these Terms of Use, the User acknowledges that: </span>
            </span>
          </p>
          <ol className="all-details-entered-in-the-con">
            <li className="all-details-entered-in-the-con1">
              <span>
                <span className="all-details-entered">
                  all details entered in the Contract are complete, true and correct,
                </span>
              </span>
            </li>
            <li className="he-gives-consent-for-processin">
              <span>
                <span className="he-gives-consent">
                  he gives consent for processing by the Operator of personal data concerning the User, the User’s
                  Clients, his contractors, persons visiting the website. You will entrust the following data for
                  processing: name and surname, e-mail address, telephone number, address, financial information
                  (invoice data, purchase history);
                </span>
              </span>
            </li>
            <li className="he-has-agreed-to-receive-vat-i">
              <span>
                <span className="he-has-agreed">
                  he has agreed to receive VAT invoices generated by the service provider in an electronic format, via
                  email.
                </span>
              </span>
            </li>
            <li className="allows-the-operator-to-use-per">
              <span>
                <span className="allows-the-operator">
                  allows the Operator to use personal data only for the purposes of this Agreement, in accordance with
                  the provisions of the GDPR and the Personal Data Protection Act. For the avoidance of doubt,
                  acceptance of these Terms and Conditions and use of the Service will be treated as entrusting data for
                  processing in accordance with the provisions of the GDPR and the Act on Personal Data Protection.
                  Moreover, the Customers who have a paid subscription to the Releva System together with the conclusion
                  of the main Agreement also conclude the Agreement of entrusting the processing of personal data.
                </span>
              </span>
            </li>
          </ol>
          <p className="agreement-termination">
            <b>
              <span className="agreement-termination1">5. Agreement termination</span>
            </b>
          </p>
          <p className="the-contract-shall-be-terminat">
            <span>
              <b>
                <span className="the-contract-shall">
                  The contract shall be terminated under the following conditions:
                </span>
              </b>
            </span>
          </p>
          <ol className="at-the-request-of-the-user-aft">
            <li className="at-the-request-of-the-user-aft1">
              <span>
                <span className="at-the-request">
                  At the request of the User after the expiration of the trial period.
                </span>
              </span>
            </li>
            <li className="by-mutual-agreement-of-the-par">
              <span>
                <span className="by-mutual-agreement">
                  By mutual agreement of the Parties – expressed in writing with one month’s notice.
                </span>
              </span>
            </li>
            <li className="in-case-of-culpable-non-fulfil">
              <span>
                <span className="in-case-of1">
                  In case of culpable non-fulfillment of the obligations more than 30 / thirty / days of one of the
                  parties of the Agreement with 7 / seven / days written notice from the affected to the culpable party.
                </span>
              </span>
            </li>
            <li className="if-the-integration-is-not-star">
              <span>
                <span className="if-the-integration">
                  If the integration is not started due to the fault of the User, without notifying the other party that
                  he needs an extension of the integration period, the Operator has the right to terminate the contract
                  and vice versa, without penalty.
                </span>
              </span>
            </li>
            <li className="unilaterally-without-notice-f">
              <span>
                <span className="unilaterally-without-notice">
                  Unilaterally, without notice from either party, in the event of insolvency or liquidation proceedings
                  against the other party.
                </span>
              </span>
            </li>
            <li className="conditionals-bilaterally-agree">
              <span>
                <span className="conditionals-bilaterally-agree1">
                  Conditionals bilaterally agreed in bilateral Agreement between the parties.
                </span>
              </span>
            </li>
          </ol>
          <p className="complaints-procedure">
            <b className="complaints-procedure1">6. Complaints procedure</b>
          </p>
          <p className="user-complaints-regarding-the">
            <span className="user-complaints-regarding">
              User complaints regarding the Operator’s failure to perform provisions of the Agreement or his undue
              performance of any contractual provisions shall be sent to the Operator electronically at the following
              address: info@releva.ai and should contain in particular:
            </span>
          </p>
          <ol className="users-contact-details-reason">
            <li className="users-contact-details">
              <span className="users-contact-details1">User’s contact details,</span>
            </li>
            <li className="reasons-for-submitting-the-com">
              <span className="reasons-for-submitting">reasons for submitting the complaint,</span>
            </li>
            <li className="a-description-of-circumstances">
              <span className="a-description-of">
                a description of circumstances forming the basis of the User’s complaint.
              </span>
            </li>
          </ol>
          <p className="users-obligations">
            <b>
              <span className="users-obligations1">7. User’s obligations</span>
            </b>
          </p>
          <p className="the-user-shall-be-obligated-to">
            <span>
              <b>
                <span className="the-user-shall">The User shall be obligated to:</span>
              </b>
            </span>
          </p>
          <ol className="notify-the-operator-of-any-cha">
            <li className="notify-the-operator-of-any-cha1">
              <span>
                <span>
                  notify the Operator of any changes to data required for billing and other settlements subject to
                  effective delivery of any relevant e-mail messages to a non-updated address,
                </span>
              </span>
            </li>
            <li className="to-provide-assistance-to-the-o">
              <span>
                <span>
                  To provide assistance to the Operator in order to carry out technical connectivity of the service
                  qualitatively in full compliance with the provisions of this Agreement.
                </span>
              </span>
            </li>
            <li className="to-assist-the-operator-in-prov">
              <span>
                <span>
                  To assist the Operator in providing, on his own or through a third contracting party certified by
                  Operator, free initial marketing training within the test period, as well as subsequent consultations
                  to ensure optimal operation of the platform. If the User does NOT use the training hours due to his
                  fault, without this having been communicated to the Operator within the free test period, the Operator
                  has the right to issue an invoice based on the usage.
                </span>
              </span>
            </li>
            <li className="to-pay-the-agreement-price-agr">
              <span>
                <span>To pay the Agreement price agreed upon in this contract.</span>
              </span>
            </li>
            <li className="not-to-obstruct-the-operator-a">
              <span>
                <span>
                  Not to obstruct the Operator and not to violate its operational independence in connection with the
                  performance of the Contract.
                </span>
              </span>
            </li>
            <li className="to-train-its-technical-team-in">
              <span>
                <span>
                  To train its technical team in order to achieve the goal of the necessary connectivity between the
                  systems of the User and the Operator, in the event that the integration by the User will be carried
                  out to a platform that is not officially supported by the Operator.
                </span>
              </span>
            </li>
            <li className="to-keep-confidential-all-mater">
              <span>
                <span>
                  To keep confidential all materials, data and information that he will receive during the integration
                  activities, test period and training, his work with the Operator and has no right to their official or
                  unofficial distribution, without the prior written consent of the Operator.
                </span>
              </span>
            </li>
            <li className="observe-these-terms-of-use">
              <span>
                <span>observe these Terms of Use,</span>
              </span>
            </li>
            <li className="observe-generally-applicable-l">
              <span>
                <span>observe generally applicable legal provisions,</span>
              </span>
            </li>
            <li className="observe-any-rights-of-third-pa">
              <span>
                <span>observe any rights of third parties.</span>
              </span>
            </li>
          </ol>
          <p className="the-user-has-the-right">
            <span>
              <b className="the-user-has">The User has the right:</b>
            </span>
          </p>
          <ol className="to-apply-ongoing-control-over">
            <li className="to-apply-ongoing-control-over1">
              <span>
                <span className="to-apply-ongoing">
                  To apply ongoing control over the performance of all actions aiming the implementation of the
                  Agreement.
                </span>
              </span>
            </li>
            <li className="to-require-from-the-operator-t">
              <span>
                <span className="to-require-from">
                  To require from the Operator the performance of the assigned service in time, without deviation from
                  the agreed upon.
                </span>
              </span>
            </li>
            <li className="to-require-from-the-operator-t1">
              <span>
                <span className="to-require-from1">
                  To require from the Operator the correction of all defects in the construction and elimination of all
                  discrepancies with the parameters of the Operator’s offer, established until the expiration of the
                  agreed term under the terms of the Agreement.
                </span>
              </span>
            </li>
          </ol>
          <p className="technical-and-content-related">
            <b>
              <span className="technical-and-content-related1">8. Technical and content-related limitations</span>
            </b>
          </p>
          <p className="the-operator-reserves-the-righ">
            <span>
              <b>
                <span className="the-operator-reserves">The Operator reserves the right to:</span>
              </b>
            </span>
          </p>
          <ol className="periodically-disrupt-the-avail">
            <li className="periodically-disrupt-the-avail1">
              <span>
                <span>
                  periodically disrupt the availability of the Service for the purpose of its expansion or maintenance,
                </span>
              </span>
            </li>
            <li className="immediately-terminate-the-prov">
              <span>
                <span>immediately terminate the provision of services should the User breach these Terms of Use,</span>
              </span>
            </li>
            <li className="change-account-or-user-profile">
              <span>
                <span>change Account or User Profile parameters, including Service features and functionalities,</span>
              </span>
            </li>
            <li className="terminate-the-provision-of-ser">
              <span>
                <span>
                  terminate the provision of services at any time, by closing the entire Service or any of its parts,
                  subject to prior placement of relevant notification on the Service website.
                </span>
              </span>
            </li>
          </ol>
          <p className="the-operator-excludes-his-liab">
            <span>
              <span className="the-operator-excludes">The Operator excludes his liability for:</span>
            </span>
          </p>
          <ol className="any-damage-related-to-third-pa">
            <li className="any-damage-related-to-third-pa1">
              <span>
                <span className="any-damage-related">
                  any damage related to third party interference, faulty operation of external factors or other systems
                  (e.g. telecommunications grids) beyond the Operator’s control,
                </span>
              </span>
            </li>
            <li className="any-damage-resulting-from-the">
              <span>
                <span className="any-damage-resulting">any damage resulting from the occurrence of force majeure,</span>
              </span>
            </li>
            <li className="any-damage-resulting-from-unau">
              <span>
                <span className="any-damage-resulting1">
                  any damage resulting from unauthorized access to the User’s Account or Profile resulting from your
                  negligence,
                </span>
              </span>
            </li>
            <li className="any-damage-resulting-from-the1">
              <span>
                <span className="any-damage-resulting2">
                  any damage resulting from the User’s failure to observe these Terms of Use,
                </span>
              </span>
            </li>
            <li className="any-damage-resulting-from-inco">
              <span>
                <span className="any-damage-resulting3">
                  any damage resulting from incorrect transfer of data within the system, incorrect record or receipt of
                  messages as well as any loss of data contained in transferred messages.
                </span>
              </span>
            </li>
            <li className="damage-to-the-user-resulting-f">
              <span>
                <span className="damage-to-the">
                  damage to the User resulting from risk factors characteristic of the Internet, including system
                  attacks or the User’s system becoming infected with malicious software.
                </span>
              </span>
            </li>
          </ol>
          <p className="the-operator-makes-every-effor">
            <span>
              <span className="the-operator-makes">
                The Operator makes every effort to ensure that the usage of services is available for Users of all most
                popular web browsers, operational systems, types of computers and types of Internet connections.
                However, the Operator does not guarantee and is not responsible for the successful usage of each
                possible combination of these options and/or Releva system. The minimum technical requirements
                (notwithstanding any information provided in the previous sentence) which enable using services and/or
                Releva system are as follows: access to the Internet, modern web browser which supports HTML5, CSS3 and
                JavaScript (suggested web browsers: Chrome 74, Firefox 65, Edge 44 and the newest versions of the all
                mentioned here).
              </span>
            </span>
          </p>
          <p className="intellectual-property-rights">
            <b className="intellectual-property-rights1">9. Intellectual property rights</b>
          </p>
          <p className="all-intellectual-property-righ">
            <span className="all-intellectual-property">
              All intellectual property rights are held by the Operator, whether used in the performance of obligations
              under the Agreement or otherwise, remain with the Operator, and the Contracting Authority will not acquire
              any right or interest in such intellectual property rights.
            </span>
          </p>
          <p className="confidentiality-and-personal-d">
            <b className="confidentiality-and-personal">10. Confidentiality and personal data</b>
          </p>
          <ol className="this-paragraph-contains-genera">
            <li className="this-paragraph-contains-genera1">
              <span className="this-paragraph-contains-genera2">
                <span className="this-paragraph-contains">
                  This paragraph contains general information concerning the use and processing of Users’ personal data
                  by the Operator and personal data of the Clients of the Users which are provided to the Operator.
                </span>
              </span>
            </li>
            <li className="the-user-account-database-is-s">
              <span className="the-user-account-database-is-s1">
                <span className="the-user-account">The User Account database is subject to legal protection.</span>
              </span>
            </li>
            <li className="the-operator-shall-act-as-cont">
              <span className="the-operator-shall-act-as-cont1">
                <span className="the-operator-shall2">
                  The Operator shall act as Controller of the personal data provided by the User when registering an
                  Account in the System and the processing thereof is necessary for the User to use the System.
                </span>
              </span>
            </li>
            <li className="for-the-personal-data-of-perso">
              <span className="for-the-personal-data-of-perso1">
                <span className="for-the-personal">
                  For the personal data of persons which are in the database and who the User has entered into the
                  System – the Operator is the Processor of such personal data, and the User is the Controller of such
                  personal data or the Processor, as defined by GDPR. These personal data is provided to the Operator by
                  or on behalf of the User; and/or obtained by or created by Operator on behalf of the User in the
                  course of providing the Services. The User is fully responsible in case of lack of legal basis for
                  processing of personal data entered into the System or if the processing of such data is contrary to
                  the purpose for which they were obtained or in violation of the minimization principle resulting from
                  Article 5.1 (c) of the GDPR. The provisions of point 11 of this paragraph shall apply to such personal
                  data.
                </span>
              </span>
            </li>
            <li className="the-operator-as-the-controller">
              <span className="the-operator-as-the-controller1">
                <span className="the-operator-as">
                  The Operator as the Controller within the meaning of Article 4(7) of the GDPR declares that it
                  processes Users’ personal data in accordance with the provisions of the Data Protection Legislation.
                  If you have any questions regarding the processing of Your personal data and Your rights, please
                  contact us – our Data Protection Officer (DPO) – by e-mail at the address: info@releva.ai.
                </span>
              </span>
            </li>
            <li className="users-rights">
              <span className="users-rights1">
                <span className="users-rights2">User’s Rights: </span>
              </span>
            </li>
          </ol>
          <p className="you-have-the-right-to-withdraw">
            <span className="you-have-the-right-to-withdraw1">
              <span className="you-have-the">
                6.1. You have the right to withdraw every consent You have given at the time of the subscription to the
                Service as well as during the use of particular services and functionalities offered on the Service.
                Withdrawal of consent has effect from the moment of withdrawal. Withdrawal of your consent does not
                affect the processing we carry out in accordance with the law before its withdrawal. Withdrawal of
                consent does not entail any negative consequences for You. However, it may prevent You from continuing
                to use the Services or functionalities that we may lawfully provide only with Your consent.
              </span>
            </span>
          </p>
          <p className="you-have-the-right-at-any-time">
            <span className="you-have-the-right-at-any-time1">
              <span className="you-have-the1">
                6.2. You have the right at any time to object to the use of Your personal data, including profiling, if
                we process Your data based on our legitimate interest, e.g. in connection with the marketing of our
                products and services and our customers, keeping statistics on the use of particular functionalities of
                the Services and facilitating the use of the Services, as well as satisfaction surveys.
              </span>
            </span>
          </p>
          <p className="if-you-do-not-receive-any-mark">
            <span className="if-you-do-not-receive-any-mark1">
              <span className="if-you-do">
                If You do not receive any marketing communications about our products or services or our customers, we
                will object to the processing of your personal data, including profiling for these purposes. If Your
                objection proves justified and we have no other legal basis for processing your personal data, we will
                erase the data you have objected to.
              </span>
            </span>
          </p>
          <p className="legal-basis-article-17-gdpr">
            <span className="legal-basis-article-17-gdpr1">
              <span className="legal-basis-article">6.3. Legal basis: Article 17 GDPR</span>
            </span>
          </p>
          <p className="right-to-erasure-right-to-be">
            <span className="right-to-erasure-right-to-be1">
              <span className="right-to-erasure">Right to erasure („right to be forgotten”)</span>
            </span>
          </p>
          <p className="you-have-the-right-to-request">
            <span className="you-have-the-right-to-request1">
              <span className="you-have-the2">
                You have the right to request the erasure of all or some personal data. We will treat the request to
                erase all or some of your personal data as a request to erase Your Account. You have the right to
                request the erasure of Your personal data if:
              </span>
            </span>
          </p>
          <ul className="you-have-withdrawn-your-specif">
            <li className="you-have-withdrawn-your-specif1">
              <span className="you-have-withdrawn-your-specif2">
                <span className="you-have-withdrawn">
                  You have withdrawn Your specific consent to the extent that Your personal data has been processed
                  based on Your consent;
                </span>
              </span>
            </li>
            <li className="your-personal-data-is-no-longe">
              <span className="your-personal-data-is-no-longe1">
                <span className="your-personal-data">
                  Your personal data is no longer necessary for the purposes for which it was collected or processed;
                </span>
              </span>
            </li>
            <li className="you-have-objected-to-the-use-o">
              <span className="you-have-objected-to-the-use-o1">
                <span className="you-have-objected">
                  You have objected to the use of Your data for marketing purposes;
                </span>
              </span>
            </li>
            <li className="you-have-objected-to-the-use-o2">
              <span className="you-have-objected-to-the-use-o3">
                <span className="you-have-objected1">
                  You have objected to the use of Your data for the purpose of maintaining statistics on the use of the
                  Service and satisfaction surveys, and the objection has been deemed justified;
                </span>
              </span>
            </li>
            <li className="your-personal-data-is-processe">
              <span className="your-personal-data-is-processe1">
                <span className="your-personal-data1">Your personal data is processed illegally.</span>
              </span>
            </li>
          </ul>
          <p className="although-we-may-request-the-er">
            <span className="although-we-may-request-the-er1">
              <span className="although-we-may">
                Although we may request the erasure of personal data in connection with raising an objection or
                withdrawing consent, we may retain certain personal data to the extent necessary to establish, pursue or
                defend a claim. This includes, but is not limited to, personal information such as name, surname, email
                address and application history, which we retain for the purpose of investigating complaints and claims
                related to the use of our services.
              </span>
            </span>
          </p>
          <p className="legal-basis-article-18-gdpr">
            <span className="legal-basis-article-18-gdpr1">
              <span className="legal-basis-article1">6.4. Legal basis: Article 18 GDPR</span>
            </span>
          </p>
          <p className="right-to-restriction-of-proces">
            <span className="right-to-restriction-of-proces1">
              <span className="right-to-restriction">Right to restriction of processing of personal data</span>
            </span>
          </p>
          <p className="you-have-the-right-to-request2">
            <span className="you-have-the-right-to-request3">
              <span className="you-have-the3">
                You have the right to request a restriction of the processing of Your personal data. If You make such a
                request, we will, until such request is processed, prevent You from using certain functionalities or
                services that will involve the processing of the data requested. We will not send you any messages,
                including marketing messages. You have the right to request a restriction on the use of Your personal
                data in the following cases:
              </span>
            </span>
          </p>
          <ul className="if-you-question-the-accuracy-o">
            <li className="if-you-question-the-accuracy-o1">
              <span className="if-you-question-the-accuracy-o2">
                <span className="if-you-question">
                  if you question the accuracy of Your personal data – then we will limit the use of your data for the
                  time we need to verify the accuracy of Your data, but no longer than for 7 days;
                </span>
              </span>
            </li>
            <li className="when-the-processing-of-your-da">
              <span className="when-the-processing-of-your-da1">
                <span className="when-the-processing">
                  when the processing of Your data is against the law, and instead of erasure Your data, You will demand
                  a restriction on its use;
                </span>
              </span>
            </li>
            <li className="when-your-personal-information">
              <span className="when-your-personal-information1">
                <span className="when-your-personal">
                  when Your personal information is no longer necessary for the purposes for which we collected or used
                  it, but is needed by You to establish, pursue or defend a claim;
                </span>
              </span>
            </li>
            <li className="if-you-have-objected-to-the-us">
              <span className="if-you-have-objected-to-the-us1">
                <span className="if-you-have">
                  if You have objected to the use of Your data, then the restriction is for the time necessary to
                  consider whether, due to Your particular situation, the protection of Your interests, rights and
                  freedoms outweighs the interests we pursue by processing Your personal data.
                </span>
              </span>
            </li>
          </ul>
          <p className="legal-basis-article-15-gdpr">
            <span className="legal-basis-article-15-gdpr1">
              <span className="legal-basis-article2">6.5. Legal basis: Article 15 GDPR</span>
            </span>
          </p>
          <p className="right-of-data-access">
            <span className="right-of-data-access1">
              <span className="right-of-data">Right of data access</span>
            </span>
          </p>
          <p className="you-have-the-right-to-obtain-c">
            <span className="you-have-the-right-to-obtain-c1">
              <span className="you-have-the4">
                You have the right to obtain confirmation from us whether we are processing your personal data, and if
                it’s happening, You have a right to:
              </span>
            </span>
          </p>
          <ul className="to-access-your-personal-data">
            <li className="to-access-your-personal-data1">
              <span className="to-access-your-personal-data2">
                <span className="to-access-your">to access your personal data;</span>
              </span>
            </li>
            <li className="obtain-information-on-the-purp">
              <span className="obtain-information-on-the-purp1">
                <span className="obtain-information-on">
                  obtain information on the purposes of the processing, the categories of personal data being processed,
                  about the recipients or categories of recipients of these data, planned storage period Your data or
                  the criteria for determining this period, your rights under the GDPR and the right to complain to the
                  supervisory authority about the source of the data, about automated decision making, including
                  profiling and security features used in connection with the transfer of this data outside the European
                  Union;
                </span>
              </span>
            </li>
            <li className="obtain-a-copy-of-your-personal">
              <span className="obtain-a-copy-of-your-personal1">
                <span className="obtain-a-copy">obtain a copy of Your personal data</span>
              </span>
            </li>
          </ul>
          <p className="legal-basis-article-16-gdpr">
            <span className="legal-basis-article-16-gdpr1">
              <span className="legal-basis-article3">6.6. Legal basis: Article 16 GDPR</span>
            </span>
          </p>
          <p className="right-to-rectification">
            <span className="right-to-rectification1">
              <span className="right-to-rectification2">Right to rectification</span>
            </span>
          </p>
          <p className="you-have-the-right-to-correct">
            <span className="you-have-the-right-to-correct1">
              <span className="you-have-the5">
                You have the right to correct and complete the personal data you provide. You can do this yourself in
                the Settings tab (Privacy). With regard to other personal data, you have the right to ask us to correct
                this data (if it is incorrect) and to complete it (if it is incomplete).
              </span>
            </span>
          </p>
          <p className="legal-basis-article-20-gdpr">
            <span className="legal-basis-article-20-gdpr1">
              <span className="legal-basis-article4">6.7. Legal basis: Article 20 GDPR</span>
            </span>
          </p>
          <p className="right-to-data-portability">
            <span className="right-to-data-portability1">
              <b className="right-to-data">Right to data portability</b>
            </span>
          </p>
          <p className="you-have-the-right-to-receive">
            <span className="you-have-the-right-to-receive1">
              <span className="you-have-the6">
                You have the right to receive your personal data that You have provided to us and then send it to
                another personal data controller of your choice, e.g. another operator of similar services. You also
                have the right to request that Your personal data be sent by us directly to such other controller, if
                technically feasible.
              </span>
            </span>
          </p>
          <p className="your-personal-data-will-be-sen">
            <span className="your-personal-data-will-be-sen1">
              <span className="your-personal-data2">
                Your personal data will be sent as a file in csv format. The csv format is a commonly used
                machine-readable format that allows You to send the received data to another personal data controller.
              </span>
            </span>
          </p>
          <ol className="legal-basis-article-12-gdpr">
            <li className="legal-basis-article-12-gdpr1">
              <span className="legal-basis-article-12-gdpr2">
                <span className="legal-basis-article5">Legal basis: Article 12 GDPR</span>
              </span>
            </li>
          </ol>
          <p className="time-to-comply-with-the-reques">
            <span className="time-to-comply-with-the-reques1">
              <span className="time-to-comply">Time to comply with the request</span>
            </span>
          </p>
          <p className="if-in-the-exercise-of-the-pow">
            <span className="if-in-the-exercise-of-the-pow1">
              <span className="if-in-the">
                If, in the exercise of the powers listed above, you make a request to us, we either fulfil that request
                or refuse to fulfil it immediately, but no later than one month after receiving it. If, however, due to
                the complexity of the request or the number of requests, we are unable to fulfil your request within one
                month, we will fulfil it within the next two months by informing You in advance of the intended
                extension.
              </span>
            </span>
          </p>
          <p className="for-technical-reasons-we-alwa">
            <span className="for-technical-reasons-we-alwa1">
              <span className="for-technical-reasons">
                For technical reasons, we always need 24 hours to update the settings You have chosen in our systems.
                Therefore, it may happen that you will receive an e-mail from us during the system update, from which
                You have opted out.
              </span>
            </span>
          </p>
          <ol className="the-operator-shall-exercise-du">
            <li className="the-operator-shall-exercise-du1">
              <span className="the-operator-shall-exercise-du2">
                <span className="the-operator-shall3">
                  The Operator shall exercise due diligence when securing the confidentiality of any and all data
                  received from the User, regarding any business partners and contractors of the User as well as any
                  campaigns launched by the User by means of the Releva System.
                </span>
              </span>
            </li>
          </ol>
          <p className="the-above-mentioned-confidenti">
            <span className="the-above-mentioned-confidenti1">
              <span className="the-above-mentioned-confidenti2">
                The above-mentioned confidentiality requirement does not cover situations in which:
              </span>
            </span>
          </p>
          <ul className="the-data-is-commonly-known-th">
            <li className="the-data-is-commonly-known">
              <span className="the-data-is-commonly-known1">
                <span className="the-data-is">the data is commonly known,</span>
              </span>
            </li>
            <li className="the-data-have-been-disclosed-w">
              <span className="the-data-have-been-disclosed-w1">
                <span className="the-data-have">the data have been disclosed with the User’s consent,</span>
              </span>
            </li>
            <li className="the-data-have-been-disclosed-u">
              <span className="the-data-have-been-disclosed-u1">
                <span className="the-data-have1">
                  the data have been disclosed upon receiving a relevant request from an official body, subject to any
                  applicable legal provisions.
                </span>
              </span>
            </li>
          </ul>
          <ol className="the-operator-commits-to-never">
            <li className="the-operator-commits-to-never1">
              <span className="the-operator-commits-to-never2">
                <span className="the-operator-commits">
                  The Operator commits to never disclose the information entered and stored by the User on the User’s
                  Account in the system, in particular contact details. The User exclusively owns all data on the User’s
                  Account within the system. The Operator ensures the safety of the data stored in the system, in
                  accordance with applicable legal regulations.
                </span>
              </span>
            </li>
            <li className="the-operator-commits-to-use-th">
              <span className="the-operator-commits-to-use-th1">
                <span className="the-operator-commits1">
                  The Operator commits to use the data entered into the system by the User for no other purposes other
                  than the pursuit of activities applicable to providing the Services. Your personal information may be
                  subject to profiling for the purposes for which it was collected. However, this data will not be used
                  by the Controller to make automatic decisions.
                </span>
              </span>
            </li>
            <li className="the-user-in-his-capacity-of-c">
              <span className="the-user-in-his-capacity-of-c1">
                <span className="the-user-in">
                  The User, in his capacity of Controller of personal data, transfers to  the Operator, in his capacity
                  as Processor of personal data, the contact details of the customers and the potential customers of the
                  User, especially regarding: name and surname, email address, telephone number, IP address, behavioural
                  data.
                </span>
              </span>
            </li>
          </ol>
          <p className="processing-of-personal-data-by">
            <span className="processing-of-personal-data-by1">
              <span className="processing-of-personal">11.1. Processing of personal data by the User:</span>
            </span>
          </p>
          <p className="the-user-processes-personal-da">
            <span className="the-user-processes-personal-da1">
              <span className="the-user-processes">
                11.1.1. The User processes personal data in connection with its services in accordance with the
                requirements of the Data Protection Legislation.
              </span>
            </span>
          </p>
          <p className="the-users-instructions-for-pr">
            <span className="the-users-instructions-for-pr1">
              <span className="the-users-instructions">
                11.1.2. The User’s instructions for processing personal data must comply with Data Protection
                Legislation and shall not require the Operator to undertake unlawful processing action to comply with
                those instructions.
              </span>
            </span>
          </p>
          <p className="the-user-shall-have-sole-respo">
            <span className="the-user-shall-have-sole-respo1">
              <span className="the-user-shall1">
                11.1.3 The User shall have sole responsibility for the accuracy, quality, and legality of personal data
                and, where the User acquired the personal data, the means by which the User acquired personal data.
              </span>
            </span>
          </p>
          <p className="the-user-warrants-and-undertak">
            <span className="the-user-warrants-and-undertak1">
              <span className="the-user-warrants">11.1.4. The User warrants and undertakes that:</span>
            </span>
          </p>
          <ul className="the-disclosure-of-personal-dat">
            <li className="the-disclosure-of-personal-dat1">
              <span className="the-disclosure-of-personal-dat2">
                <span className="the-disclosure-of">
                  the disclosure of personal data by the Operator is limited to what is necessary in order for the
                  Operator to perform the Services;
                </span>
              </span>
            </li>
            <li className="such-personal-data-is-accurate">
              <span className="such-personal-data-is-accurate1">
                <span className="such-personal-data">
                  such personal data is accurate and up-to-date at the time that it is provided to the Operator and the
                  User shall promptly notify the Operator of any necessary corrections, amendments, deletions or
                  restrictions; and
                </span>
              </span>
            </li>
            <li className="it-has-and-shall-maintain-the">
              <span className="it-has-and-shall-maintain-the1">
                <span className="it-has-and">
                  it has and shall maintain the legal bases for Processing, including all necessary consents, and
                  notices required to enable the Operator to lawfully Process personal data for the duration and
                  purposes of the Services.
                </span>
              </span>
            </li>
          </ul>
          <p className="processing-of-personal-data-by2">
            <span className="processing-of-personal-data-by3">
              <span className="processing-of-personal1">11.2. Processing of personal data by the Operator</span>
            </span>
          </p>
          <p className="the-operator-shall-process-per">
            <span className="the-operator-shall-process-per1">
              <span className="the-operator-shall4">
                11.2.1 The Operator shall process personal data in connection with the Services in accordance with the
                requirements of the Data Protection Legislation, and only as specified in the User’s written
                instruction.
              </span>
            </span>
          </p>
          <p className="the-operator-shall-process-per2">
            <span className="the-operator-shall-process-per3">
              <span className="the-operator-shall5">
                11.2.2. The Operator shall process personal data on behalf of, and in accordance with, the User’s
                written instructions,  in each case to the extent permitted by law, and if unable to do so, the Operator
                shall notify the User in advance.
              </span>
            </span>
          </p>
          <p className="the-user-agrees-that-it-will-r">
            <span className="the-user-agrees-that-it-will-r1">
              <span className="the-user-agrees">
                11.2.3. The User agrees that it will reimburse the Operator for any costs incurred or payments paid as a
                result of any claim brought by a data subject arising in connection with the Operator’s compliance with
                the User’s instructions.
              </span>
            </span>
          </p>
          <p className="if-the-operator-reasonably-bel">
            <span className="if-the-operator-reasonably-bel1">
              <span className="if-the-operator">
                11.2.4. If the Operator reasonably believes the instructions provided by the User in relation to the
                processing contravene Data Protection Laws, then the Operator shall notify the User, and may suspend the
                processing until such time as the User provides new written instructions to the Operator which do not
                require the Operator to contravene applicable law, and the Operator shall be entitled to:
              </span>
            </span>
          </p>
          <ul className="modify-the-services-so-that-th">
            <li className="modify-the-services-so-that-th1">
              <span className="modify-the-services-so-that-th2">
                <span className="modify-the-services">
                  modify the Services so that they can be performed without requiring the relevant processing, and
                  without materially detracting from the overall performance of the Services; and/or 
                </span>
              </span>
            </li>
          </ul>
          <p className="cease-to-provide-the-relevant">
            <span className="cease-to-provide-the-relevant1">
              <span className="cease-to-provide">
                cease to provide the relevant part of the Services which is dependent on the processing, and the
                Operator shall not be responsible or liable for any delay in, or failure to provide, any Services
                dependent on such processing.
              </span>
            </span>
          </p>
          <p className="the-operator-is-obliged-to-pro1">
            <span className="the-operator-is-obliged-to-pro2">
              <span className="the-operator-is1">
                11.3. The Operator is obliged to process the entrusted contact details only for the purposes of
                performing the Service – the possibility of using Releva on the basis of the documented orders of the
                User that also apply to passing the contact details to the third country or international organisation.
              </span>
            </span>
          </p>
          <p className="at-the-written-request-of-the">
            <span className="at-the-written-request-of-the1">
              <span className="at-the-written">
                11.4. At the written request of the User, the Operator is obliged to provide information concerning
                processing the entrusted personal data, including the technical and organisational details used for data
                processing in the scope covered by the User’s request.
              </span>
            </span>
          </p>
          <p className="the-operator-shall-inform-the">
            <span className="the-operator-shall-inform-the1">
              <span className="the-operator-shall6">
                11.5. The Operator shall inform the Controller prior to the commencement of processing of data on the
                implementation of a possible legal obligation consisting of the transfer of personal data to a third
                country or an international organisation.
              </span>
            </span>
          </p>
          <p className="the-operator-ensures-that-pers">
            <span className="the-operator-ensures-that-pers1">
              <span className="the-operator-ensures">
                11.6. The Operator ensures that persons authorised to process the personal data have committed
                themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.
              </span>
            </span>
          </p>
          <p className="the-operator-declares-that-he">
            <span className="the-operator-declares-that-he1">
              <span className="the-operator-declares">
                11.7. The Operator declares that he has taken safeguard measures required under Article 28para. 3 (c) of
                the GDPR. The Operator uses the server infrastructure in St. Ghislain, Belgium, Europe
              </span>
            </span>
          </p>
          <p className="the-operator-declares-that-he2">
            <span className="the-operator-declares-that-he3">
              <span className="the-operator-declares1">
                11.8. The Operator declares that he respects the conditions referred to in paragraphs 2 and 4 of the
                Article 28 of the GDPR for engaging another processor, in accordance with Art. 28 para.. 3  (d) of the
                GDPR.
              </span>
            </span>
          </p>
          <p className="the-operator-takes-into-accoun">
            <span className="the-operator-takes-into-accoun1">
              <span className="the-operator-takes">
                11.9. The Operator takes into account the nature of the processing, assists the User by appropriate
                technical and organisational measures for the fulfilment of the User’s obligation to respond to requests
                for exercising the data subject’s rights laid down in the Art. 28, paragraph 3 (e) of the GDPR.
              </span>
            </span>
          </p>
          <p className="the-operator-makes-available-t">
            <span className="the-operator-makes-available-t1">
              <span className="the-operator-makes1">
                11.10. The Operator makes available to the User all information necessary to demonstrate compliance with
                the obligations laid down in this Article and allow for and contribute to audits, including inspections,
                conducted by the Controller or another auditor mandated by the Controller, in accordance with Art. 28,
                para. 3,(h) of the GDPR.
              </span>
            </span>
          </p>
          <p className="neither-party-will-be-liable-u">
            <span className="neither-party-will-be-liable-u1">
              <span className="neither-party-will">
                11.11.  Neither party will be liable under this DPA for any loss of actual or anticipated income or
                profits, loss of contracts or for any special, indirect or consequential loss or damage of any kind
                howsoever arising and whether caused by tort (including negligence), breach of contract or otherwise,
                whether or not such loss or damage is foreseeable, foreseen or known. The Operator’s liability in
                respect of any breach of the provisions set out in clauses 11.1. to 11.10 above shall amount to the
                direct damage suffered by the User but in any case not more than the total amount of the fees actually
                paid by the User.
              </span>
            </span>
          </p>
          <p className="subject-to-clause-1111-the-op">
            <span className="subject-to-clause-1111-the-op1">
              <span className="subject-to-clause">
                11.11.1. Subject to Clause 11.11 the Operator shall indemnify the User against all losses, damages,
                liabilities, claims, demands, actions, penalties, fines, awards, costs and expenses (including
                reasonable legal and other professional expenses), fines and sanctions which may be incurred by the User
                as the result of any claim, suit, proceeding or Regulator action brought against the User directly
                arising out of any breach by the Operator of the provisions set out above in 11.1. to 11.10, except:
              </span>
            </span>
          </p>
          <ul className="where-the-operator-has-acted-i">
            <li className="where-the-operator-has-acted-i1">
              <span className="where-the-operator-has-acted-i2">
                <span className="where-the-operator">
                  where the Operator has acted in accordance with the User’s instructions, the provisions set out above
                  in 11.1. to 11.10, the Data Protection Laws or other applicable laws; and
                </span>
              </span>
            </li>
            <li className="to-the-extent-that-user-or-any">
              <span className="to-the-extent-that-user-or-any1">
                <span className="to-the-extent">
                  to the extent that User or any third party acting on behalf of the User has breached the provisions
                  set out above in 11.1. to 11.10 or any applicable Data Protection Laws.
                </span>
              </span>
            </li>
          </ul>
          <p className="the-user-shall-indemnify-the-o">
            <span className="the-user-shall-indemnify-the-o1">
              <span className="the-user-shall2">
                11.11.2.The User shall indemnify the Operator against all losses, damages, liabilities, claims, demands,
                actions, penalties, fines, awards, costs and expenses (including reasonable legal and other professional
                expenses), fines and sanctions which may be incurred by the Operator as the result of any claim, suit,
                proceeding or Regulator action brought or threatened against the Operator directly arising out of or in
                connection with the Operator complying with the User’s written instructions regarding personal data
                processing.
              </span>
            </span>
          </p>
          <p className="to-claim-under-an-indemnity-se">
            <span className="to-claim-under-an-indemnity-se1">
              <span className="to-claim-under">
                11.11.3. To claim under an indemnity set out under the provision of clause 11.11, the claiming party
                must:
              </span>
            </span>
          </p>
          <ul className="give-written-notice-of-the-und">
            <li className="give-written-notice-of-the-und1">
              <span className="give-written-notice-of-the-und2">
                <span className="give-written-notice">
                  give written notice of the underlying claim, suit, proceeding or Regulator action to the other as soon
                  as reasonably practicable; 
                </span>
              </span>
            </li>
            <li className="not-making-any-admission-of-li">
              <span className="not-making-any-admission-of-li1">
                <span className="not-making-any">
                  not making any admission of liability in relation to the underlying claim, suit, proceeding or
                  Regulator action without the prior written consent of the other;
                </span>
              </span>
            </li>
            <li className="allow-the-other-to-conduct-the">
              <span className="allow-the-other-to-conduct-the1">
                <span className="allow-the-other">
                  allow the other to conduct the defence of the underlying claim, suit, proceeding or Regulator action;
                  and
                </span>
              </span>
            </li>
            <li className="at-the-others-expense-co-ope">
              <span className="at-the-others-expense-co-ope1">
                <span className="at-the-others">
                  at the other’s expense, co-operate and assist to a reasonable extent with the defence of the
                  underlying claim, suit, proceeding or Regulator action.
                </span>
              </span>
            </li>
          </ul>
          <p className="the-provisions-of-this-clause">
            <span className="the-provisions-of-this-clause1">
              <span className="the-provisions-of">
                11.12. The provisions of this clause 11 shall survive the term of the Agreement perpetually or until the
                Operator has returned or anonymized all personal data provided to it by the User.
              </span>
            </span>
          </p>
          <ol className="the-operator-makes-every-effor1">
            <li className="the-operator-makes-every-effor2">
              <span className="the-operator-makes-every-effor3">
                <span className="the-operator-makes2">
                  The Operator makes every effort to ensure that all personal data is stored for the time necessary to
                  achieve the purposes for which it was collected. As far as possible, we try to provide a specific
                  period of data storage.
                </span>
              </span>
            </li>
          </ol>
          <p className="month-after-the-resignation-of">
            <span className="month-after-the-resignation-of1">
              <span className="month-after-the">
                12.1. 1 month after the resignation of the User from using the Releva system, the Operator will
                permanently remove all records containing personal data transmitted to the Operator in relation to or
                while using the Releva system, in accordance with the Article 28, para. 3(g) in the GDPR.
              </span>
            </span>
          </p>
          <p className="our-services-are-dynamic-and-w">
            <span className="our-services-are-dynamic-and-w1">
              <span className="our-services-are">
                12.2. Our Services are dynamic and we often introduce new features that may require gathering new
                information. If we start collecting other personal information or significantly change the way we
                collect, use or share that information, we will notify You and make changes to these Terms of Use.
              </span>
            </span>
          </p>
          <p className="the-operator-shall-not-process">
            <span className="the-operator-shall-not-process1">
              <span className="the-operator-shall7">
                12.3. The Operator shall not process, store or transfer Personal Data outside of the European Economic
                Area (“EEA”) without prior written authorisation from the User. The Operator is deemed to have
                authorisation to transfer data to a Sub-Processor if there is an adequacy decision or other valid lawful
                transfer mechanism in place (such as, but not limited to, the European Commission’s Standard Contractual
                Clauses), as necessary for the provision of the Services, subject to these Terms of Use.
              </span>
            </span>
          </p>
          <p className="if-any-personal-data-transferr">
            <span className="if-any-personal-data-transferr1">
              <span className="if-any-personal">
                12.3.1.If any Personal Data transferred between the User and the Operator requires execution of the
                European Commission’s Standard Contractual Clauses in order to comply with the Data Protection
                Legislation, the parties will complete all relevant details in, and execute, the European Commission’s
                Standard Contractual Clauses, and take all other actions required to legitimise the transfer. The User
                authorises the Operator to enter into the European Commission’s Standard Contractual Clauses with
                Sub-Processors on User’s behalf and in its name where necessary to account for authorised transfers of,
                or access to, Personal Data outside the EEA.
              </span>
            </span>
          </p>
          <p className="you-may-submit-complaints-inq">
            <span className="you-may-submit-complaints-inq1">
              <span className="you-may-submit">
                12.4. You may submit complaints, inquiries and requests to us regarding the processing of Your personal
                data and the fulfilment of Your rights.
              </span>
            </span>
          </p>
          <p className="payment-terms-for-the-use-of-t">
            <b className="payment-terms-for">11. Payment terms for the use of the system</b>
          </p>
          <p className="user-will-pay-the-fees-applica">
            <span className="user-will-pay">
              User will pay the Fees applicable to User’s subscription to the Services (“Subscription Fees”) and any
              other applicable fees, including but not limited to applicable fees relating to the growth of User’s
              business (“Monthly active users (MAU) based price”), and any fees relating to user’s purchase of
              additional services such as Viber, sms or other (“Additional Fees”), in case it’s not agreed otherwise in
              the Agreement. In cases when the User exceeds normal service usage (over 15 marketing emails and web
              pushes per user per month), the price will be additionally agreed in writing. Together, the Subscription
              Fees, MAU based price and the Additional Fees are referred to as the “Fees”.
            </span>
          </p>
          <p className="subscription-fees-and-mau-base">
            <span className="subscription-fees-and">
              Subscription Fees and MAU based price are paid in advance and will be billed in 30/31 day intervals (each
              such date, a “Billing Date”). Additional Fees will be charged time to time according to the usage. Users
              will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees
              will appear on an invoice, which will be sent to the User via the Contact Email Address provided. Users
              have approximately two weeks to bring up and settle any issues with the billing of the Fees.
            </span>
          </p>
          <p className="the-first-invoice-issued-by-th">
            <span className="the-first-invoice">{`The first invoice issued by the Operator will cover the period from the end of the test period to the first /1st/ day of the following month and will be calculated on the MAU basis during the test period. It will be issued after written confirmation by the User that he wishes to continue using the services provided by the Operator. The invoice must be issued by the `}</span>
          </p>
          <p className="operator-within-5-calendar-day">
            <span className="operator-within-5">
              Operator within 5 calendar days and paid within 10 calendar days after its issuance by the Operator.
            </span>
          </p>
          <p className="each-subsequent-invoice-is-cal">
            <span className="each-subsequent-invoice">
              Each subsequent invoice is calculated from the first /1st/ to the last day of the upcoming month, based on
              the MAU of the past month. Invoices must be issued by the Operator within 5 calendar days of the beginning
              of the month and paid within 10 calendar days after their issuance by the User.
            </span>
          </p>
          <p className="the-invoiced-amount-is-transfe">
            <span className="the-invoiced-amount">
              The invoiced amount is transferred to the following bank accounts:
            </span>
          </p>
          <p className="in-eur-iban-bg13stsa93000028">
            <span className="in-eur-iban">
              In EUR: IBAN: BG13STSA93000028804879, opened in DSK Bank, Sofia, Bulgaria with holder Releva OOD.
            </span>
          </p>
          <p className="in-bgn-iban-bg69stsa93000027">
            <span className="in-bgn-iban">
              In BGN: IBAN: BG69STSA93000027131494, opened in DSK Bank, Sofia, Bulgaria with holder Releva OOD.
            </span>
          </p>
          <p className="if-releva-does-not-receive-a-p">
            <span className="if-releva-does">
              If Releva does not receive a payment of Fees within 28 days of issuance of the invoice Releva may suspend
              and revoke access to the user’s Account and the Services. User’s Account will be reactivated upon user’s
              payment of any outstanding Fees, plus the Fees applicable to the next billing cycle. A User may not be
              able to access the User’s Account during any period of suspension. If the outstanding Fees remain unpaid
              for 60 days following the date of suspension, Releva reserves the right to terminate the user’s Account.
            </span>
          </p>
          <p className="in-case-of-delay-in-payment-of">
            <span className="in-case-of2">{`In case of delay in payment of the remuneration by more than 5 calendar days after the term, without a communicated reason, considered by the Operator as a “valid reason”, the `}</span>
          </p>
          <p className="user-owes-the-operator-a-penal">
            <span className="user-owes-the">
              User owes the Operator a penalty in the amount of 0.5% of the unpaid amount for each overdue day.
            </span>
          </p>
          <p className="releva-reserves-the-right-to-c">
            <span className="releva-reserves-the">
              Releva reserves the right to change the applicable Fees with written notice and will notify User by email
              at least 30 days before the Fee change. If User doesn’t agree to the fee change, he must cancel and stop
              using the Services before the price change takes effect. If there is a fixed term and fee for user
              Service, that Fee will remain in force for the first twelve month period from the Agreement effective
              date.
            </span>
          </p>
          <p className="final-provisions">
            <b className="final-provisions1">12. Final provisions</b>
          </p>
          <p className="any-matters-outside-the-scope">
            <span>
              Any matters outside the scope of these Terms of Use shall be subject to relevant provisions of the Civil
              Code and other Bulgarian law.
            </span>
          </p>
          <p className="with-regard-to-any-disputes-ar">
            <span>
              With regard to any disputes arising in connection with the Service, the parties undertake to resolve any
              such disputes amicably and with due respect to the interests of the other party.
            </span>
          </p>
          <p className="the-releva-users-are-required">
            <span>The Releva Users are required to keep track of any changes to these Terms of Use available at </span>
            <a rel="noopener noreferrer" className="relevaai" href="https://releva.ai/" target="_blank">
              <span>
                <span className="relevaai1">releva.ai</span>
              </span>
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsOfUse;
