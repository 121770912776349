import './FrameComponent10.css';

const FrameComponent10 = ({ color, number, title, description, image }) => {
  return (
    <section className="section-01-wrapper">
      <div className={`section-01 ${color}`}>
        <div className="section-01-inner">
          <div className="hi-john-message-parent">
            <div className="hi-john-message">{number}</div>
            <div className="streamline-abandoned-cart-reco-parent">
              <h1 className="streamline-abandoned-cart">{title}</h1>
              <div className="automate-retargeting-of">{description}</div>
              {/* <button className="button8">
                <div className="button-name6">Learn More</div>
              </button> */}
            </div>
          </div>
        </div>
        <div className="frame-parent33">
          <img
            loading="lazy"
            src={`/CaseStudies/${image}`}
            alt="Results"
            title="Results"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent10;
