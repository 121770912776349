const analyticsFeatures = [
  {
    feature: 'Revenue attribution analytics',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Advanced behavior analytics',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Performance insights',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Email analytics',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Segment analytics',
    //startup: false,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Export analytics',
    //startup: false,
    Growth: false,
    Standard: false,
    Enterprise: true,
  },
];

export default analyticsFeatures;
