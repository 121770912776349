import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './PressureControl.css';

const PressureControl = () => {
  return (
    <section className="pressure-control">
      <div className="section-021">
        <div className="parent12">
          <div className="div15">02</div>
          <div className="c-t-a-background4">
            <h1 className="data-processing-and">Data Processing and Personalization Mastery</h1>
            <div className="once-your-data">
              Once your data is in, Releva's AI algorithms take the wheel, processing it in real-time. Dive deep into
              customer behavior, preferences, and market trends, resulting in a comprehensive 360-degree view of each
              user. This treasure trove of insights fuels Releva's personalization engine, a dynamic fusion of machine
              learning and AI. It excels at identifying intricate patterns and preferences, setting the stage for
              customized strategies.
            </div>
            <div className="button28">
              <a
                className="button-name25"
                rel="noopener noreferrer"
                target="_blank"
                onClick={(e) =>
                  handleBookaDemoClick(
                    e,
                    'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                  )
                }
              >
                Book a demo
              </a>
            </div>
          </div>
        </div>
        <div className="condition-checker">
          <img
            loading="lazy"
            src="/OverviewPage/DataProcessingandPersonalizationMastery.webp"
            alt="DataProcessingandPersonalizationMastery"
            title="DataProcessingandPersonalizationMastery"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default PressureControl;
