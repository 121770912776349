import './ShapeConnector1.css';

const ShapeConnector1 = () => {
  return (
    <div className="shape-connector">
      <div className="phone-number">Phone number*</div>
      <div className="input-field2">
        <div className="text2">
          <div className="typing1">Typing |</div>
          <img className="icon5" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ShapeConnector1;
