import React from 'react';
import { Link } from 'react-router-dom';
import contentLibraryItems from '../../constants/contentLibraryItems';
import LazyImage from '../LazyImage';
import './ContentLibraryList.css';

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const ContentLibraryList = ({ category }) => {
  let filteredCaseStudies = contentLibraryItems.filter(
    (item) => category === 'All' || category === '' || item.category === category
  );

  if (category === 'All' || category === '') {
    filteredCaseStudies = shuffleArray(filteredCaseStudies);
  }

  const shuffledConentLibraryItems = filteredCaseStudies;
  // //used for downloading images will be removed on a cleanup
  // const tempArray = [];

  // // Function to extract image URLs from a given text using regex
  // function extractImageUrls(text) {
  //   const urlPattern = /https?:\/\/\S+\.(jpg|jpeg|png|gif)/g;
  //   return text.match(urlPattern) || [];
  // }

  // // Loop through the filteredCaseStudies array
  // for (const caseStudy of filteredCaseStudies) {
  //   // Push the main image URL into tempArray
  //   if (caseStudy.image) {
  //     tempArray.push(caseStudy.image);
  //   }

  //   // Extract and push image URLs from the content field
  //   if (caseStudy.content) {
  //     const contentImages = extractImageUrls(caseStudy.content);
  //     tempArray.push(...contentImages);
  //   }
  // }

  // console.log(tempArray);

  return (
    <div className="caseStudiesContainer contentLibrary">
      {shuffledConentLibraryItems.map((item, index) => (
        <Link to={`/content-library-details?item=${item.url}`} key={index}>
          <div className="frame-parent174">
            {/* <img className="frame-child108" loading="lazy" src={item.image} alt={item.title} /> */}
            <LazyImage className="frame-child108" src={item.image} alt={item.title} />
            <div className="ellipse-parent7">
              <div className={`frame-child109 ${item.category}`} />
              <div className={`slow-moving-consumer-goods9 ${item.category}`}>{item.category}</div>
            </div>
            <div className="momax-case-study1">{item.title}</div>
            <div className="conversion-rate-from5">{item.shortDescription}</div>
            {/* <div className="center">
              <button class="button5">
                <Link class="button-name5" to={`/content-library-details?item=${item.url}`} key={index}>
                  Read More ->
                </Link>
              </button>
            </div> */}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ContentLibraryList;
