import { useState } from 'react';
import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../../components/HomePage/HomePageHeroTitle';
import FeatureTable from '../../components/PricingPage/FeatureTable';
import PlanDetails from '../../components/PricingPage/PlanDetails';
import PlansTable from '../../components/PricingPage/PlansTable';
import ShopifyMerchantProButtons1 from '../../components/PricingPage/ShopifyMerchantProButtons1';
import aiHubFeatures from '../../constants/aiHubFeatures';
import analyticsFeatures from '../../constants/analyticsFeatures';
import automationFeatures from '../../constants/automationFeatures';
import dataPlatformFeatures from '../../constants/dataPlatformFeatures';
import marketingChannelsFeatures from '../../constants/marketingChannelsFeatures';
import plansData from '../../constants/plansData';
import resourcesFeatures from '../../constants/resourcesFeatures';
import settingsFeatures from '../../constants/settingsFeatures';
import webPersonalizationFeatures from '../../constants/webPersonalizationFeatures';
import '../PricingPage.css';

const FlagShipProduct = () => {
  //const [showAnnualPrices, setShowAnnualPrices] = useState(false); // State for toggle switch
  const [showAnnualPrices] = useState(false); // State for toggle switch
  return (
    <div className="pricing-page">
      <Helmet>
        <title>Releva AI | Flagship Product Plan</title>
        <meta
          name="description"
          content="Seamlessly integrate with Releva AI's to optimize your e-commerce operations. Discover how our advanced integration solutions enhance your online store's performance, streamline processes, and drive growth."
        />
      </Helmet>
      <main className="background">
        <HomePageHeroTitle
          heroTitle="Trust Your Decisions, Drive Success"
          heroDescription="Maximize the Value of Your Business and Grow Your eCommerce."
        />
        {/* <ChooseYourPlan /> */}
        <div className="releva-pricing-plans2">
          <h1 className="releva-pricing-plans3">Use Releva Flagship Plans</h1>
          {/* <div className="toggle-container"> */}
          {/* Monthly label */}
          {/* <div className="toggle-label">
              <span>Monthly Commitment</span>
            </div> */}
          {/* Toggle switch */}
          {/* <div className="toggle-switch">
              <input
                type="checkbox"
                id="toggle"
                checked={showAnnualPrices}
                onChange={() => setShowAnnualPrices(!showAnnualPrices)}
              />
              <label htmlFor="toggle"></label>
            </div> */}
          {/* Annual label */}
          {/* <div className="toggle-label">
              <span>Annual Commitment</span>
            </div>
          </div> */}
          <div className="startup-plan-parent">
            {plansData.map((plan, index) => (
              <PlanDetails key={index} {...plan} showAnnualPrices={showAnnualPrices} />
            ))}
          </div>
          {/* <p className="mauDescription mb-0">*UMAU - Monthly Active Users tracked by Releva.</p> */}
        </div>
        <ShopifyMerchantProButtons1 />
        <form className="compare-plans2">
          <div className="plans1">
            <PlansTable plans={[{ name: 'Enterprise' }, { name: 'Standard' }, { name: 'Growth' }]} />
          </div>
          <FeatureTable growth={true} enterprise={true} data={aiHubFeatures} title="AI Hub <sup>*new</sup>" />
          <FeatureTable
            growth={true}
            enterprise={true}
            data={dataPlatformFeatures}
            title="Data Platform"
            disclaimer="*Predefined Segments are delievered with Growth plan."
          />
          <FeatureTable
            growth={true}
            enterprise={true}
            data={marketingChannelsFeatures}
            title="Marketing channels"
            disclaimer="*Available for Standard and Growth, additional costs apply."
          />
          <FeatureTable growth={true} enterprise={true} data={webPersonalizationFeatures} title="Web personalization" />
          <FeatureTable
            growth={true}
            enterprise={true}
            data={automationFeatures}
            title="Automation"
            disclaimer="*The Standard and Growth Plans come with predefined workflows."
          />
          <FeatureTable growth={true} enterprise={true} data={analyticsFeatures} title="Analytics" />
          <FeatureTable growth={true} enterprise={true} data={resourcesFeatures} title="Resources" />
          <FeatureTable growth={true} enterprise={true} data={settingsFeatures} title="Settings" />
        </form>
      </main>
      {/* <div className="cta2">
        <h1 className="try-releva-free3">Try Releva free for 30 days. </h1>
        <div className="book-a-session2">
          Book a session with one of our eCommerce experts and learn how Releva can help your business grow.
        </div>
        <div className="button10">
          <div className="button-name10">Book a demo</div>
        </div>
      </div>
      <FAQ1 />
      <Testimonials4 />
      <Customers />
       */}
    </div>
  );
};

export default FlagShipProduct;
