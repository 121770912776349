const pagesJsonLD = {
  homePage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Home Page',
    description:
      'Discover Releva AI, the leading platform for AI-driven marketing solutions. Transform customer engagement, optimize campaigns, and drive business growth with our innovative technology. Explore our cutting-edge tools and see how we can elevate your marketing strategy.',
    url: 'https://www.releva.ai',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  aboutUsPage: {
    '@context': 'https://schema.org',
    '@type': 'AboutPage',
    name: 'Releva AI | About Us',
    description:
      "Learn about Releva AI, the innovative company revolutionizing marketing automation. Meet our team, explore our mission, and discover how we're using AI to transform customer engagement and drive business success.",
    url: 'https://www.releva.ai/about-us',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  caseStudiesPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Case Study',
    description:
      "Explore Releva AI's case studies to see how our AI-driven solutions have transformed businesses. Discover real-world examples of enhanced customer engagement, increased conversions, and optimized marketing strategies. Learn from our success stories.",
    url: 'https://www.releva.ai/case-studies',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  contentLibraryPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Blog',
    description:
      'Stay updated with the latest insights, tips, and success stories from Releva AI. Explore articles on AI-driven e-commerce solutions, customer engagement strategies, and digital marketing trends.',
    url: 'https://www.releva.ai/content-library',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  partnersPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Blog',
    description:
      'Join Releva.ai Partners to enhance your business with AI-driven solutions. Collaborate for growth, innovation, and tailored offerings that deliver exceptional value to clients.',
    url: 'https://www.releva.ai/partners',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  cookiesPolicyPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Cookie Policy',
    description:
      "Learn about Releva AI's Cookie Policy to understand how we use cookies to enhance your experience on our site. Find details on how we collect, use, and manage cookies and your options for controlling them.",
    url: 'https://www.releva.ai/cookie-policy',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  customerAndProductPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Customer and Product Platform',
    description:
      "Maximize your marketing impact with Releva AI's Customer and Product Platform. Use advanced AI insights to personalize customer experiences and optimize product offerings. Discover how our platform can drive growth and engagement.",
    url: 'https://www.releva.ai/customer-and-product-platform',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  dataDrivenMarketersPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Data Driven Marketers',
    description:
      'Empower your marketing strategy with Releva AI. Harness the power of data-driven insights to optimize campaigns, personalize customer interactions, and maximize ROI. Explore our solutions for data-driven marketers today!',
    url: 'https://www.releva.ai/data-driven-marketers',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  mailAndPushPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Mail & Push',
    description:
      "Enhance your marketing with Releva AI's Mail and Push solutions. Automate personalized email and push notifications to boost engagement and conversions. Discover how our AI technology can transform your outreach strategies.",
    url: 'https://www.releva.ai/mail-and-push',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  messageHubPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Message Hub',
    description:
      "Enhance your marketing with Releva AI's Mail and Push solutions. Automate personalized email and push notifications to boost engagement and conversions. Discover how our AI technology can transform your outreach strategies.",
    url: 'https://www.releva.ai/message-hub',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  omniPersonalizationPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Omni Personalization',
    description:
      'Unlock the power of omni-personalization with Releva AI. Deliver seamless, personalized experiences across all customer touchpoints. Learn how our AI-driven solutions can enhance engagement and drive conversions.',
    url: 'https://www.releva.ai/omni-personalization',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  overviewPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Overview',
    description:
      "Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI.",
    url: 'https://www.releva.ai/overview',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  privacyPolicyPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Privacy Policy',
    description:
      "Review Releva AI's Privacy Policy to understand how we protect your personal information. Learn about our data collection practices, usage, and your rights regarding your privacy and data security while using our services.",
    url: 'https://www.releva.ai/privacy-policy',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  productPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Flagship Product',
    description:
      "Discover Releva AI's advanced marketing automation tools. Personalize customer experiences, boost engagement, and drive sales with our innovative AI solutions. Learn more about our cutting-edge product now!",
    url: 'https://www.releva.ai/product-page',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  aiHubPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | AI Hub',
    description:
      'Welcome to the Releva AI Hub. Access the latest AI innovations, insights, and tools designed to enhance your marketing strategy. Stay updated with cutting-edge technology and maximize your marketing potential with Releva AI.',
    url: 'https://www.releva.ai/ai-hub',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  socialMediaPage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Social Media',
    description:
      'Elevate your social media strategy with Releva AI. Leverage advanced AI tools to optimize your campaigns, increase engagement, and drive growth across all platforms. Discover how our solutions can transform your social media presence.',
    url: 'https://www.releva.ai/social-media',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
  termsOfUsePage: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Releva AI | Terms of Use',
    description:
      'Review the Terms of Use for Releva AI to understand the guidelines and policies governing your use of our services. Ensure a clear understanding of your rights and responsibilities while using our AI-driven marketing solutions.',
    url: 'https://www.releva.ai/terms-of-use',
    publisher: {
      '@type': 'Organization',
      name: 'Releva AI',
      url: 'https://www.releva.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://releva.ai/releva-logo.svg',
      },
    },
  },
};
export default pagesJsonLD;
