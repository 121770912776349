import './HowItWorks1.css';

const HowItWorks1 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/AIHub/HowItWorks2.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">03</div>
          <div className="master-your-generated">Master Your Generated Content</div>
          <div className="edit-your-documents">Edit Your Documents with Full Flexibility</div>
          <div className="customize-your-generated">
            Customize your generated content effortlessly by adjusting it manually or letting Releva's AI Hub summarize,
            expand, or enhance the text for you. The created document is fully editable, you can add formatting or
            generate images that complement your message, ensuring they perfectly align with your brand identity and
            your user persona. Save your documents and collaborate with coworkers to achieve the best final results.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks1;
