import './Block1.css';

const Block1 = () => {
  return (
    <div className="block-7">
      <div className="block-7-inner">
        <div className="facebook-ads-personalization-parent">
          <div className="facebook-ads-personalization">Facebook Ads personalization</div>
          <div className="frame-wrapper48">
            <div className="frame-parent118">
              <div className="frame-parent119">
                <div className="frame-wrapper49">
                  <div className="referral-programs-parent">
                    <div className="referral-programs5">78.7</div>
                    <div className="ready-to-see-action6">
                      <div className="logo-background">%</div>
                    </div>
                  </div>
                </div>
                <div className="click-through-rate">Click through rate increase</div>
              </div>
              <div className="frame-parent120">
                <div className="header-block-parent">
                  <div className="header-block">54.3</div>
                  <div className="cost-per-click-decrease-wrapper">
                    <div className="cost-per-click">Cost per click decrease</div>
                  </div>
                </div>
                <div className="ready-to-see-action7">
                  <div className="ebag-trendo-adres3">%</div>
                </div>
              </div>
              <div className="try-relevafree1">
                <div className="value-formater">
                  <div className="input-filter3">
                    <div className="mercari4">32.4</div>
                    <div className="price-per-result-decrease-wrapper">
                      <div className="price-per-result">Price per result decrease</div>
                    </div>
                  </div>
                  <div className="ready-to-see-action8">
                    <div className="get-a-demo5">%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer10">
        <div className="c-t-a-background6">
          <div className="ecommerceowners-marketingspeci">
            <img className="vuesaxlineardirect-up-icon22" alt="" />
            <div className="vuesaxlineartag-2-container">
              <img className="vuesaxlineartag-2-icon3" loading="lazy" alt="" src="/vuesaxlineartag2.svg" />
            </div>
            <div className="retargeting-parent">
              <div className="retargeting">{`Retargeting `}</div>
              <div className="customer-datasegmentation">
                <div className="personalized">Personalized</div>
              </div>
            </div>
          </div>
        </div>
        <div className="artwork-group">
          <img className="artwork-icon2" alt="" src="/artwork-1.svg" />
          <div className="c-t-a-button1">
            <div className="c-t-a-button-child" />
            <div className="vuesaxlineareraser-wrapper1">
              <img className="vuesaxlineareraser-icon3" loading="lazy" alt="" src="/vuesaxlineareraser.svg" />
            </div>
            <div className="vuesaxlinearsize-wrapper1">
              <img className="vuesaxlinearsize-icon3" alt="" src="/vuesaxlinearsize.svg" />
            </div>
            <div className="frame-parent121">
              <img className="frame-child65" loading="lazy" alt="" src="/frame-141.svg" />
              <div className="rectangle-parent12">
                <div className="frame-child66" />
                <div className="frame-child67" />
                <div className="frame-child68" />
                <img
                  className="black-t-shirt-with-tag-hanging-icon"
                  loading="lazy"
                  alt=""
                  src="/blacktshirtwithtaghangingfromclothesrackw20231127053210utcremovebgpreview-1@2x.webp"
                />
              </div>
            </div>
          </div>
          <div className="frame-parent122">
            <div className="vuesaxlineardirect-up-wrapper4">
              <img className="vuesaxlineardirect-up-icon23" alt="" />
            </div>
            <div className="on-ads-parent">
              <div className="on-ads">on Ads</div>
              <div className="save-money-wrapper">
                <div className="save-money">Save money</div>
              </div>
            </div>
            <img className="money-change-1-icon" alt="" src="/moneychange-1.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block1;
