import React from 'react';
import './FeatureTable.css'; // Import your CSS file for styling

const FeatureTable = ({ data, title, disclaimer, enterprise, growth }) => {
  return (
    <div className="data-platform">
      <div className="background1">
        <div className="data-platform-wrapper">
          <div className="data-platform1" dangerouslySetInnerHTML={{ __html: title }}></div>
        </div>
      </div>
      <div className="feature-table-container">
        {data.map((item, index) => (
          <div className="row" key={index}>
            <div className="feature-cell">{item.feature}</div>
            {enterprise && (
              <div className="availability-cell">
                <img
                  src={item.Enterprise ? '/frame-4.svg' : '/frame-208.svg'}
                  alt={item.Enterprise ? 'available' : 'not available'}
                />
              </div>
            )}
            {item.startup && (
              <div className="availability-cell">
                <img
                  src={item.Enterprise ? '/frame-4.svg' : '/frame-208.svg'}
                  alt={item.startup ? 'available' : 'not available'}
                />
              </div>
            )}
            <div className="availability-cell">
              <img
                src={item.Standard ? '/frame-4.svg' : '/frame-208.svg'}
                alt={item.Standard ? 'available' : 'not available'}
              />
            </div>
            {growth && (
              <div className="availability-cell">
                <img
                  src={item.Growth ? '/frame-4.svg' : '/frame-208.svg'}
                  alt={item.Growth ? 'available' : 'not available'}
                />
              </div>
            )}
          </div>
        ))}
        {disclaimer && <p>{disclaimer}</p>}
      </div>
    </div>
  );
};

export default FeatureTable;
