import { Helmet } from 'react-helmet';
import FeaturesSection from '../components/AIHubPage/FeaturesSection';
import HeroSlider from '../components/AIHubPage/HeroSlider';
import HowItWorks1 from '../components/AIHubPage/HowItWorks1';
import Testmonials from '../components/Testimonials';
import TrustedCustomers from '../components/TrustedCustomers';
import pagesJsonLD from '../constants/pagesJsonLD';
import './RelevaAIHub.css';

const RelevaAIHub = () => {
  //const imgRef = useRef(null);

  // Function to handle clicks on the card wrappers
  const handleCardWrapperClick = (e, slider) => {
    e.preventDefault(); // Prevent default behavior of the click event if needed
    showElements(`.slider${slider}`, 'image');
    showElements(`.text${slider}`, 'text');
    toggleActive(`.interactiveHeading${slider}`);
  };
  // Function to toggle "active" class on the clicked element and remove it from others
  const toggleActive = (className) => {
    // Get all elements with the specified class name
    const elements = document.querySelectorAll(className);

    // Remove "active" class from all elements
    document.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });

    // Add "active" class to the clicked element
    elements.forEach((element) => {
      element.classList.add('active');
    });
  };
  // Function to show all elements with the specified class name
  const showElements = (className, type) => {
    // Get all elements with the specified class name
    const elementsToShow = document.querySelectorAll(className);

    // Convert NodeList to an array using spread operator
    const elementsArray = [...elementsToShow];

    // Show all elements
    elementsArray.forEach((element) => {
      element.style.display = 'block';
    });

    // Hide all other images
    if (type === 'image') {
      const allImages = document.querySelectorAll('.interactiveSlider');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
    if (type === 'text') {
      const allImages = document.querySelectorAll('.interactiveText');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
  };

  return (
    <div className="releva-ai-hub">
      <Helmet>
        <title>Releva AI | AI Hub</title>
        <meta
          name="description"
          content="Welcome to the Releva AI Hub. Access the latest AI innovations, insights, and tools designed to enhance your marketing strategy. Stay updated with cutting-edge technology and maximize your marketing potential with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.aiHubPage)}</script>
      </Helmet>
      <div className="img-4947-4-1-parent">
        <div className="AIHubHolder">
          <h1>You've never created like this before.</h1>
          <div className="clip-path-group-parent">
            <div className="ai-hub">
              <img className="clip-path-group heroImage" alt="" src="/relava-symbol.svg" /> AI Hub
            </div>
          </div>
        </div>
        <HeroSlider />
      </div>
      <section className="else-statement">
        <div className="while-loop">
          <div className="relevas-ai-hub">Releva’s AI Hub Is The ChatGPT Alternative For Creative Marketing</div>
          <div className="switch-case-ai">
            <div className="create-document-collaborate">
              Create, Document, Collaborate and Share, Brand Consistent, Persuasive and Audience Tailored Content.
            </div>
          </div>
          <div className="break-point">
            <div className="button1">
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                href="https://releva.ai/admin/#/signup"
              >
                Try Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="call-stack">
        <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/HeroImage.webp" />
      </section>
      <section className="global-scope">
        <div className="block-statement">
          <div className="achievements-recognition">{`Achievements & Recognition `}</div>
          <div className="achievements-recognition-parent">
            <div className="arithmetic-operator">
              <div className="relevas-global-impact">Releva's global impact on eCommerce from around the world.</div>
            </div>
          </div>
          <div className="frame-container">
            <div className="frame-wrapper"></div>
            <div className="awardsHolder">
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements1.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements2.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements3.webp" />
            </div>
          </div>
          <div className="array-operator">
            <div className="object-operator">
              <div className="function-define">
                <div className="why-your-ecommerce">Why Your eCommerce Business Needs Releva AI Hub?</div>
              </div>
              {/* make it interactive */}
              <div className="variable-declare">
                <div className="variable-update">
                  <div className="statement-list">
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading1 active"
                      onClick={(e) => handleCardWrapperClick(e, 1)}
                    >
                      <b className="struggling-to-persuade">Struggling to persuade your audience?</b>
                      <div className="convey-in-the interactiveText text1">
                        Convey in the most persuasive way the topic of your message in your preferred channel and
                        language (Bulgarian, English, German, French, Italian, Spanish).
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading2"
                      onClick={(e) => handleCardWrapperClick(e, 2)}
                    >
                      <b className="struggling-to-persuade">Do you have consistency in your writing?</b>
                      <div className="convey-in-the interactiveText text2">
                        Convey in the most persuasive way the topic of your message in your preferred channel and
                        language (Bulgarian, English, German, French, Italian, Spanish).
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading3"
                      onClick={(e) => handleCardWrapperClick(e, 3)}
                    >
                      <b className="struggling-to-persuade">Do you know your audience?</b>
                      <div className="convey-in-the interactiveText text3">
                        Convey in the most persuasive way the topic of your message in your preferred channel and
                        language (Bulgarian, English, German, French, Italian, Spanish).
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="image-118-icon interactiveSlider  slider1"
                  loading="lazy"
                  alt=""
                  src="/AIHub/WhySlider1.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider2"
                  loading="lazy"
                  alt=""
                  src="/AIHub/WhySlider2.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider3"
                  loading="lazy"
                  alt=""
                  src="/AIHub/WhySlider3.webp"
                />
              </div>
              {/* make it interactive */}
            </div>
          </div>
        </div>
      </section>
      <Testmonials />

      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://releva.ai/admin/#/signup"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks">
            <div className="title">
              <div className="how-releva-ai">How Releva AI Hub Works</div>
              <div className="releva-ai-hub1">Releva AI Hub consists of 4 powerful components</div>
            </div>
            <div className="sub-menu">
              <div className="menu-items">
                <div className="brand-identity-wrapper">
                  <div className="brand-identity1">
                    <a className="dummyLink" href="#brandIdentity">
                      Brand Identity
                    </a>
                  </div>
                </div>
                <div className="create-content-wrapper">
                  <div className="create-content">
                    <a className="dummyLink" href="#createContent">
                      Create Content
                    </a>
                  </div>
                </div>
                <div className="generated-content-wrapper">
                  <div className="generated-content">
                    <a className="dummyLink" href="#generateContent">
                      Generated Content
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#aiChat">
                      AI chat
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks1-wrapper">
            <div className="howitworks1" id="brandIdentity">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">01</div>
                  </div>
                  <div className="extract-your-brand">Extract your brand voice, tone</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">description and persona for consistent messaging. </div>
                  </div>
                  <div className="simply-provide-your">
                    Simply provide your website URL and Releva will generate contextual brand information, ensuring that
                    all your future generated content aligns seamlessly with your brand identity.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/AIHub/HowItWorks1.webp" />
            </div>
          </div>
          <div className="howitworks2-wrapper">
            <div className="howitworks2" id="createContent">
              <div className="conditional-split">
                <div className="div1-ai">02</div>
                <div className="create-engaging-ai-generated">Create engaging AI-generated content</div>
                <div className="effortlessly-with-relevas">
                  effortlessly with Releva's intuitive platform in just 4 simple steps:
                </div>
                <div className="conditional-split-inner">
                  <div className="frame-parent8">
                    <div className="icon-01-parent-ai">
                      {/* <img className="icon-01" loading="lazy" alt="" src="/icon-021.svg" /> */}
                      <div className="choose-what-type-of-content-yo-parent">
                        <div className="choose-what-type">{`Choose what type of content you want to create.`}</div>
                        <div className="select-your-desired">
                          Select your desired content type from a range of options including Facebook Ads, Emails, Blog
                          Posts, Viber Messages, Text Messages, and WhatsApp Messages.
                        </div>
                      </div>
                    </div>
                    <div className="icon-01-parent-ai">
                      {/* <img className="icon-01" loading="lazy" alt="" src="/icon-021.svg" /> */}
                      <div className="choose-what-type-of-content-yo-parent">
                        <div className="choose-what-type">{`Define your marketing objectives`}</div>
                        <div className="select-your-desired">
                          Define your marketing goals. Releva provides you with a seamless workflow: add your objectives
                          and receive customized content that resonates with your brand and captivates your audience.
                        </div>
                      </div>
                    </div>
                    <div className="icon-01-parent-ai">
                      {/* <img className="icon-01" loading="lazy" alt="" src="/icon-021.svg" /> */}
                      <div className="choose-what-type-of-content-yo-parent">
                        <div className="choose-what-type">{`Generate your content`}</div>
                        <div className="select-your-desired">
                          Allow Releva's AI to craft content precisely tailored to your preferences by configuring
                          campaign specifics such as campaign name, language, topic section, and target audience.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks3-ai-wrapper" id="generateContent">
            <HowItWorks1 />
          </div>
          <div className="howitworks1-wrapper" id="aiChat">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">04</div>
                  </div>
                  <div className="extract-your-brand">AI Chat</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">AI-generated content creation like never before </div>
                  </div>
                  <div className="simply-provide-your">
                    Discover the most creative way to produce content. Effortlessly craft dynamic marketing
                    communications in multiple languages, and generate text and images that are precisely tailored for
                    your brand and audience.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/AIHub/HowItWorks3.webp" />
            </div>
          </div>
        </div>
      </section>
      <TrustedCustomers />
      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://releva.ai/admin/#/signup"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FeaturesSection title="Releva goes beyond just content creation." />
      <Testmonials />
      <div className="separator"></div>
    </div>
  );
};

export default RelevaAIHub;
