import './Clock.css';

const Clock = () => {
  return (
    <section className="clock">
      <div className="slider">
        <div className="toggle-button">
          <div className="accordion">
            <div className="modal">
              <h1 className="integrations">Integrations</h1>
              <div className="we-provide-seamless1">
                We provide seamless integration with major eCommerce platforms.
              </div>
            </div>
          </div>
          <div className="tab">
            <div className="magento">
              <img className="magento-child" loading="lazy" alt="" src="/group-19.svg" />
              <div className="magento1">Magento</div>
            </div>
            <div className="magento2">
              <div className="vector-frame">
                <img className="vector-icon13" loading="lazy" alt="" src="/vector-31.svg" />
              </div>
              <div className="gomba-shop">Gomba Shop</div>
            </div>
            <div className="magento3">
              <div className="vector-wrapper1">
                <img className="vector-icon14" loading="lazy" alt="" src="/vector-41.svg" />
              </div>
              <div className="cloudcard">Cloudcart</div>
            </div>
            <div className="magento4">
              <img className="vector-icon15" loading="lazy" alt="" src="/vector-51.svg" />
              <div className="opencard">Opencart</div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="stepper">
            <div className="magento-gomba-shop-cloudcard-parent">
              <img className="magento-gomba-shop-cloudcard" loading="lazy" alt="" src="/vector-61.svg" />
              <div className="flowchart">
                <div className="cs-card">CS Cart</div>
              </div>
            </div>
            <div className="frame-parent84">
              <div className="wizard-wrapper">
                <div className="wizard1">
                  <img className="product-recommendations-icon" loading="lazy" alt="" src="/vector-71.svg" />
                  <img className="customer-data-segmentation" loading="lazy" alt="" src="/vector-8.svg" />
                  <img className="communication-center-icon" loading="lazy" alt="" src="/vector-91.svg" />
                </div>
              </div>
              <div className="shopify-wrapper">
                <div className="shopify2">Shopify</div>
              </div>
            </div>
            <div className="marketing-specialist3">
              <div className="get-started4">Get started</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clock;
