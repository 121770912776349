import './ShapeArray.css';

const ShapeArray = () => {
  const handleCardWrapperClick = (event, item) => {
    // Remove 'active' class from all shopify-c-s-card-wrapper elements
    document.querySelectorAll('.shopify-c-s-card-wrapper').forEach((element) => {
      element.classList.remove('active');
    });

    // Add 'active' class to the clicked shopify-c-s-card-wrapper element
    const cardWrapper = event.currentTarget;
    cardWrapper.classList.add('active');

    // Call function to update sliderItem in child component
    updateSliderItem(item);
  };

  const updateSliderItem = (item) => {
    const elementWithAiClass = document.querySelector(`.${item}`);
    document.querySelector('.image-collection.ai').classList.remove('active');
    document.querySelector('.image-collection.automationSlider').classList.remove('active');
    document.querySelector('.image-collection.omnichannel').classList.remove('active');
    document.querySelector('.image-collection.analytics').classList.remove('active');
    if (elementWithAiClass) {
      elementWithAiClass.classList.add('active');
    }
  };

  return (
    <section className="shape-array">
      <div className="use-cases-section">
        <h1 className="the-data-driven-marketers">The Data-Driven Marketer's Swiss Army Knife</h1>
        <div className="shapeHolder">
          <div className="frame-parent83">
            <div className="shopify-c-s-card-wrapper active" onClick={(e) => handleCardWrapperClick(e, 'ai')}>
              <div className="shopify-c-s-card">
                <h3 className="ai-driven-personalization">AI-Driven Personalization</h3>
                <div className="craft-personalized-experiences">
                  Craft personalized experiences for each visitor, fostering long-term customer loyalty.
                </div>
              </div>
            </div>
            <div className="shopify-c-s-card-wrapper" onClick={(e) => handleCardWrapperClick(e, 'automationSlider')}>
              <div className="shopify-c-s-card">
                <h3 className="ai-driven-personalization">Automated Campaigns</h3>
                <div className="craft-personalized-experiences">
                  Automate marketing tasks and optimize campaigns in real-time with AI insights.
                </div>
              </div>
            </div>
            <div className="shopify-c-s-card-wrapper" onClick={(e) => handleCardWrapperClick(e, 'omnichannel')}>
              <div className="shopify-c-s-card">
                <h3 className="ai-driven-personalization">Omnichannel Mastery</h3>
                <div className="craft-personalized-experiences">
                  Engage customers through push notifications, pop-up banners, Viber, WhatsApp, SMS, and targeted
                  Facebook ads.
                </div>
              </div>
            </div>
            <div className="shopify-c-s-card-wrapper" onClick={(e) => handleCardWrapperClick(e, 'analytics')}>
              <div className="shopify-c-s-card">
                <h3 className="ai-driven-personalization">Comprehensive Analytics</h3>
                <div className="craft-personalized-experiences">
                  Access in-depth data analysis and A/B testing to refine strategies continually.
                </div>
              </div>
            </div>
          </div>

          <div className="image-collection automationSlider">
            <img
              loading="lazy"
              src="/DataDrivenMarketer/Slider2.webp"
              alt="HeroImage"
              title="HeroImage"
              className="HomePageHeroImage"
            />
          </div>
          <div className="image-collection ai active">
            <img
              loading="lazy"
              src="/DataDrivenMarketer/Slider1.webp"
              alt="HeroImage"
              title="HeroImage"
              className="HomePageHeroImage"
            />
          </div>

          <div className="image-collection omnichannel">
            <img
              loading="lazy"
              src="/DataDrivenMarketer/Slider3.webp"
              alt="HeroImage"
              title="HeroImage"
              className="HomePageHeroImage"
            />
          </div>

          <div className="image-collection analytics">
            <img
              loading="lazy"
              src="/DataDrivenMarketer/Slider4.webp"
              alt="HeroImage"
              title="HeroImage"
              className="HomePageHeroImage"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShapeArray;
