import React, { useEffect, useRef, useState } from 'react';

const HeroSlider = () => {
  const imgRefs = useRef([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const resizeImage = () => {
      const windowHeight = window.innerHeight;
      const imageHeight = windowHeight - 123; // Subtracting 123px from the window height
      const imgContainer = imgContainerRef.current;

      if (imgContainer) {
        imgContainer.style.height = `${imageHeight}px`;
      }

      imgRefs.current.forEach((ref, index) => {
        if (ref) {
          ref.style.height = `${imageHeight}px`;
          ref.style.display = index === currentImageIndex ? 'block' : 'none';
        }
      });
    };

    // Call resizeImage initially and on window resize
    resizeImage();
    window.addEventListener('resize', resizeImage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', resizeImage);
    };
  }, [currentImageIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imgRefs.current.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div ref={imgContainerRef} className="img-4947-4-1-parent">
      {['AIHub/HeroImage1.webp', 'AIHub/HeroImage2.webp', 'AIHub/HeroImage3.webp', 'AIHub/HeroImage4.webp'].map(
        (src, index) => (
          <img
            key={index}
            ref={(el) => (imgRefs.current[index] = el)}
            className={`img-4947-4-1 heroImageSlider`}
            loading="lazy"
            alt=""
            src={src}
            style={{ display: index === 0 ? 'block' : 'none' }}
            onError={(e) => (e.target.style.display = 'none')} // Hide image if loading fails
          />
        )
      )}
    </div>
  );
};

export default HeroSlider;
