const automationFeatures = [
  {
    feature: 'Email newsletter',
    //startup: true,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Marketing automation',
    //startup: false,
    Growth: true,
    Standard: true,
    Enterprise: true,
  },
  // {
  //   feature: 'Predefined continuous and calendar driven workflows*',
  //   //startup: true,
  //   Growth: false,
  //   Standard: true,
  //   Enterprise: true,
  // },
  {
    feature: 'Continuous and calendar-driven workflows*',
    //startup: false,
    Growth: false,
    Standard: true,
    Enterprise: true,
  },
  {
    feature: 'Triggered campaigns',
    //startup: false,
    Growth: false,
    Standard: false,
    Enterprise: true,
  },
];

export default automationFeatures;
