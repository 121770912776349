import './Shapes.css';

const Shapes = () => {
  return (
    <div className="shapes">
      <div className="fills">
        <div className="i-05">
          <img
            loading="lazy"
            src="/DataDrivenMarketer/EightHours.webp"
            alt="HeroImage"
            title="HeroImage"
            className="HomePageHeroImage"
          />
        </div>
        <div className="fills-inner">
          <div className="hour-weekday-freedom-pass-parent">
            <h1 className="hour-weekday-freedom">8-Hour Weekday Freedom Pass</h1>
            <div className="save-over-8">
              Save over 8 hours per week on repetitive marketing tasks and free up your time for creativity and
              strategy.
            </div>
          </div>
        </div>
      </div>
      <div className="fills1">
        <div className="frame-parent77">
          <div className="frame-wrapper34">
            <div className="join-the-40-revenue-club-parent">
              <h1 className="join-the-40">Join the +40% Revenue Club</h1>
              <div className="join-the-marketing">
                Join the marketing elite with Releva, where +40% revenue boosts are just another day at the office.
              </div>
            </div>
          </div>
          <div className="i-06">
            <img
              loading="lazy"
              src="/DataDrivenMarketer/JoinTheClub.webp"
              alt="HeroImage"
              title="HeroImage"
              className="HomePageHeroImage"
            />
          </div>
        </div>
      </div>
      <div className="fills2">
        <div className="i-07">
          <img
            loading="lazy"
            src="/DataDrivenMarketer/WhereROI.webp"
            alt="HeroImage"
            title="HeroImage"
            className="HomePageHeroImage"
          />
        </div>
        <div className="fills-child">
          <div className="where-50x-roi-isnt-a-dream-parent">
            <h1 className="where-50x-roi">Where +50x ROI Isn't a Dream</h1>
            <div className="releva-consistently-delivers">
              Releva consistently delivers a remarkable +50x ROI, making it your smartest investment.
            </div>
          </div>
        </div>
      </div>
      <div className="fills3">
        <div className="frame-parent79">
          <div className="frame-wrapper35">
            <div className="double-your-conversions-parent">
              <h1 className="double-your-conversions">Double Your Conversions</h1>
              <div className="releva-doubles-purchase">
                Releva doubles purchase rates from the traffic you drive, turning visitors into loyal customers.
              </div>
            </div>
          </div>
          <div className="i-08">
            <img
              loading="lazy"
              src="/DataDrivenMarketer/Double.webp"
              alt="HeroImage"
              title="HeroImage"
              className="HomePageHeroImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shapes;
