import './FrameComponent32.css';

const FrameComponent32 = () => {
  // Function to handle clicks on the headings
  const handleCardWrapperClick = (e, slider) => {
    e.preventDefault(); // Prevent default behavior of the click event if needed
    showElements(`.slider${slider}`, 'image');
    showElements(`.description${slider}`, 'text');
    toggleActive(`.heading${slider}`);
  };
  // Function to toggle "active" class on the clicked element and remove it from others
  const toggleActive = (className) => {
    // Get all elements with the specified class name
    const elements = document.querySelectorAll(className);

    // Remove "active" class from all elements
    document.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });
    // console.log(elements);
    // Add "active" class to the clicked element
    elements.forEach((element) => {
      element.classList.add('active');
    });
  };
  // Function to show all elements with the specified class name
  const showElements = (className, type) => {
    // Get all elements with the specified class name
    const elementsToShow = document.querySelectorAll(className);
    // Convert NodeList to an array using spread operator
    const elementsArray = [...elementsToShow];

    // Show all elements
    elementsArray.forEach((element) => {
      element.style.display = 'block';
    });

    // Hide all other images
    if (type === 'image') {
      const allImages = document.querySelectorAll('.interactiveJourneyImages');
      allImages.forEach((image) => {
        // console.log(image);
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
    if (type === 'text') {
      const allImages = document.querySelectorAll('.interactiveDesctiption');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
  };

  return (
    <div className="distance-calculator-inner">
      <div className="tabs-parent">
        <div className="tabs">
          <div className="tab-1">
            <div
              className="customer-data-segmentation2 interactiveHeading heading1 active"
              onClick={(e) => handleCardWrapperClick(e, 1)}
            >
              <div className="the-beginning-of">The beginning of Releva</div>
              <div className="releva-was-founded interactiveDesctiption description1">
                Releva was founded at the beginning of 2020 by a group of software engineers and data scientists.
              </div>
            </div>
          </div>
          <div className="tab-2">
            <div
              className="customer-data-segmentation2 interactiveHeading heading2"
              onClick={(e) => handleCardWrapperClick(e, 2)}
            >
              <div className="the-beginning-of">Rapid growth</div>
              <div className="releva-was-founded interactiveDesctiption description2">
                In just 20 months, Releva achieved a rapid growth:
                <br />
                ∙ from 2 to 20 people;
                <br />
                ∙ from 20 to 400+ brands;
                <br />∙ from 0 to 50+ partners.
              </div>
            </div>
          </div>
          <div className="tab-3">
            <div
              className="customer-data-segmentation2 interactiveHeading heading3"
              onClick={(e) => handleCardWrapperClick(e, 3)}
            >
              <div className="the-beginning-of">Worldwide recognition</div>
              <div className="releva-was-founded interactiveDesctiption description3">
                ∙ Top emerging startup in the EMERGE 100 Report;
                <br />
                ∙ Selected to be one of the 13 participants in the Google for Startups Accelerator: AI First program;
                <br />
                ∙ Participant at the AI Bootcamp London 2023;
                <br />∙ Participant at the Bulgarian Innovation Hub.
              </div>
            </div>
          </div>
          <div className="tab-4">
            <div
              className="customer-data-segmentation2 interactiveHeading heading4"
              onClick={(e) => handleCardWrapperClick(e, 4)}
            >
              <div className="the-beginning-of">Awards</div>
              <div className="releva-was-founded interactiveDesctiption description4">
                ∙ Winner of the StartUp World Cup Bulgaria 2023;
                <br />
                ∙ Top 3 Startups in the Forbes Business Awards;
                <br />
                ∙ Finalist of the StartUp World Cup - Semi-finals San Francisco 2023;
                <br />∙ Top 3 Startup at the Bulgarian Information Technology Association Awards 2023.
              </div>
            </div>
          </div>
        </div>
        <div className="element-4-wrapper">
          <div className="element-4">
            <img
              className="frame-165-1 interactiveJourneyImages slider1"
              loading="lazy"
              alt=""
              src="/AboutUs/Journey1.webp"
            />
            <img
              className="frame-165-1 interactiveJourneyImages slider2"
              loading="lazy"
              alt=""
              src="/AboutUs/Journey2.webp"
              style={{ display: 'none' }}
            />
            <img
              className="frame-165-1 interactiveJourneyImages slider3"
              loading="lazy"
              alt=""
              src="/AboutUs/Journey3.webp"
              style={{ display: 'none' }}
            />
            <img
              className="frame-165-1 interactiveJourneyImages slider4"
              loading="lazy"
              alt=""
              src="/AboutUs/Journey4.webp"
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent32;
