import './CompareValues.css';

const CompareValues = () => {
  return (
    <section className="compare-values">
      <div className="negate-value">
        <div className="boolean-operator">
          <img className="purchaseconversionrateboost-icon" loading="lazy" alt="" src="/frame-222.svg" />
          <img className="testimonial-image-big-icon" alt="" src="/testimonialimagebig@2x.webp" />
        </div>
        <div className="function-operator">
          <div className="array-operator">
            <div className="matrix-operator">
              <img className="ebag-logo-1-icon" loading="lazy" alt="" src="/ebaglogo-1.svg" />
            </div>
            <div className="debugging-tools">
              <div className="visualizer">
                <div className="reach-purchase-conversion2">{`Reach purchase conversion rate boost `}</div>
                <div className="exporter">
                  <div className="thanks-to-releva2">
                    “Thanks to Releva we manage to offer our customers so much more personalised and relevant content.
                    We manage to engage them, encourage them to make a purchase and increase the average shopping cart
                    total.”
                  </div>
                </div>
              </div>
              <div className="navigator">
                <div className="eleonora-ivanova-marketing-container2">
                  <p className="eleonora-ivanova2">Eleonora Ivanova</p>
                  <p className="marketing-automation-expert2">Marketing automation expert</p>
                  <p className="ebag2">eBag</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompareValues;
