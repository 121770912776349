import './HowItWorks1.css';

const HowItWorks2 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/MailAndPushPage/omni4.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">04</div>
          <div className="master-your-generated">Optimize Your Product Recommendations</div>
          <div className="edit-your-documents">with Real-Time Product Insights</div>
          <div className="customize-your-generated">
            Optimize your product catalogue and sales strategies with Releva's Product Platform, which provides
            real-time insights into product performance. Manage marketing campaigns and product recommendation blocks,
            ensuring that you can swiftly respond to changing customer demands and market conditions.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks2;
