import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './Block4.css';

const Block4 = () => {
  return (
    <div className="block-41">
      <img className="artwork-icon4" alt="" src="/artwork-11.svg" loading="lazy" />
      <div className="block-4-child">
        <div className="our-mission-parent">
          <div className="our-mission">Our Mission</div>
          <div className="driving-growth-simplifying-container">
            <p className="driving-growth-simplifying">{`Driving Growth, Simplifying Processes. At Releva, we're dedicated to elevating eCommerce marketing, making `}</p>
            <p className="it-more-intuitive">it more intuitive, efficient, and result-oriented.</p>
          </div>
          <div className="button44">
            <a
              rel="noopener noreferrer"
              className="button-name40"
              target="_blank"
              href="https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-AboutUs"
              onClick={(e) =>
                handleBookaDemoClick(
                  e,
                  'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                )
              }
            >
              Book a demo
            </a>
          </div>
        </div>
      </div>
      <div className="frame-parent128">
        <img className="image-118-icon" loading="lazy" alt="" src="/AboutUs/OurMission.webp" />
      </div>
    </div>
  );
};

export default Block4;
