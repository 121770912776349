import { Helmet } from 'react-helmet';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CookiePolicy.css';

const PrivacyPolicyShopifyAppTwo = () => {
  return (
    <div className="cookie-policy3">
      <Helmet>
        <title>Releva AI | Privacy Policy</title>
        <meta
          name="description"
          content="Review Releva AI's Privacy Policy to understand how we protect your personal information. Learn about our data collection practices, usage, and your rights regarding your privacy and data security while using our services."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.privacyPolicyPage)}</script>
      </Helmet>
      <section className="cookie-policy-container">
        <h1 className="cookie-policy4">Releva ‑ AI Growth Automation Privacy Policy</h1>
      </section>
      {/* <SubMenu active="shopify-app-privacy-policy" /> */}
      <section className="at-releva-we-respect-individua-wrapper">
        <div className="at-releva-we-container">
          <p className="the-administrator-of-your-pers">
            <span className="the-administrator-of-your-pers1">
              <span className="the-administrator-of-your-pers2">
                <span className="the-administrator-of">
                  Releva ‑ AI Growth Automation "the App” provides an all-in-one AI automation solution for E-commerce
                  growth, "the Service" to merchants who use Shopify to power their stores. This Privacy Policy
                  describes how personal information is collected, used, and shared when you install or use the App in
                  connection with your Shopify-supported store.
                </span>
              </span>
            </span>
          </p>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Personal Information the App Collects</b>
            </span>
          </p>
          <div className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                When you install the App, we are automatically able to access certain types of information from your
                Shopify account:
              </span>
            </span>
            <ul className="access-your-personal-data-cor">
              <li className="access-your-personal-data-cor1">
                <span>
                  <span className="access-your-personal">
                    All customers: names, emails, phone numbers, and whether they have agreed to receive marketing
                    messages or notifications
                  </span>
                </span>
              </li>
              <li className="access-your-personal-data-cor1">
                <span>
                  <span className="access-your-personal">Orders placed by your customers during the past year</span>
                </span>
              </li>
            </ul>
            <span>
              <span className="by-filling-out">
                Additionally, we collect the following types of personal information from you and/or your customers once
                you have installed the App:
              </span>
            </span>
            <ul className="access-your-personal-data-cor">
              <li className="access-your-personal-data-cor1">
                <span>
                  <span className="access-your-personal">
                    Information about you and others who may access the App on behalf of your store, such as your name,
                    address, email address, phone number, and billing information
                  </span>
                </span>
              </li>
              <li className="access-your-personal-data-cor1">
                <span>
                  <span className="access-your-personal">
                    Information about individuals who visit your store, such as their IP address, web browser details,
                    time zone, and information about the cookies installed on the particular device
                  </span>
                </span>
              </li>
              <li className="access-your-personal-data-cor1">
                <span>
                  <span className="access-your-personal">
                    Information about preferences of customers as well as anonymous visitors (last viewed products, last
                    added to cart products, pages viewed, etc.)
                  </span>
                </span>
              </li>
            </ul>
            <p>
              <span className="by-filling-out">
                We collect personal information directly from the relevant individual, through your Shopify account, or
                using the following technologies:
              </span>
            </p>
            <span className="by-filling-out">
              <ul>
                <li>
                  “Cookies” are data files that are placed on your device or computer and often include an anonymous
                  unique identifier. For more information about cookies, and how to disable cookies, visit &nbsp;
                  <a
                    rel="noopener noreferrer"
                    className="inforelevaai2"
                    target="_blank"
                    href="http://www.allaboutcookies.org"
                  >
                    http://www.allaboutcookies.org
                  </a>
                  .
                </li>
                <li>
                  “Log files” track actions occurring on the Site, and collect data including your IP address, browser
                  type, Internet service provider, referring/exit pages, and date/time stamps.
                </li>
                <li>
                  “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you
                  browse the Site.
                </li>
              </ul>
            </span>
          </div>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">How Do We Use Your Personal Information?</b>
            </span>
          </p>
          <div className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                We use the personal information we collect from you and your customers in order to provide the Service
                and to operate the App, mainly to enable you to orchestrate complex marketing campaigns based on your
                customers’ behavior and preferences and to generate personalized content for each visitor based on their
                recorded preferences. Additionally, we use this personal information to:
              </span>
              <ul>
                <li>Communicate with you</li>
                <li>Optimize or improve the App</li>
                <li>Provide you with information or advertising relating to our products or services</li>
              </ul>
            </span>
          </div>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Sharing Your Personal Information</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                We do not share your personal information and/or the personal information of your customers with third
                parties. We may share a hashed version of personal information (alphanumeric code which is not in itself
                personal information and cannot be decoded) such as email or phone number with Facebook and/or Google
                ads in order to enable you to run Facebook/Google Ads campaigns through Releva only with your explicit
                authorization. For more information, see &nbsp;
                <a
                  rel="noopener noreferrer"
                  className="inforelevaai2"
                  target="_blank"
                  href="https://www.facebook.com/business/help/112061095610075"
                >
                  https://www.facebook.com/business/help/112061095610075
                </a>
                .
              </span>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                Finally, we may also share your and your customers’ Personal Information to comply with applicable laws
                and regulations, to respond to a subpoena, search warrant, or other lawful request for information we
                receive, or to otherwise protect our rights.
              </span>
            </span>
          </p>

          <p className="registration">
            <span className="registration1">
              <b className="registration2">Behavioural Advertising</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                As described above, we use your customers’ Personal Information to provide them with targeted
                advertisements or marketing communications we believe may be of interest to them. For more information
                about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”)
                educational page at &nbsp;
                <a
                  rel="noopener noreferrer"
                  className="inforelevaai2"
                  target="_blank"
                  href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
                >
                  http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                </a>
                .
              </span>
              <span className="by-filling-out">
                Your customers may opt out of all marketing campaigns you run through Releva using the unsubscribe link
                included in the footer of all non-transactional marketing messages.
              </span>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                Your customers may opt out of all marketing campaigns you run through Releva using the unsubscribe link
                included in the footer of all non-transactional marketing messages.
              </span>
            </span>
          </p>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Your rights:</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                If your visitors are European residents, they have the right to access the personal information we hold
                about them and to request that it be corrected, updated, or deleted. If any of your customers would like
                to exercise that right, please direct them to use the built-in Shopify functionality to submit GDPR
                requests - see &nbsp;
                <a
                  rel="noopener noreferrer"
                  className="inforelevaai2"
                  target="_blank"
                  href="https://help.shopify.com/en/manual/privacy-and-security/privacy/processing-customer-data-requests"
                >
                  https://help.shopify.com/en/manual/privacy-and-security/privacy/processing-customer-data-requests
                </a>
                . The App is fully integrated into the Shopify GDPR layer and therefore any information collected by the
                App would be shown to the customer if they request to download their information, or deleted if they
                request to have it erased.
              </span>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                Additionally, if you are a European resident we note that we are processing your information in order to
                fulfill contracts we might have with you (for example if you make an order through the Site), or
                otherwise to pursue our legitimate business interests listed above.
              </span>
            </span>
          </p>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Data Retention:</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                We keep behavioral data for your visitors and customers (including the products and pages they viewed,
                the products they added to their cart, and the products they purchased as well as interactions with
                Releva marketing messages, such as clicks) for up to 2 years unless and until they ask us to delete this
                information through the standard GDPR channels offered by Shopify. We keep your customers’ personal
                information (if they have agreed to share it) indefinitely, unless they request to have this information
                deleted.
              </span>
            </span>
          </p>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Changes:</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                We may update this privacy policy from time to time in order to reflect, for example, changes to our
                practices or for other operational, legal, or regulatory reasons.
              </span>
            </span>
          </p>
          <p className="registration">
            <span className="registration1">
              <b className="registration2">Contact Us:</b>
            </span>
          </p>
          <p className="by-filling-out-the-contact-for">
            <span>
              <span className="by-filling-out">
                For more information about our privacy practices, if you have questions, or if you would like to make a
                complaint, please contact us by e-mail at{' '}
                <a
                  rel="noopener noreferrer"
                  className="inforelevaai2"
                  target="_blank"
                  href="mailto:shop-support@releva.ai"
                >
                  shop-support@releva.ai
                </a>{' '}
                or by mail using the details provided below: Releva AD
                <br />
                Ul. Prof. Haralambi Tachev 18
                <br />
                Et. 4 ap. 18
                <br />
                Sofia 1164
                <br />
                Bulgaria
              </span>
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicyShopifyAppTwo;
