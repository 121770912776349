import './Block.css';

const Block = () => {
  return (
    <div className="block-9">
      <div className="block-9-inner">
        <div className="trendo-about-releva-parent">
          <h1 className="trendo-about-releva">TrendO about Releva</h1>
          <div className="releva-helped-our-container">
            <p className="releva-helped-our">
              "Releva helped our site a lot. It gave us a lot of flexibility and provided our customers with a different
              and much appreciated experience on our website. Most of the products each customer sees are based on their
              own interest and individual needs. 
            </p>
            <p className="and-of-course">
              And of course, Releva helped us increase our profits. It reflects our marketing strategy by allowing us to
              use automations and personalizations."
            </p>
            <p className="blank-line1">&nbsp;</p>
            <p className="datsko-datsev-cto">Datsko Datsev, CTO at trendo.bg</p>
          </div>
        </div>
      </div>
      <div className="vector-parent11">
        <img className="vector-icon30" alt="" src="/vector-42.svg" />
        <img className="frame-child69" loading="lazy" alt="" src="/frame-1351@2x.webp" />
        <div className="frame-parent123">
          <img className="frame-child70" alt="" src="/frame-134@2x.webp" />
          <img className="trendo-logo-1-icon" loading="lazy" alt="" src="/trendo-logo-1.svg" />
        </div>
      </div>
    </div>
  );
};

export default Block;
