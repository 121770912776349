import { useMemo } from 'react';
import './Ionut.css';

const Ionut = ({
  ionutLeoveanu,
  whatsAppImage20230812At17,
  propPadding,
  propLeft,
  propMinWidth,
  propWidth,
  propHeight,
}) => {
  const ionutStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const ionutLeoveanuStyle = useMemo(() => {
    return {
      left: propLeft,
      minWidth: propMinWidth,
    };
  }, [propLeft, propMinWidth]);

  const whatsAppImage20230812At1IconStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);

  return (
    <div className="ionut" style={ionutStyle}>
      <div className="timer">
        <b className="ionut-leoveanu" style={ionutLeoveanuStyle}>
          {ionutLeoveanu}
        </b>
        <div className="delay-processor">
          <img loading="lazy" className="vector-icon32" alt="" src="/vector-23.svg" />
          <img
            loading="lazy"
            className="whatsapp-image-2023-08-12-at-1-icon"
            alt=""
            src={whatsAppImage20230812At17}
            style={whatsAppImage20230812At1IconStyle}
          />
        </div>
      </div>
      <div className="filter-chain">
        <div className="customer-success-specialist">Customer Success Specialist</div>
      </div>
    </div>
  );
};

export default Ionut;
