import { Helmet } from 'react-helmet';
import pagesJsonLD from '../constants/pagesJsonLD';
import './NotFoundPage.css';

const NotFoundPage = () => {
  return (
    <div className="holder notFoundPage">
      <Helmet>
        <title>Releva AI | Home Page</title>
        <meta
          name="description"
          content="Discover Releva AI, the leading platform for AI-driven marketing solutions. Transform customer engagement, optimize campaigns, and drive business growth with our innovative technology. Explore our cutting-edge tools and see how we can elevate your marketing strategy."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.homePage)}</script>
      </Helmet>
      <img src="/notFoundImage.png" alt="" />
      <div className="contentNotFound">
        <h1>404</h1>
        <p>even AI can't help you find what you are looking for...</p>
        <a href="/" className="button-name5">
          Go back home
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
