import './PowFunction.css';

const PowFunction = () => {
  return (
    <section className="pow-function">
      <div className="round-function">
        <div className="frame-parent90">
          <img className="frame-child55" loading="lazy" alt="" src="/group-191.svg" />
          <div className="magento5">Magento</div>
        </div>
        <div className="frame-parent91">
          <div className="vector-wrapper2">
            <img className="vector-icon18" loading="lazy" alt="" src="/vector-31.svg" />
          </div>
          <div className="gomba-shop1">Gomba Shop</div>
        </div>
        <div className="frame-parent92">
          <div className="vector-wrapper3">
            <img className="vector-icon19" loading="lazy" alt="" src="/vector-41.svg" />
          </div>
          <div className="cloudcard1">Cloudcart</div>
        </div>
        <div className="vector-parent8">
          <img className="vector-icon20" alt="" src="/vector-51.svg" />
          <div className="opencard1">Opencart</div>
        </div>
      </div>
    </section>
  );
};

export default PowFunction;
