import './HowItWorks1.css';

const HowItWorks4 = () => {
  return (
    <div className="howitworks3">
      <div className="left">
        <img className="screenshot-2024-04-09-at-2028-icon" loading="lazy" alt="" src="/MailAndPushPage/omni8.webp" />
      </div>
      <div className="howitworks3-inner">
        <div className="parent6">
          <div className="div28">08</div>
          <div className="master-your-generated">Data-Driven Decisions: </div>
          <div className="edit-your-documents">Enhance Your Strategy with Powerful Analytics</div>
          <div className="customize-your-generated">
            Releva’s analytics deliver in-depth insights into customer behaviors and campaign performance, empowering
            you to make data-driven decisions that can significantly improve your results and profits. These analytics
            help refine your approach to meet market demands more effectively, optimizing your overall business
            performance and ROI.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks4;
