import { Helmet } from 'react-helmet';
import OvalShape from '../components/LockedCaseStudyPage/OvalShape';
import ValueComparator from '../components/ValueComparator';
import './LockedCaseStudy.css';

const LockedCaseStudy = () => {
  return (
    <div className="locked-case-study">
      <Helmet>
        <title>Releva AI | Case Study</title>
        <meta
          name="description"
          content="Explore Releva AI's case studies to see how our AI-driven solutions have transformed businesses. Discover real-world examples of enhanced customer engagement, increased conversions, and optimized marketing strategies. Learn from our success stories."
        />
      </Helmet>
      <section className="case-study-kidso-wrapper">
        <h1 className="case-study-">Case study - Kidso</h1>
      </section>
      <section className="kidso-is-one-of-the-top-brands-wrapper">
        <div className="kidso-is-one">
          Kidso is one of the top brands in children's apparel and accessories. The company’s website has between 250
          000 and 550 000 unique monthly active users per month.
        </div>
      </section>
      <OvalShape />
      <ValueComparator />
    </div>
  );
};

export default LockedCaseStudy;
